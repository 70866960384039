import {take, put, fork, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {Vehicle} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import updateServiceCase from './updateServiceCase';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as memberActionTypes from '../../members/memberActionTypes';
import config from '../../config';

/**
 * Service case vehicle edit flow (uses modal)
 * edits a vehicle on the AMP & patches the edited vehicle snapshot on a case (if needed)
 */
const editServiceCaseVehicleFlow = function* editServiceCaseVehicleFlow() {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.INITIATE_EDIT_SERVICE_CASE_VEHICLE_FLOW);
        const {vehicleId} = payload;

        yield* openModal(modalIds.VEHICLE_DATA, {vehicleId: `${vehicleId}`, mode: 'edit'});
        const chosenModalOption = yield take([
            serviceCaseActionTypes.CONFIRM_EDIT_SERVICE_CASE_VEHICLE,
            serviceCaseActionTypes.DECLINE_EDIT_SERVICE_CASE_VEHICLE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceCaseActionTypes.CONFIRM_EDIT_SERVICE_CASE_VEHICLE) {
            const {payload} = chosenModalOption;
            const {membershipNo, memberVehicle, serviceCaseData} = payload;
            const {serviceCaseId, caseType, nonAceMember, isSnapshotUpdateAllowed} = payload;

            if ((nonAceMember && !vehicleId) || (config.IS_FALLBACK_SYSTEM && !vehicleId)) {
                yield* updateServiceCase({
                    caller: serviceCaseActionTypes.CONFIRM_EDIT_SERVICE_CASE_VEHICLE,
                    caseType,
                    serviceCaseId,
                    serviceCaseData: {
                        ...serviceCaseData,
                        vehicle: {
                            ...serviceCaseData.vehicle,
                        },
                    },
                });
                yield* closeModal(modalIds.VEHICLE_DATA, {vehicleId: `${vehicleId}`, mode: 'edit'});
                continue;
            }

            yield fork(
                fetchRequest,
                memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST,
                membersService.updateMemberVehicle,
                {
                    memberVehiclePatchDTO: Vehicle.objectToPatchDTO(memberVehicle),
                    vehicleId,
                },
            );
            const responseAction = yield take([
                memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED,
                memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST_FAILED,
            ]);

            if (!responseAction.error
               && responseAction.type === memberActionTypes.UPDATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED) {
                const {response} = responseAction.payload;
                const {memberVehicleDTO} = response;

                yield put({
                    type: memberActionTypes.STORE_MEMBER_VEHICLE,
                    payload: {membershipNo, memberVehicleDTO},
                });

                if (memberVehicleDTO.id && isSnapshotUpdateAllowed) {
                    yield* updateServiceCase({
                        caller: serviceCaseActionTypes.CONFIRM_EDIT_SERVICE_CASE_VEHICLE,
                        caseType,
                        serviceCaseId,
                        serviceCaseData: {
                            ...serviceCaseData,
                            vehicle: {
                                ...serviceCaseData.vehicle,
                                vehicleId: memberVehicleDTO.id,
                            },
                        },
                    });
                }
            }
        }

        yield* closeModal(modalIds.VEHICLE_DATA, {vehicleId: `${vehicleId}`, mode: 'edit'});
    }
};

export default editServiceCaseVehicleFlow;
