import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CommissioningPeopleOnSitePanel = ({serviceCase, serviceAssignment}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('sava_commissioning_tab');

    const formatChildrenAge = () => {
        let outputString = `${serviceAssignment.childrenAtSite}`;
        serviceAssignment.childrenAge.forEach((age, idx) => {
            if (idx === 0) {
                outputString = outputString.concat(' (');
            }
            outputString = idx === serviceAssignment.childrenAge.length - 1
                ? outputString.concat(`${age} ${translate('global.time_unit.year')})`)
                : outputString.concat(`${age} ${translate('global.time_unit.year')}, `);
        });
        return outputString;
    };

    return (
        <Panel
            title={translateTab('panel_title.people_on_site')}
            qaIdent="sava-commissioning-people-at-site"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.adults')}
                        qaIdent="sa-commissioning-people-at-site"
                    >
                        {serviceAssignment.grownUpsAtSite}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.pets')}
                        qaIdent="sa-commissioning-pets-at-site"
                    >
                        {serviceAssignment.petsAtSite}
                    </DataRow>

                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.children')}
                        qaIdent="sa-commissioning-children-at-site"
                    >
                        {formatChildrenAge()}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.planned_departure')}
                        qaIdent="sc-planned-departure"
                    >
                        {serviceCase.departureDate ? moment(serviceCase.departureDate).format('DD.MM.YYYY') : ''}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CommissioningPeopleOnSitePanel.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
};

CommissioningPeopleOnSitePanel.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CommissioningPeopleOnSitePanel));

