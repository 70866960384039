import {put, select, take, fork} from 'redux-saga/effects';
import {ehmCaseLogCategoryTypes, efServiceTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as caseLogActionTypes from '../caseLogActionTypes';
import serviceCaseScreenTabs from '../../service-cases/serviceCaseScreenTabs';
import modalIds from '../../modalIds';

const loadCaseLog = function* loadCaseLog({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');
    const {match, location} = payload;
    const {serviceCaseId} = match.params;

    if (location.query.activeTab !== serviceCaseScreenTabs.PROTOCOL) return;

    // do not reload case history when agent opens send message modal or create note modal
    if (location && location.state?.isModalOpen
        && [modalIds.SEND_MESSAGE, modalIds.CREATE_CASE_LOG_NOTE]
            .includes(location.state?.modalId)
    ) return;

    const searchQueryParams = new URLSearchParams(location.search);
    searchQueryParams.append('category', ehmCaseLogCategoryTypes.CASE_STATUS_CHANGE);
    searchQueryParams.append('category', ehmCaseLogCategoryTypes.CASE_DATA_CHANGE);
    searchQueryParams.append('category', ehmCaseLogCategoryTypes.ASSIGNMENT_STATUS_CHANGE);
    Object.values(efServiceTypes).forEach(serviceType => searchQueryParams.append('serviceTypes', serviceType));
    Object.values(efServiceAssignmentTypes).forEach(serviceType => searchQueryParams.append('serviceTypes', serviceType));
    if (!searchQueryParams.get('page')) {
        searchQueryParams.append('page', 0);
    }

    yield fork(fetchRequest, caseLogActionTypes.FETCH_CASE_LOG_REQUEST, ecsHistoryManagementService.filterCaseLogs, {
        serviceCaseId,
        searchQueryParams,
    });

    const responseAction = yield take([
        caseLogActionTypes.FETCH_CASE_LOG_REQUEST_SUCCEEDED,
        caseLogActionTypes.FETCH_CASE_LOG_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {caseLogFilterResults} = response;

        yield put({
            type: caseLogActionTypes.STORE_CASE_LOGS,
            payload: {caseLogFilterResults, serviceCaseId},
        });
    }
};

export default loadCaseLog;
