import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {resolveRoute} from '@computerrock/formation-router';
import {useStyles, Panel, DataRow, Badge, ToggleSwitch, InputField} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import {Icon, warningAlertIcon, linkIcon} from '@ace-de/ui-components/icons';
import {ampMemberStatusTypes, ampMembershipTypes, ampMemberTariffGroups, ampMemberTariffAdditionalPackageTypes} from '@ace-de/eua-entity-types';
import config from '../../config';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import routePaths from '../../routePaths';
import {validateEmail} from '../../utils/validation';

const MemberInfoPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_basic_data_tab');
    const {member, members, serviceCase, toggleOngoingLegalCaseState, email, handleOnChange} = props;
    const [emailError, setEmailError] = useState('');
    const {personalDetails, tariffDetails} = member;
    const {address} = personalDetails;
    const membershipBeginDate = member.beginMembership ? moment(member.beginMembership).format('DD.MM.YYYY') : '';
    const membershipEndDate = member.endMembership ? moment(member.endMembership).format('DD.MM.YYYY') : '';
    const additionalPackages = tariffDetails?.additionalPackages
        ? tariffDetails.additionalPackages?.reduce((prev, curr) => prev + (prev === '' ? '' : ' & ')
            + translate(`global.member_tariff_additional_package.${snakeCase(ampMemberTariffAdditionalPackageTypes[curr])}`), '')
        : '';

    const isLegalCaseToggleAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.ONGOING_LEGAL_CASE,
        ecsFeatureActions.UPDATE,
    );

    const toggleLegalCaseOngoing = value => {
        if (!isLegalCaseToggleAvailable) return;
        toggleOngoingLegalCaseState({
            serviceCase,
            isLegalCaseOngoing: value,
        });
    };

    const handleRedirectToServiceCaseSearchScreen = event => {
        event.preventDefault();
        event.stopPropagation();
        const searchParams = new URLSearchParams();
        searchParams.append('membershipNo', member.membershipNo);
        const {pathname} = resolveRoute(routePaths.SERVICE_CASE_SEARCH);
        window.open(`${pathname}?membershipNo=${member.membershipNo}`, '_blank');
    };

    const validateEmailFormat = value => {
        if (!value) {
            setEmailError('');
            return;
        }
        const isEmailValid = validateEmail(value);
        setEmailError(isEmailValid ? '' : translateTab('member_info_panel_data_row_label.email_error_message'));
    };

    return (
        <Panel
            title={translateTab('member_info_panel.title')}
            qaIdent="member-info"
            actions={isLegalCaseToggleAvailable && (
                <Fragment>
                    <p
                        className={cx([
                            'global!ace-u-padding--right-8',
                            'global!ace-u-typography--variant-body-bold',
                        ])}
                    >
                        {translateTab('toggle_label.is_legal_case_ongoing')}
                    </p>
                    <ToggleSwitch
                        name="toggleIsLegalCaseOngoing"
                        value={true}
                        isSelected={serviceCase?.isLegalCaseOngoing}
                        onChange={value => { toggleLegalCaseOngoing(value); }}
                    />
                </Fragment>

            )}
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('member_info_panel_data_row_label.member')}
                        qaIdent="member-personal-details-name"
                    >
                        {[personalDetails?.firstName, personalDetails?.surname].filter(value => !!value).join(' ')}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_info_panel_data_row_label.address')}
                        qaIdent="member-personal-details-address"
                    >
                        {address.parsedFormattedAddress || ''}
                    </DataRow>
                    {member.membershipType === ampMembershipTypes.PRIVATE && (
                        <DataRow
                            label={translateTab('member_info_panel_data_row_label.birthday')}
                            qaIdent="member-personal-details-birthday"
                        >
                            {personalDetails.birthday ? moment(personalDetails.birthday).format('DD.MM.YYYY') : ''}
                        </DataRow>
                    )}
                    <DataRow
                        label={translateTab('member_info_panel_data_row_label.entry_date')}
                        qaIdent="member-membership-dates"
                    >
                        {`${membershipBeginDate} ${membershipEndDate
                            ? `${translateTab('member_info_panel_data_row_label.leaving')} ${membershipEndDate}`
                            : ''}`}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_info_panel_data_row_label.callback_number')}
                        qaIdent="member-personal-details-callback-number"
                    >
                        {serviceCase.callbackPhoneNo || ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_info_panel_data_row_label.email')}
                        qaIdent="member-email"
                        className={cx('ace-c-data-row--condensed')}
                    >
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-column',
                                'global!ace-u-flex--grow-1',
                            ])}
                        >
                            <InputField
                                className={cx(['ace-c-input--small', 'global!ace-u-flex--grow-1'])}
                                value={email}
                                onChange={value => handleOnChange('email', value)}
                                onBlur={validateEmailFormat}
                                errors={emailError ? [emailError] : []}
                            />
                        </div>
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('member_info_panel_data_row_label.membership_number')}
                        qaIdent="member-membership-number"
                    >
                        {member.membershipNo}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_info_panel_data_row_label.tariff')}
                        qaIdent="member-tariff-details"
                    >
                        {tariffDetails.tariffGroup ? translate(`global.member_tariff_group.${snakeCase(ampMemberTariffGroups[tariffDetails.tariffGroup])}`) : ''}
                        {additionalPackages ? ` | ${additionalPackages}` : ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_info_panel_data_row_label.status')}
                        qaIdent="member-status"
                    >
                        {member.status !== ampMemberStatusTypes.EMPTY && (
                            <Badge status={member.status.toLowerCase()}>
                                {translate(`global.member_status.${snakeCase(member.status)}`)}
                            </Badge>
                        )}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_info_panel_data_row_label.dunning_level')}
                        qaIdent="member-dunning-level"
                    >
                        <p
                            className={cx({
                                'global!ace-u-typography--color-warning': member.dunningLevel
                                    >= config.WARNING_DUNNING_LEVEL,
                            })}
                        >
                            {member.dunningLevel}
                        </p>
                        {member.dunningLevel >= config.WARNING_DUNNING_LEVEL && (
                            <Icon
                                icon={warningAlertIcon}
                                className={cx('ace-c-icon--color-warning', 'global!ace-u-margin--left-16')}
                            />
                        )}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_info_panel_data_row_label.service_cases')}
                        qaIdent="member-service-case-count"
                        contentClassName={cx([
                            'global!ace-u-width--full',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--justify-space-between',
                        ])}
                    >
                        {members[member.membershipNo]?.serviceCaseCount}
                        {members[member.membershipNo]?.serviceCaseCount && (
                        <Icon
                            icon={linkIcon}
                            className={cx('global!ace-u-cursor--pointer')}
                            onClick={handleRedirectToServiceCaseSearchScreen}
                        />
                        )}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

MemberInfoPanel.propTypes = {
    member: PropTypes.object.isRequired,
    members: PropTypes.object,
    serviceCase: PropTypes.object.isRequired,
    toggleOngoingLegalCaseState: PropTypes.func.isRequired,
    handleOnChange: PropTypes.func,
    email: PropTypes.string,
};

MemberInfoPanel.defaultProps = {
    members: [],
    handleOnChange: () => undefined,
    email: '',
};

const mapStateToProps = state => ({
    members: state.members.membersByMembershipNo,
});

const mapDispatchToProps = dispatch => {
    return {
        toggleOngoingLegalCaseState: payload => {
            dispatch({
                type: serviceCaseActionTypes.TOGGLE_ONGOING_LEGAL_CASE,
                payload,
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberInfoPanel);
