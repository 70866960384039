import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, Divider, HighlightCircle} from '@ace-de/ui-components';
import {Form, ButtonPrimary, SelectField, Option, RichTextAreaField} from '@ace-de/ui-components/form';
import {InteractiveIcon, closeIcon, Icon, plusIcon, uploadIcon, timeIcon, attachmentIcon, deleteIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import {useFileAssets, FileAssetsInput, FileAssetsDropZone} from '@ace-de/eua-file-assets';
import config from '../../config';
import * as caseLogActionTypes from '../caseLogActionTypes';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import {getServiceAssignments} from '../../service-cases/serviceCaseSelectors';
import allowedAttachmentMIMETypes from '../../service-cases/allowedAttachmentMIMETypes';
import formatBytes from '../../application/formatBytes';

const fileSizeLimit = formatBytes(config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES);

const CreateCaseLogNoteModal = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const {serviceCase, serviceAssignments} = props;
    const {confirmCreateCaseLogNote, declineCreateCaseLogNote, hasBackdrop} = props;
    const translateModal = createTranslateShorthand('create_case_log_note_modal');
    const [formData, setFormData] = useState(null);
    const [richTextAreaContent, setRichTextAreaContent] = useState(EditorState.createEmpty());
    const {configure: configureFileAssetsService, fileList, deleteFile} = useFileAssets({
        service: 'ehmFileAssetsService',
        fileList: 'note-documents',
    });

    useEffect(() => {
        configureFileAssetsService({
            maxFileSize: config.MAXIMUM_FILE_ASSETS_FILE_SIZE_BYTES,
            allowedMIMETypes: allowedAttachmentMIMETypes,
        });
    }, [configureFileAssetsService]);

    const handleOnChange = formValues => {
        setFormData(formValues);
    };

    const handleOnSubmit = () => {
        const caseId = serviceCase && serviceCase.id;
        const assignmentId = `${caseId}-${formData?.lineNo}`;
        const serviceAssignment = serviceAssignments && serviceAssignments[assignmentId];
        const assignmentType = serviceAssignment ? serviceAssignment.assignmentType : '';
        const serviceType = serviceAssignment?.serviceType
        && serviceAssignment.assignmentType !== efServiceAssignmentTypes.OTHER_SERVICES
            ? translate(`global.service_type.${serviceAssignment?.serviceType.toLowerCase()}`)
            : translate(`global.service_type.${serviceAssignment?.assignmentType.toLowerCase()}`);
        const serviceCaseCreationDate = `${moment(serviceAssignment?.createdAt).format('DD.MM.YYYY[,] HH:mm')} ${translate('global.time_unit.time')}`;
        const noteTopic = formData?.lineNo ? `${serviceType} (${serviceCaseCreationDate})` : `${translateModal('assignment_option.other')}`;
        const html = stateToHTML(richTextAreaContent.getCurrentContent());
        const fileAssets = fileList.files;

        const caseLogNoteData = {
            serviceCaseId: serviceCase?.id,
            lineNum: formData?.lineNo,
            serviceType: formData?.lineNo ? serviceAssignment?.serviceType || null : null,
            assignmentType,
            noteTopic: noteTopic || '',
            note: html,
            attachments: fileAssets.length > 0
                ? fileAssets.map(fileAsset => ({
                    id: fileAsset.id,
                    fileName: fileAsset.fileName,
                }))
                : null,
        };

        const serviceCaseId = serviceCase?.id;
        confirmCreateCaseLogNote({caseLogNoteData, serviceCaseId});
    };

    return (
        <Modal
            contentClassName={cx('global!ace-u-modal-content-size--l')}
            title={translateModal('header.title')}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineCreateCaseLogNote}
                />
            )}
            hasBackdrop={hasBackdrop}
            className={cx('ace-c-modal--full-scrollable')}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                ])}
            >
                <Form
                    name="createCaseLogNoteForm"
                    onChange={handleOnChange}
                >
                    <Fragment>
                        <div className={cx('global!ace-u-margin--top-32')} />
                        <Divider />
                        <div
                            className={cx([
                                'global!ace-u-grid',
                                'global!ace-u-margin--24-0-48',
                            ])}
                        >
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    {
                                        'global!ace-u-grid-column--span-7': !config.IS_FALLBACK_SYSTEM,
                                        'global!ace-u-grid-column--span-12': config.IS_FALLBACK_SYSTEM,
                                    },
                                ])}
                            >
                                <div className={cx('global!ace-u-grid')}>
                                    <SelectField
                                        name="lineNo"
                                        className={cx([
                                            'global!ace-u-margin--24-0-48', {
                                                'global!ace-u-grid-column--span-6': config.IS_FALLBACK_SYSTEM,
                                                'global!ace-u-grid-column--span-12': !config.IS_FALLBACK_SYSTEM,
                                            },
                                        ])}
                                        label={translateModal('select_field_label.assignment')}
                                        value={formData?.lineNo || ''}
                                    >
                                        {(serviceCase?.serviceAssignmentIds || []).map(serviceAssignmentId => {
                                            const serviceAssignment = serviceAssignments[serviceAssignmentId];
                                            const {serviceType, assignmentType} = serviceAssignment;
                                            const serviceAssignmentType = serviceType
                                            && assignmentType !== efServiceAssignmentTypes.OTHER_SERVICES
                                                ? translate(`global.service_type.${serviceType.toLowerCase()}`)
                                                : translate(`global.service_type.${assignmentType.toLowerCase()}`);
                                            const serviceCaseCreationDate = `${moment(serviceAssignment?.createdAt).format('DD.MM.YYYY[,] HH:mm')} ${translate('global.time_unit.time')}`;
                                            return (
                                                <Option
                                                    name={`serviceAssignment${serviceAssignment.lineNo}`}
                                                    key={`serviceAssignment${serviceAssignment.lineNo}`}
                                                    value={serviceAssignment.lineNo}
                                                >
                                                    {`${serviceAssignmentType} (${serviceCaseCreationDate})`}
                                                </Option>
                                            );
                                        })}
                                        <Option
                                            name="other"
                                            value=""
                                        >
                                            {translateModal('assignment_option.other')}
                                        </Option>
                                    </SelectField>
                                    <RichTextAreaField
                                        label={translateModal('text_area_field_label.notice')}
                                        maxLength={config.CASE_LOG_NOTE_MAX_LENGTH}
                                        setEditorState={value => setRichTextAreaContent(value)}
                                        editorState={richTextAreaContent}
                                        className={cx('global!ace-u-grid-column--span-12')}
                                        contentClassName={cx('ace-c-text-area--medium')}
                                        locale={activeLocale}
                                    />
                                </div>
                            </div>
                            {!config.IS_FALLBACK_SYSTEM && (
                                <div className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--justify-space-between',
                                    'global!ace-u-grid-column--span-5',
                                    'global!ace-u-padding--left-48',
                                ])}
                                >
                                    <FileAssetsDropZone
                                        service="ehmFileAssetsService"
                                        fileList="note-documents"
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                            'global!ace-u-flex--justify-center',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-margin--top-48',
                                        ])}
                                    >
                                        <HighlightCircle
                                            className={cx([
                                                'global!ace-u-margin--32-192',
                                                'ace-c-highlight-circle--medium',
                                                'ace-c-highlight-circle--primary',
                                            ])}
                                        >
                                            <Icon
                                                icon={uploadIcon}
                                                className={cx([
                                                    'ace-c-icon--xxl',
                                                    'ace-c-icon--color-highlight',
                                                ])}
                                            />
                                        </HighlightCircle>
                                        <h3 className={cx('global!ace-u-typography--variant-h3')}>
                                            {translateModal('drop_zone_title.document_upload')}
                                        </h3>
                                        <p
                                            className={cx([
                                                'global!ace-u-typography--variant-body',
                                                'global!ace-u-typography--color-medium-emphasis',
                                            ])}
                                        >
                                            {translateModal('text.valid_formats')}
                                        </p>
                                        <FileAssetsInput
                                            service="ehmFileAssetsService"
                                            fileList="note-documents"
                                            className={cx([
                                                'global!ace-u-margin--24-0',
                                                'ace-c-file-input--no-chrome',
                                            ])}
                                        >
                                            <div
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-flex--align-center',
                                                    'global!ace-u-margin--24-0',
                                                    'global!ace-u-typography--variant-highlight',
                                                    'global!ace-u-typography--color-highlighted',
                                                    'global!ace-u-cursor--pointer',
                                                ])}
                                            >
                                                <Icon
                                                    icon={plusIcon}
                                                    className={cx([
                                                        'global!ace-u-margin--right-8',
                                                        'ace-c-icon--color-highlight',
                                                    ])}
                                                />
                                                {translateModal('label.search_documents')}
                                            </div>
                                        </FileAssetsInput>
                                    </FileAssetsDropZone>
                                    {fileList.errors.length > 0 && (
                                        <div className={cx('global!ace-u-margin--top-8')}>
                                            {fileList.errors.map((errorInfo, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={cx([
                                                            'global!ace-u-flex',
                                                            'global!ace-u-flex--align-center',
                                                            'global!ace-u-margin--4-0',
                                                            'global!ace-u-typography--variant-caption',
                                                            'global!ace-u-typography--color-warning',
                                                        ])}
                                                    >
                                                        <Icon
                                                            icon={infoAlertIcon}
                                                            className={cx([
                                                                'global!ace-u-margin--right-4',
                                                                'ace-c-icon--s',
                                                                'ace-c-icon--color-warning',
                                                                'global!ace-u-flex--shrink-0',
                                                            ])}
                                                        />
                                                        {translate(`global.eua_file_assets_error_type.${snakeCase(errorInfo.errorType)}`, {
                                                            fileName: errorInfo.fileName,
                                                            fileType: errorInfo.fileType,
                                                            fileSizeLimit,
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    {fileList.files.length > 0 && (
                                        <div className={cx('global!ace-u-margin--16-0')}>
                                            {fileList.files.map(fileAsset => {
                                                return (
                                                    <div
                                                        key={fileAsset.id}
                                                        className={cx([
                                                            'global!ace-u-flex',
                                                            'global!ace-u-flex--align-center',
                                                            'global!ace-u-flex--justify-space-between',
                                                            'global!ace-u-margin--8-0',
                                                        ])}
                                                    >
                                                        <span
                                                            className={cx([
                                                                'global!ace-u-flex',
                                                                'global!ace-u-flex--align-center',
                                                                'global!ace-u-typography--variant-body',
                                                                'global!ace-u-typography--color-highlighted',
                                                            ])}
                                                        >
                                                            <Icon
                                                                icon={fileAsset.isUploadPending
                                                                    ? timeIcon
                                                                    : attachmentIcon
                                                                }
                                                                className={cx([
                                                                    'global!ace-u-margin--right-8',
                                                                    'ace-c-icon--xs',
                                                                    'ace-c-icon--color-highlight',
                                                                ])}
                                                            />
                                                            {fileAsset.fileName}
                                                        </span>
                                                        <Icon
                                                            onClick={() => deleteFile(fileAsset)}
                                                            icon={deleteIcon}
                                                            className={cx([
                                                                'ace-c-icon--xs',
                                                                'ace-c-icon--color-medium-emphasis',
                                                            ])}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <Divider />
                        <div className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                        ])}
                        >
                            <ButtonPrimary
                                name="confirmModalButton"
                                className={cx('global!ace-u-margin--top-32')}
                                onClick={handleOnSubmit}
                                isDisabled={!richTextAreaContent.getCurrentContent().hasText()
                                    || fileList.files.some(fileAsset => fileAsset.isUploadPending)
                                }
                            >
                                {translateModal('button_label.attached_note')}
                            </ButtonPrimary>
                        </div>
                    </Fragment>
                </Form>
            </div>
        </Modal>
    );
};

CreateCaseLogNoteModal.propTypes = {
    declineCreateCaseLogNote: PropTypes.func.isRequired,
    confirmCreateCaseLogNote: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
    serviceAssignments: PropTypes.object,
    hasBackdrop: PropTypes.bool,
};

CreateCaseLogNoteModal.defaultProps = {
    serviceCase: null,
    serviceAssignments: null,
    hasBackdrop: true,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignments: getServiceAssignments(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineCreateCaseLogNote: () => dispatch({type: caseLogActionTypes.DECLINE_CREATE_CASE_LOG_NOTE}),
    confirmCreateCaseLogNote: payload => dispatch({
        type: caseLogActionTypes.CONFIRM_CREATE_CASE_LOG_NOTE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCaseLogNoteModal);
