/**
 * Command action types
 */
export const INITIATE_SELECT_ADDITIONAL_SERVICES_FLOW = '[service-cases] INITIATE_SELECT_ADDITIONAL_SERVICES_FLOW';
export const CONFIRM_SELECT_ADDITIONAL_SERVICES = '[service-cases] CONFIRM_SELECT_ADDITIONAL_SERVICES';
export const DECLINE_SELECT_ADDITIONAL_SERVICES = '[service-cases] DECLINE_SELECT_ADDITIONAL_SERVICES';
export const INITIATE_CREATE_PERSON_SERVICE_CASE_FLOW = '[service-cases] INITIATE_CREATE_PERSON_SERVICE_CASE_FLOW';
export const CONFIRM_CREATE_PERSON_SERVICE_CASE = '[service-cases] CONFIRM_CREATE_PERSON_SERVICE_CASE';
export const DECLINE_CREATE_PERSON_SERVICE_CASE = '[service-cases] DECLINE_CREATE_PERSON_SERVICE_CASE';
export const CREATE_PERSON_SERVICE_ASSIGNMENT = '[service-cases] CREATE_PERSON_SERVICE_ASSIGNMENT';
export const CREATE_PERSON_SERVICE_ASSIGNMENT_FLOW = '[service-cases] CREATE_PERSON_SERVICE_ASSIGNMENT_FLOW';
export const SUBMIT_SERVICE_ASSIGNMENT_CLONE_FORM = '[service-cases] SUBMIT_SERVICE_ASSIGNMENT_CLONE_FORM';
export const INITIATE_SERVICE_ASSIGNMENT_STATUS_UPDATE_FLOW = '[service-cases] INITIATE_SERVICE_ASSIGNMENT_STATUS_UPDATE_FLOW';
export const CONFIRM_SERVICE_ASSIGNMENT_STATUS_UPDATE = '[service-cases] CONFIRM_SERVICE_ASSIGNMENT_STATUS_UPDATE';
export const DECLINE_SERVICE_ASSIGNMENT_STATUS_UPDATE = '[service-cases] DECLINE_SERVICE_ASSIGNMENT_STATUS_UPDATE';
export const SUBMIT_CREATE_PDF_FORM = '[service-cases] SUBMIT_CREATE_PDF_FORM';
export const INITIATE_SAVE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW = '[service-cases] INITIATE_SAVE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW';
export const INITIATE_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW = '[service-cases] INITIATE_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT_FLOW';
export const CONFIRM_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT = '[service-cases] CONFIRM_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT';
export const DECLINE_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT = '[service-cases] DECLINE_CLOSE_ARCHIVE_SERVICE_ASSIGNMENT';
export const INITIATE_SERVICE_ASSIGNMENT_FINAL_DESTINATION_UPDATE_FLOW = '[service-cases] INITIATE_SERVICE_ASSIGNMENT_FINAL_DESTINATION_UPDATE_FLOW';
export const INITIATE_SERVICE_ASSIGNMENT_CHANNELS_UPDATE_FLOW = '[service-cases] INITIATE_SERVICE_ASSIGNMENT_CHANNELS_UPDATE_FLOW';
export const INITIATE_RESEND_SERVICE_ASSIGNMENT_FLOW = '[service-cases] INITIATE_RESEND_SERVICE_ASSIGNMENT_FLOW';
export const CONFIRM_RESEND_SERVICE_ASSIGNMENT = '[service-cases] CONFIRM_RESEND_SERVICE_ASSIGNMENT';
export const DECLINE_RESEND_SERVICE_ASSIGNMENT = '[service-cases] DECLINE_RESEND_SERVICE_ASSIGNMENT';
export const INITIATE_UPDATE_SERVICE_ASSIGNMENT_TEXT_FLOW = '[service-cases] INITIATE_UPDATE_SERVICE_ASSIGNMENT_TEXT_FLOW';
export const SUBMIT_ALTERNATIVE_SERVICE_PROVIDER_FORM = '[service-cases] SUBMIT_ALTERNATIVE_SERVICE_PROVIDER_FORM';
export const SUBMIT_POWER_OF_ATTORNEY_CHANNELS = '[service-cases] SUBMIT_POWER_OF_ATTORNEY_CHANNELS';
export const SUBMIT_POWER_OF_ATTORNEY_FORM = '[service-cases] SUBMIT_POWER_OF_ATTORNEY_FORM';
export const INITIATE_DELETE_SERVICE_ASSIGNMENT_FLOW = '[service-cases] INITIATE_DELETE_SERVICE_ASSIGNMENT_FLOW';
export const CONFIRM_DELETE_SERVICE_ASSIGNMENT_FLOW = '[service-cases] CONFIRM_DELETE_SERVICE_ASSIGNMENT_FLOW';
export const DECLINE_DELETE_SERVICE_ASSIGNMENT_FLOW = '[service-cases] DECLINE_DELETE_SERVICE_ASSIGNMENT_FLOW';
export const SUBMIT_COMMISSIONING_ACE_PARTNER_FORM = '[service-cases] SUBMIT_COMMISSIONING_ACE_PARTNER_FORM';
export const SUBMIT_COMMISSIONING_ACE_PARTNER_STATUS_CHANGE = '[service-cases] SUBMIT_COMMISSIONING_ACE_PARTNER_STATUS_CHANGE';
export const SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION';
export const SUBMIT_RELEVANT_LOCATION_FORM = '[service-cases] SUBMIT_RELEVANT_LOCATION_FORM';
export const SEARCH_ARCGIS_NEARBY_POIS = '[service-cases] SEARCH_ARCGIS_NEARBY_POIS';
export const SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION';
export const INITIATE_UPDATE_COMMISSIONING_ACE_PARTNER_DETAILS = '[service-cases] INITIATE_UPDATE_COMMISSIONING_ACE_PARTNER_DETAILS';
export const CREATE_SERVICE_ASSIGNMENT_PDF = '[service-cases] CREATE_SERVICE_ASSIGNMENT_PDF';
export const SUBMIT_SERVICE_ASSIGNMENT_JOURNEY_NOTES_FORM = '[service-cases] SUBMIT_SERVICE_ASSIGNMENT_JOURNEY_NOTES_FORM';
export const CONFIRM_RESENDING_FAILED_POWER_OF_ATTORNEY = '[service-cases] CONFIRM_RESENDING_FAILED_POWER_OF_ATTORNEY';
export const DECLINE_RESENDING_FAILED_POWER_OF_ATTORNEY = '[service-cases] DECLINE_RESENDING_FAILED_POWER_OF_ATTORNEY';
export const CONFIRM_RESUBMITTING_COMMISSIONING_ACE_PARTNER = '[service-cases] CONFIRM_RESUBMITTING_COMMISSIONING_ACE_PARTNER';
export const DECLINE_RESUBMITTING_COMMISSIONING_ACE_PARTNER = '[service-cases] DECLINE_RESUBMITTING_COMMISSIONING_ACE_PARTNER';
export const INITIATE_UPDATE_ACE_PARTNER = '[service-cases] INITIATE_UPDATE_ACE_PARTNER';
export const CONFIRM_UPDATE_ACE_PARTNER = '[service-cases] CONFIRM_UPDATE_ACE_PARTNER';
export const DECLINE_UPDATE_ACE_PARTNER = '[service-cases] DECLINE_UPDATE_ACE_PARTNER';
export const SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION = '[service-cases] SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION';
export const INITIATE_SERVICE_ASSIGNMENT_ACE_PARTNER_DELETION_FLOW = '[service-cases] INITIATE_SERVICE_ASSIGNMENT_ACE_PARTNER_DELETION_FLOW';
export const INITIATE_QM_FEEDBACK_DRAFT_CREATION_FLOW = '[service-cases] INITIATE_QM_FEEDBACK_DRAFT_CREATION_FLOW';
export const INITIATE_POWER_OF_ATTORNEY_TASK_CREATION_FLOW = '[service-cases] INITIATE_POWER_OF_ATTORNEY_TASK_CREATION_FLOW';

/**
 * Event action types
 */
export const BATCH_UPDATE_SERVICE_ASSIGNMENT = '[service-cases] BATCH_UPDATE_SERVICE_ASSIGNMENT';
export const BATCH_UPDATE_SERVICE_ASSIGNMENT_COMPLETED = '[service-cases] BATCH_UPDATE_SERVICE_ASSIGNMENT_COMPLETED';

export const FETCH_SERVICE_ASSIGNMENT_REQUEST = '[service-cases] FETCH_SERVICE_ASSIGNMENT_REQUEST';
export const FETCH_SERVICE_ASSIGNMENT_REQUEST_SENT = '[service-cases] FETCH_SERVICE_ASSIGNMENT_REQUEST_SENT';
export const FETCH_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_ASSIGNMENT_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_ASSIGNMENT_REQUEST_FAILED';

export const UPDATE_SERVICE_ASSIGNMENT_REQUEST = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_REQUEST';
export const UPDATE_SERVICE_ASSIGNMENT_REQUEST_SENT = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_REQUEST_SENT';
export const UPDATE_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED';
export const UPDATE_SERVICE_ASSIGNMENT_REQUEST_FAILED = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_REQUEST_FAILED';

export const UPDATE_SERVICE_ASSIGNMENT_STATUS_REQUEST = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_STATUS_REQUEST';
export const UPDATE_SERVICE_ASSIGNMENT_STATUS_REQUEST_SENT = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_STATUS_REQUEST_SENT';
export const UPDATE_SERVICE_ASSIGNMENT_STATUS_REQUEST_SUCCEEDED = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_STATUS_REQUEST_SUCCEEDED';
export const UPDATE_SERVICE_ASSIGNMENT_STATUS_REQUEST_FAILED = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_STATUS_REQUEST_FAILED';

export const UPDATE_SERVICE_ASSIGNMENT_SUB_STATUS_REQUEST = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_SUB_STATUS_REQUEST';
export const UPDATE_SERVICE_ASSIGNMENT_SUB_STATUS_REQUEST_SENT = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_SUB_STATUS_REQUEST_SENT';
export const UPDATE_SERVICE_ASSIGNMENT_SUB_STATUS_REQUEST_SUCCEEDED = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_SUB_STATUS_REQUEST_SUCCEEDED';
export const UPDATE_SERVICE_ASSIGNMENT_SUB_STATUS_REQUEST_FAILED = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_SUB_STATUS_REQUEST_FAILED';

export const FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST = '[service-cases] FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST';
export const FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST_SENT = '[service-cases] FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST_SENT';
export const FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED';
export const FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST_FAILED = '[service-cases] FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST_FAILED';

export const FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST = '[service-cases] FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST';
export const FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST_SENT = '[service-cases] FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST_SENT';
export const FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST_SUCCEEDED';
export const FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST_FAILED = '[service-cases] FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST_FAILED';

export const UPDATE_SERVICE_ASSIGNMENT_POWER_OF_ATTORNEY_REQUEST = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_POWER_OF_ATTORNEY_REQUEST';
export const UPDATE_SERVICE_ASSIGNMENT_POWER_OF_ATTORNEY_REQUEST_SENT = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_POWER_OF_ATTORNEY_REQUEST_SENT';
export const UPDATE_SERVICE_ASSIGNMENT_POWER_OF_ATTORNEY_REQUEST_SUCCEEDED = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_POWER_OF_ATTORNEY_REQUEST_SUCCEEDED';
export const UPDATE_SERVICE_ASSIGNMENT_POWER_OF_ATTORNEY_REQUEST_FAILED = '[service-cases] UPDATE_SERVICE_ASSIGNMENT_POWER_OF_ATTORNEY_REQUEST_FAILED';

export const CREATE_SERVICE_ASSIGNMENT_CLONE_REQUEST = '[service-cases] CREATE_SERVICE_ASSIGNMENT_CLONE_REQUEST';
export const CREATE_SERVICE_ASSIGNMENT_CLONE_REQUEST_SENT = '[service-cases] CREATE_SERVICE_ASSIGNMENT_CLONE_REQUEST_SENT';
export const CREATE_SERVICE_ASSIGNMENT_CLONE_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_CLONE_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_ASSIGNMENT_CLONE_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_CLONE_REQUEST_FAILED';

export const CREATE_PDF_REQUEST = '[service-cases] CREATE_PDF_REQUEST';
export const CREATE_PDF_REQUEST_SENT = '[service-cases] CREATE_PDF_REQUEST_SENT';
export const CREATE_PDF_REQUEST_SUCCEEDED = '[service-cases] CREATE_PDF_REQUEST_SUCCEEDED';
export const CREATE_PDF_REQUEST_FAILED = '[service-cases] CREATE_PDF_REQUEST_FAILED';

export const RESEND_SERVICE_ASSIGNMENT_REQUEST = '[service-cases] RESEND_SERVICE_ASSIGNMENT_REQUEST';
export const RESEND_SERVICE_ASSIGNMENT_REQUEST_SENT = '[service-cases] RESEND_SERVICE_ASSIGNMENT_REQUEST_SENT';
export const RESEND_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] RESEND_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED';
export const RESEND_SERVICE_ASSIGNMENT_REQUEST_FAILED = '[service-cases] RESEND_SERVICE_ASSIGNMENT_REQUEST_FAILED';

export const FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST = '[service-cases] FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST';
export const FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SENT = '[service-cases] FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SENT';
export const FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_FAILED';

export const CREATE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST = '[service-cases] CREATE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST';
export const CREATE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SENT = '[service-cases] CREATE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SENT';
export const CREATE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SUCCEEDED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SUCCEEDED';
export const CREATE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_FAILED = '[service-cases] CREATE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_FAILED';

export const DELETE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST = '[service-cases] DELETE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST';
export const DELETE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SENT = '[service-cases] DELETE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SENT';
export const DELETE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SUCCEEDED = '[service-cases] DELETE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_SUCCEEDED';
export const DELETE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_FAILED = '[service-cases] DELETE_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFT_REQUEST_FAILED';

export const DELETE_SERVICE_ASSIGNMENT_REQUEST = '[service-cases] DELETE_SERVICE_ASSIGNMENT_REQUEST';
export const DELETE_SERVICE_ASSIGNMENT_REQUEST_SENT = '[service-cases] DELETE_SERVICE_ASSIGNMENT_REQUEST_SENT';
export const DELETE_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] DELETE_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED';
export const DELETE_SERVICE_ASSIGNMENT_REQUEST_FAILED = '[service-cases] DELETE_SERVICE_ASSIGNMENT_REQUEST_FAILED';

export const SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const FETCH_SERVICE_PROVIDER_DATA_REQUEST = '[service-cases] FETCH_SERVICE_PROVIDER_DATA_REQUEST';
export const FETCH_SERVICE_PROVIDER_DATA_REQUEST_SENT = '[service-cases] FETCH_SERVICE_PROVIDER_DATA_REQUEST_SENT';
export const FETCH_SERVICE_PROVIDER_DATA_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_PROVIDER_DATA_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_PROVIDER_DATA_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_PROVIDER_DATA_REQUEST_FAILED';

export const SEARCH_ARCGIS_NEARBY_POIS_REQUEST = '[service-cases] SEARCH_ARCGIS_NEARBY_POIS_REQUEST';
export const SEARCH_ARCGIS_NEARBY_POIS_REQUEST_SENT = '[service-cases] SEARCH_ARCGIS_NEARBY_POIS_REQUEST_SENT';
export const SEARCH_ARCGIS_NEARBY_POIS_REQUEST_SUCCEEDED = '[service-cases] SEARCH_ARCGIS_NEARBY_POIS_REQUEST_SUCCEEDED';
export const SEARCH_ARCGIS_NEARBY_POIS_REQUEST_FAILED = '[service-cases] SEARCH_ARCGIS_NEARBY_POIS_REQUEST_FAILED';

export const SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const FETCH_DAMAGE_LOCATION_TO_FINAL_DESTINATION_ROUTE_REQUEST = '[service-cases] FETCH_DAMAGE_LOCATION_TO_FINAL_DESTINATION_ROUTE_REQUEST';
export const FETCH_DAMAGE_LOCATION_TO_FINAL_DESTINATION_ROUTE_REQUEST_SENT = '[service-cases] FETCH_DAMAGE_LOCATION_TO_FINAL_DESTINATION_ROUTE_REQUEST_SENT';
export const FETCH_DAMAGE_LOCATION_TO_FINAL_DESTINATION_ROUTE_REQUEST_SUCCEEDED = '[service-cases] FETCH_DAMAGE_LOCATION_TO_FINAL_DESTINATION_ROUTE_REQUEST_SUCCEEDED';
export const FETCH_DAMAGE_LOCATION_TO_FINAL_DESTINATION_ROUTE_REQUEST_FAILED = '[service-cases] FETCH_DAMAGE_LOCATION_TO_FINAL_DESTINATION_ROUTE_REQUEST_FAILED';

export const SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_ACE_PARTNER_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const FETCH_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE_REQUEST = '[service-cases] FETCH_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE_REQUEST';
export const FETCH_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE_REQUEST_SENT = '[service-cases] FETCH_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE_REQUEST_SENT';
export const FETCH_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE_REQUEST_SUCCEEDED = '[service-cases] FETCH_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE_REQUEST_SUCCEEDED';
export const FETCH_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE_REQUEST_FAILED = '[service-cases] FETCH_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_SERVICE_ASSIGNMENTS = '[service-cases] STORE_SERVICE_ASSIGNMENTS';
export const SET_SERVICE_ASSIGNMENT_PERSISTENCE_STATE = '[service-cases] SET_SERVICE_ASSIGNMENT_PERSISTENCE_STATE';
export const STORE_RENTAL_CAR_ACE_PARTNERS = '[service-cases] STORE_RENTAL_CAR_ACE_PARTNERS';
export const STORE_SARC_ACE_PARTNER_STATIONS = '[service-cases] STORE_SARC_ACE_PARTNER_STATIONS';
export const STORE_RENTAL_CAR_ACE_PARTNER_SIPP_CODES = '[service-cases] STORE_RENTAL_CAR_ACE_PARTNER_SIPP_CODES';
export const SET_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFTS = '[service-cases] SET_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFTS';
export const STORE_TRAILER_RETURN_SERVICE_PROVIDERS = '[service-cases] STORE_TRAILER_RETURN_SERVICE_PROVIDERS';
export const SET_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_CANDIDATES = '[service-cases] SET_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_CANDIDATES';
export const RESET_RELEVANT_LOCATION_CANDIDATES = '[service-cases] RESET_RELEVANT_LOCATION_CANDIDATES';
export const STORE_SERVICE_PROVIDERS = '[service-cases] STORE_SERVICE_PROVIDERS';
export const RESET_SERVICE_PROVIDERS = '[service-cases] RESET_SERVICE_PROVIDERS';
export const SET_ARCGIS_NEARBY_POIS = '[service-cases] SET_ARCGIS_NEARBY_POIS';
export const RESET_ARCGIS_NEARBY_POIS_RESULTS = '[service-cases] RESET_ARCGIS_NEARBY_POIS_RESULTS';
export const SET_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_CANDIDATES = '[service-cases] SET_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_CANDIDATES';
export const SET_SERVICE_ASSIGNMENT_FINAL_DESTINATION_ROUTE = '[service-cases] SET_SERVICE_ASSIGNMENT_FINAL_DESTINATION_ROUTE';
export const SET_ACE_PARTNER_LOCATION_CANDIDATES = '[service-cases] SET_ACE_PARTNER_LOCATION_CANDIDATES';
export const SET_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE = '[service-cases] SET_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE';
export const SET_ERROR_CODE = '[service-cases] SET_ERROR_CODE';
export const RESET_ERROR_CODE = '[service-cases] RESET_ERROR_CODE';
export const STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION = '[service-cases] STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION';
export const SET_SERVICE_PROVIDER_SEARCH_PERSISTENCE_STATE = '[service-cases] SET_SERVICE_PROVIDER_SEARCH_PERSISTENCE_STATE';
