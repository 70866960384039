import {put, select, take, fork} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import {apmACEPartnerTypes, persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import routePaths from '../../routePaths';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';

const loadContractPartners = function* loadContractPartners() {
    const {areAllContractPartnersFetched} = yield select(state => state.contractPartners);
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {location} = yield select(state => state.router);

    const serviceCaseSearchScreenMatch = matchPath(location.pathname, {
        path: routePaths.SERVICE_CASE_SEARCH,
        exact: true,
    });

    if (areAllContractPartnersFetched) return;

    // add loaders on search screens because of slow load time
    if (serviceCaseSearchScreenMatch) {
        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_SEARCH_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.PENDING},
        });
    }

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST,
        partnerManagementService.getACEPartnersV2,
        {
            partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_FAILED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {acePartnerDTOs: contractPartnerDTOs} = response;

        yield put({
            type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
            payload: {contractPartnerDTOs},
        });

        // set areAllContractPartnersFetched flag to true and prevent multiple fetches per session
        yield put({
            type: contractPartnerActionTypes.SET_ARE_ALL_CONTRACT_PARTNERS_FETCHED,
            payload: {areAllContractPartnersFetched: true},
        });

        if (serviceCaseSearchScreenMatch) {
            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_CASE_SEARCH_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.READY},
            });
        }
    }
};

export default loadContractPartners;
