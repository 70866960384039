/**
 * Command action types
 */
export const SEARCH_CONTRACT_PARTNERS = '[contract-partners] SEARCH_CONTRACT_PARTNERS';
export const INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW = '[contract-partners] INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW';
export const DECLINE_CREATE_TEMPORARY_RESTRICTION = '[contract-partners] DECLINE_CREATE_TEMPORARY_RESTRICTION';
export const CONFIRM_CREATE_TEMPORARY_RESTRICTION = '[contract-partners] CONFIRM_CREATE_TEMPORARY_RESTRICTION';
export const INITIATE_EDIT_TEMPORARY_RESTRICTION_FLOW = '[contract-partners] INITIATE_EDIT_TEMPORARY_RESTRICTION_FLOW';
export const CONFIRM_EDIT_TEMPORARY_RESTRICTION = '[contract-partners] CONFIRM_EDIT_TEMPORARY_RESTRICTION';
export const DECLINE_EDIT_TEMPORARY_RESTRICTION = '[contract-partners] DECLINE_EDIT_TEMPORARY_RESTRICTION';
export const INITIATE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT_FLOW = '[contract-partners] INITIATE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT_FLOW';
export const DECLINE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT = '[contract-partners] DECLINE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT';
export const CONFIRM_CREATE_CONTRACT_PARTNER_QUALITY_REPORT = '[contract-partners] CONFIRM_CREATE_CONTRACT_PARTNER_QUALITY_REPORT';
export const SEARCH_SERVICE_CASES = '[contract-partners] SEARCH_SERVICE_CASES';
export const INITIATE_CP_OVERVIEW_DOWNLOAD_FLOW = '[contract-partners] INITIATE_CP_OVERVIEW_DOWNLOAD_FLOW';

/**
 * Event action types
 */
export const SEARCH_CONTRACT_PARTNERS_REQUEST = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST';
export const SEARCH_CONTRACT_PARTNERS_REQUEST_SENT = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST_SENT';
export const SEARCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED';
export const SEARCH_CONTRACT_PARTNERS_REQUEST_FAILED = '[contract-partners] SEARCH_CONTRACT_PARTNERS_REQUEST_FAILED';

export const FETCH_CONTRACT_PARTNERS_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST';
export const FETCH_CONTRACT_PARTNERS_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNERS_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNERS_REQUEST_FAILED';

export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST';
export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT';
export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED';
export const CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED = '[contract-partners] CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED';

export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST';
export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_FAILED';

export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST';
export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SENT';
export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED';
export const UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED = '[contract-partners] UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED';

export const FETCH_ACE_COMMISSIONERS_REQUEST = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST';
export const FETCH_ACE_COMMISSIONERS_REQUEST_SENT = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST_SENT';
export const FETCH_ACE_COMMISSIONERS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST_SUCCEEDED';
export const FETCH_ACE_COMMISSIONERS_REQUEST_FAILED = '[contract-partners] FETCH_ACE_COMMISSIONERS_REQUEST_FAILED';

export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST';
export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SENT = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SENT';
export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SUCCEEDED = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SUCCEEDED';
export const CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_FAILED = '[contract-partners] CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_FAILED';

export const FORWARD_TEMPORARY_RESTRICTION_REQUEST = '[contract-partners] FORWARD_TEMPORARY_RESTRICTION_REQUEST';
export const FORWARD_TEMPORARY_RESTRICTION_REQUEST_SENT = '[contract-partners] FORWARD_TEMPORARY_RESTRICTION_REQUEST_SENT';
export const FORWARD_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED = '[contract-partners] FORWARD_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED';
export const FORWARD_TEMPORARY_RESTRICTION_REQUEST_FAILED = '[contract-partners] FORWARD_TEMPORARY_RESTRICTION_REQUEST_FAILED';

export const DOWNLOAD_CP_OVERVIEW_REQUEST = '[contract-partners] DOWNLOAD_CP_OVERVIEW_REQUEST';
export const DOWNLOAD_CP_OVERVIEW_REQUEST_SENT = '[contract-partners] DOWNLOAD_CP_OVERVIEW_REQUEST_SENT';
export const DOWNLOAD_CP_OVERVIEW_REQUEST_SUCCEEDED = '[contract-partners] DOWNLOAD_CP_OVERVIEW_REQUEST_SUCCEEDED';
export const DOWNLOAD_CP_OVERVIEW_REQUEST_FAILED = '[contract-partners] DOWNLOAD_CP_OVERVIEW_REQUEST_FAILED';

export const FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST = '[contract-partners] FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST';
export const FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_SENT = '[contract-partners] FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_SENT';
export const FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_SUCCEEDED = '[contract-partners] FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_SUCCEEDED';
export const FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_FAILED = '[contract-partners] FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_FAILED';
/**
 * Store action types
 */
export const SET_ARE_SEARCH_RESULTS_VALID = '[contract-partners] SET_ARE_SEARCH_RESULTS_VALID';
export const STORE_CONTRACT_PARTNER_SEARCH_RESULTS = '[contract-partners] STORE_CONTRACT_PARTNER_SEARCH_RESULTS';
export const STORE_CONTRACT_PARTNERS = '[contract-partners] STORE_CONTRACT_PARTNERS';
export const STORE_CONTRACT_PARTNER_RESTRICTIONS = '[contract-partners] STORE_CONTRACT_PARTNER_RESTRICTIONS';
export const STORE_ACE_COMMISSIONERS = '[contract-partners] STORE_ACE_COMMISSIONERS';
export const STORE_SERVICE_CASE_SEARCH_RESULTS = '[contract-partners] STORE_SERVICE_CASE_SEARCH_RESULTS';
export const SET_CONTRACT_PARTNER_SEARCH_PERSISTENCE_STATE = '[contract-partners] SET_CONTRACT_PARTNER_SEARCH_PERSISTENCE_STATE';
export const STORE_CONTRACT_PARTNER_ACCOUNT_MANAGERS = '[contract-partners] STORE_CONTRACT_PARTNER_ACCOUNT_MANAGERS';
export const SET_ARE_ALL_CONTRACT_PARTNERS_FETCHED = '[contract-partners] SET_ARE_ALL_CONTRACT_PARTNERS_FETCHED';
