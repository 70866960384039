import {select, fork, take, put} from 'redux-saga/effects';
import {persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as taskActionTypes from '../taskActionTypes';

/**
 * Search tasks
 */
const searchTasks = function* searchTasks({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');

    const {searchQueryParams} = payload;

    yield put({
        type: taskActionTypes.SET_TASK_SEARCH_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.PENDING},
    });

    yield fork(
        fetchRequest,
        taskActionTypes.SEARCH_TASKS_REQUEST,
        ecsTaskManagementService.searchTasks, {
            searchQueryParams,
        },
    );

    const searchTaskResponseAction = yield take([
        taskActionTypes.SEARCH_TASKS_REQUEST_SUCCEEDED,
        taskActionTypes.SEARCH_TASKS_REQUEST_FAILED,
    ]);

    if (!searchTaskResponseAction.error) {
        const {response} = searchTaskResponseAction.payload;
        const {results: taskSearchResults, totalCount: taskSearchResultsCount} = response;

        yield put({
            type: taskActionTypes.STORE_TASK_SEARCH_RESULTS,
            payload: {taskSearchResults, taskSearchResultsCount},
        });

        yield put({
            type: taskActionTypes.SET_TASK_SEARCH_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.READY},
        });
    }
};

export default searchTasks;
