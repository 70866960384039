import {produce, current} from 'immer';
import {TemporaryRestriction, ACEPartner, AccountManager, persistenceStates} from '@ace-de/eua-entity-types';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import createNewServiceCase from '../service-cases/createNewServiceCase';

const initialState = {
    areSearchResultsValid: false,
    contractPartnerSearchResults: [],
    contractPartnerSearchResultsCount: 0,
    contractPartners: {},
    commissioners: {},
    serviceCaseSearchResults: [],
    serviceCaseSearchResultsCount: 0,
    searchPersistenceState: persistenceStates.READY,
    accountManagers: {},
    areAllContractPartnersFetched: false,
};

/**
 * Contract partner reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const contractPartnerReducer = produce((draft, action) => {
    switch (action.type) {
        case contractPartnerActionTypes.SET_ARE_SEARCH_RESULTS_VALID: {
            const {areSearchResultsValid} = action.payload;
            draft.areSearchResultsValid = areSearchResultsValid;
            break;
        }

        case contractPartnerActionTypes.STORE_CONTRACT_PARTNER_SEARCH_RESULTS: {
            const {contractPartnerSearchResults, contractPartnerSearchResultsCount} = action.payload;
            draft.contractPartnerSearchResults = contractPartnerSearchResults.map(contractPartnerDTO => {
                return new ACEPartner().fromDTO(contractPartnerDTO);
            });
            draft.contractPartnerSearchResultsCount = typeof contractPartnerSearchResultsCount === 'number'
                ? contractPartnerSearchResultsCount : 0;
            break;
        }

        case contractPartnerActionTypes.STORE_CONTRACT_PARTNERS: {
            const {contractPartnerDTOs} = action.payload;
            contractPartnerDTOs.forEach(contractPartnerDTO => {
                const contractPartner = draft.contractPartners[contractPartnerDTO.id];
                if (!contractPartner) {
                    const newContractPartner = new ACEPartner().fromDTO(contractPartnerDTO);
                    draft.contractPartners[newContractPartner.id] = newContractPartner;
                    return;
                }
                draft.contractPartners[contractPartnerDTO.id] = contractPartner.fromDTO(contractPartnerDTO);
            });
            break;
        }

        case contractPartnerActionTypes.STORE_CONTRACT_PARTNER_RESTRICTIONS: {
            const {contractPartnerId, contractPartnerRestrictionDTOs} = action.payload;
            let draftContractPartner = current(draft.contractPartners[contractPartnerId]);

            if (draftContractPartner) {
                contractPartnerRestrictionDTOs.forEach(contractPartnerRestrictionDTO => {
                    const newContractPartnerRestrictions = new TemporaryRestriction().fromDTO(
                        contractPartnerRestrictionDTO,
                    );
                    draftContractPartner = draftContractPartner
                        .setTemporaryRestriction(newContractPartnerRestrictions.id, newContractPartnerRestrictions);
                });
                draft.contractPartners[contractPartnerId] = draftContractPartner;
            }
            break;
        }

        case contractPartnerActionTypes.STORE_ACE_COMMISSIONERS: {
            const {commissionerDTOs} = action.payload;
            commissionerDTOs.forEach(commissionerDTO => {
                const commissioner = draft.commissioners[commissionerDTO.id];
                if (!commissioner) {
                    const newCommissioner = new ACEPartner().fromDTO(commissionerDTO);
                    draft.commissioners[newCommissioner.id] = newCommissioner;
                    return;
                }
                draft.commissioners[commissionerDTO.id] = commissioner.fromDTO(commissionerDTO);
            });
            break;
        }

        case contractPartnerActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS: {
            const {serviceCaseSearchResults, serviceCaseSearchResultsCount} = action.payload;
            draft.serviceCaseSearchResults = serviceCaseSearchResults.map(serviceCaseDTO => {
                return createNewServiceCase(serviceCaseDTO.caseType).fromDTO(serviceCaseDTO);
            });
            draft.serviceCaseSearchResultsCount = serviceCaseSearchResultsCount;
            break;
        }

        case contractPartnerActionTypes.SET_CONTRACT_PARTNER_SEARCH_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.searchPersistenceState = persistenceState;
            break;
        }

        case contractPartnerActionTypes.STORE_CONTRACT_PARTNER_ACCOUNT_MANAGERS: {
            const {accountManagerDTOs = []} = action.payload;
            accountManagerDTOs.forEach(accountManagerDTO => {
                const accountManager = draft.accountManagers[accountManagerDTO.regionId];
                if (!accountManager) {
                    const newAccountManager = new AccountManager().fromDTO(accountManagerDTO);
                    draft.accountManagers[newAccountManager.regionId] = newAccountManager;
                    return;
                }
                draft.accountManagers[accountManagerDTO.regionId] = accountManager.fromDTO(accountManagerDTO);
            });
            break;
        }

        case contractPartnerActionTypes.SET_ARE_ALL_CONTRACT_PARTNERS_FETCHED: {
            const {areAllContractPartnersFetched} = action.payload;
            draft.areAllContractPartnersFetched = !!areAllContractPartnersFetched;
            break;
        }

        default:
            // no-op
    }
}, initialState);

export default contractPartnerReducer;
