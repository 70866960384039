import React, {Fragment, useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efDACHCountryCodes, persistenceStates} from '@ace-de/eua-entity-types';
import {useArcGISMap, createMarkerGraphic, markerVehiclePNG, markerFinalTowingPNG} from '@ace-de/eua-arcgis-map';
import {useStyles, ContentBlock, ContentItem, Panel, ScrollableBlock, SearchBox, Pill, ButtonPrimary} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, checkmarkIcon, resetIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as savActionTypes from './savActionTypes';
import GooglePlacesLogo from '../application/ui-elements/GooglePlacesLogo';
import TowingDestinationItem from './ui-elements/TowingDestinationItem';
import FinalDestinationItem from './ui-elements/FinalDestinationItem';

const SAVTowingDestinationTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sav_towing_destination_tab');
    const arcGISMap = useArcGISMap('service-assignment-vehicle');
    const {serviceCase, serviceAssignment, searchSAVTowingDestinations, submitSAVTowingDestinationForm} = props;
    const {resetSelectedTowingDestinationLocationId} = props;
    const [selectedTowingDestinationId, setSelectedTowingDestinationId] = useState('');
    const [towingDestinationSearchQuery, setTowingDestinationSearchQuery] = useState(null);
    const currentTowingDestinationIdRef = useRef(serviceAssignment?.towingDestination?.id || null);

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        resetSelectedTowingDestinationLocationId({
            serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
        });
        completeRouteUnmountSideEffect({
            caller: savActionTypes.RESET_SAV_SELECTED_TOWING_DESTINATION_LOCATION_ID,
        });
    }, [serviceAssignment.serviceCaseId, serviceAssignment.lineNo, resetSelectedTowingDestinationLocationId]);

    useEffect(() => {
        if (towingDestinationSearchQuery !== null || !serviceCase) return;

        const {vehicle, damage} = serviceCase;
        if (damage && damage.location) {
            const searchQueryStringKey = Object.values(efDACHCountryCodes).includes(damage.location.countryCode)
                ? 'input.search_query_string_dach'
                : 'input.search_query_string';
            setTowingDestinationSearchQuery(translateTab(
                searchQueryStringKey,
                {vehicleManufacturer: vehicle?.manufacturer || ''},
            ));
        }
    }, [towingDestinationSearchQuery, serviceCase, translateTab]);

    useEffect(() => {
        const {selectedTowingDestinationLocationId} = serviceAssignment;
        setSelectedTowingDestinationId(selectedTowingDestinationLocationId
            || currentTowingDestinationIdRef.current || null);
    }, [serviceAssignment]);

    useEffect(() => {
        if (!arcGISMap || !serviceCase || !serviceAssignment) return;

        arcGISMap.hideLayer('sav-contract-partner-service-areas');
        arcGISMap.hideLayer('sav-contract-partner-routes');
        arcGISMap.hideLayer('sav-contract-partner-locations');

        const {damage} = serviceCase;
        const {finalDestination, finalDestinationRoute} = serviceAssignment;
        arcGISMap.setGraphics({
            graphics: [
                ...(damage && damage.location
                    ? [
                        createMarkerGraphic({
                            id: 'damageLocation',
                            longitude: damage.location.longitude,
                            latitude: damage.location.latitude,
                            icon: markerVehiclePNG,
                        }),
                    ] : []),
                ...(finalDestination?.location?.id
                    ? [
                        createMarkerGraphic({
                            id: 'finalDestinationLocation',
                            longitude: finalDestination?.location?.longitude,
                            latitude: finalDestination?.location?.latitude,
                            icon: markerFinalTowingPNG,
                        }),
                    ] : []),
                ...(finalDestination?.location?.id && finalDestinationRoute
                    ? [
                        createMarkerGraphic({
                            id: 'finalDestinationRoute',
                            geometry: finalDestinationRoute.geometry,
                            isRouteSymbol: true,
                            isActive: true,
                            color: '#38B100',
                        }),
                    ] : []),
            ],
        });
    }, [arcGISMap, serviceCase, serviceAssignment, translateTab]);

    const handleTowingDestinationSubmit = () => {
        const {towingDestinationRecommendations} = serviceAssignment;
        const newTowingDestination = towingDestinationRecommendations
            .find(towingDestination => towingDestination.id === selectedTowingDestinationId);
        submitSAVTowingDestinationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                towingDestination: newTowingDestination,
                towingDestinationRoute: newTowingDestination.routeToReferentialPoint,
            },
        });
    };

    const handleResetTowingDestination = () => {
        resetSelectedTowingDestinationLocationId({
            serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
        });
        currentTowingDestinationIdRef.current = null;
        setSelectedTowingDestinationId(null);

        submitSAVTowingDestinationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                towingDestination: null,
                towingDestinationRoute: null,
            },
        });
    };

    const handleTowingDestinationSelect = newTowingDestinationId => {
        if (!newTowingDestinationId || selectedTowingDestinationId === newTowingDestinationId) return;

        resetSelectedTowingDestinationLocationId({
            serviceAssignmentId: `${serviceAssignment.serviceCaseId}-${serviceAssignment.lineNo}`,
        });
        currentTowingDestinationIdRef.current = newTowingDestinationId;
        setSelectedTowingDestinationId(newTowingDestinationId);

        const {towingDestinationRecommendations} = serviceAssignment;
        const newTowingDestination = towingDestinationRecommendations
            .find(towingDestination => towingDestination.id === newTowingDestinationId);
        submitSAVTowingDestinationForm({
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseId: serviceAssignment.serviceCaseId,
            serviceAssignmentData: {
                towingDestination: newTowingDestination,
                towingDestinationRoute: newTowingDestination.routeToReferentialPoint,
            },
            isUpdateNeeded: false,
        });
    };

    const handleTowingDestinationSearch = () => {
        if (towingDestinationSearchQuery) {
            searchSAVTowingDestinations({
                serviceCaseId: serviceAssignment.serviceCaseId,
                serviceAssignmentLineNo: serviceAssignment.lineNo,
                searchQuery: towingDestinationSearchQuery,
            });
        }
    };

    // if no service case or service assignment don't render
    if (!serviceCase || !serviceAssignment) return null;

    const {towingDestinationRecommendations, recommendedTowingDestinationId} = serviceAssignment;
    const recommendedTowingDestination = towingDestinationRecommendations
        .find(towingDestination => towingDestination.id === recommendedTowingDestinationId);

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'ace-c-content-block--sidebar',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-content-item--sidebar',
                ])}
            >
                <Panel
                    title={translateTab('panel_title.choose_place')}
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <ScrollableBlock isLabelDisabled={true} className={cx('ace-c-scrollable-block--full-bleed')}>
                        <div
                            className={cx([
                                'global!ace-u-typography--align-left',
                                'global!ace-u-padding--8-32',
                            ])}
                        >
                            <Pill type="information">
                                {translateTab('pill_label.suitable_towing_destination')} {serviceCase.vehicle?.manufacturer || ''}
                            </Pill>
                        </div>
                        <div className={cx(['global!ace-u-flex', 'global!ace-u-padding--0-32'])}>
                            <SearchBox
                                isComposedIn={true}
                                name="searchTowingDestination"
                                placeholder={translateTab('input_placeholder.find_workshop')}
                                className={cx([
                                    'global!ace-u-flex--grow-1',
                                    'global!ace-u-margin--16-0',
                                ])}
                                value={towingDestinationSearchQuery}
                                onChange={setTowingDestinationSearchQuery}
                                onSearchSubmit={handleTowingDestinationSearch}
                                isDropDownDisabled={true}
                                isDisabled={serviceCase.persistenceState === persistenceStates.PENDING
                                    || serviceAssignment.persistenceState === persistenceStates.PENDING}
                            />
                        </div>
                        <GooglePlacesLogo />
                        {!!towingDestinationRecommendations.length && !!recommendedTowingDestination && (
                            <Fragment>
                                <h4
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-typography--align-left',
                                        'global!ace-u-margin--24-32',
                                    ])}
                                >
                                    {translateTab('heading.nearest_workshop')}
                                </h4>
                                <TowingDestinationItem
                                    key={recommendedTowingDestination.id}
                                    towingDestination={recommendedTowingDestination}
                                    isSelected={selectedTowingDestinationId === recommendedTowingDestination.id}
                                    onSelect={handleTowingDestinationSelect}
                                />
                            </Fragment>
                        )}
                        {!!serviceAssignment?.finalDestination && (
                            <Fragment>
                                <h4
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-typography--align-left',
                                        'global!ace-u-margin--24-32',
                                    ])}
                                >
                                    {translateTab('heading.final_destination')}
                                </h4>
                                <FinalDestinationItem
                                    recommendedTowingDestination={recommendedTowingDestination}
                                />
                            </Fragment>
                        )}
                        {!!towingDestinationRecommendations.length && (
                            <Fragment>
                                <div className={cx('global!ace-u-margin--16-32')}>
                                    <ButtonPrimary
                                        name="submitTowingDestination"
                                        onClick={handleTowingDestinationSubmit}
                                        className={cx('global!ace-u-full-width', {
                                            'ace-c-button-primary--is-dark-positive':
                                                serviceAssignment.towingDestination?.id === selectedTowingDestinationId,
                                        })}
                                        isDisabled={!selectedTowingDestinationId}
                                    >
                                        {serviceAssignment.towingDestination?.id === selectedTowingDestinationId
                                            ? (
                                                <Fragment>
                                                    {translateTab('button_label.towing_destination_confirmed')}
                                                    <Icon
                                                        icon={checkmarkIcon}
                                                        className={cx(
                                                            'ace-c-icon--color-contrast',
                                                            'global!ace-u-margin--left-16',
                                                        )}
                                                    />
                                                </Fragment>
                                            ) : translateTab('button_label.confirm_towing_destination')
                                        }
                                    </ButtonPrimary>
                                </div>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-flex--justify-center',
                                        'global!ace-u-margin--0-32',
                                    ])}
                                >
                                    <InteractiveIcon
                                        icon={resetIcon}
                                        className={cx([
                                            'ace-c-interactive-icon--reverse',
                                            'ace-c-interactive-icon--highlight',
                                        ])}
                                        onClick={handleResetTowingDestination}
                                    >
                                        {translateTab('button_label.reset_towing_destination')}
                                    </InteractiveIcon>
                                </div>
                                <h4
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-typography--align-left',
                                        'global!ace-u-margin--top-32',
                                        'global!ace-u-margin--left-32',
                                        'global!ace-u-margin--right-32',
                                    ])}
                                >
                                    {translateTab('heading.other_workshops')}
                                </h4>
                                {towingDestinationRecommendations.map(towingDestination => {
                                    if (recommendedTowingDestination
                                        && towingDestination.id === recommendedTowingDestination.id) return null;

                                    return (
                                        <TowingDestinationItem
                                            key={towingDestination.id}
                                            towingDestination={towingDestination}
                                            recommendedTowingDestination={recommendedTowingDestination}
                                            isSelected={selectedTowingDestinationId === towingDestination.id}
                                            onSelect={handleTowingDestinationSelect}
                                        />
                                    );
                                })}
                            </Fragment>
                        )}
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
        </ContentBlock>
    );
};

SAVTowingDestinationTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    searchSAVTowingDestinations: PropTypes.func.isRequired,
    submitSAVTowingDestinationForm: PropTypes.func.isRequired,
    resetSelectedTowingDestinationLocationId: PropTypes.func.isRequired,
};

SAVTowingDestinationTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    searchSAVTowingDestinations: payload => dispatch({
        type: savActionTypes.SEARCH_SAV_TOWING_DESTINATIONS,
        payload,
    }),
    submitSAVTowingDestinationForm: payload => dispatch({
        type: savActionTypes.SUBMIT_SAV_TOWING_DESTINATION_FORM,
        payload,
    }),
    resetSelectedTowingDestinationLocationId: payload => dispatch({
        type: savActionTypes.RESET_SAV_SELECTED_TOWING_DESTINATION_LOCATION_ID,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAVTowingDestinationTab));
