import React, {Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {eupShiftRoleTypes} from '@ace-de/eua-entity-types';
import {useStyles, Avatar, AvatarPlaceholder, List, Panel, Divider} from '@ace-de/ui-components';

const CurrentTeamPanel = props => {
    const {cx} = useStyles();
    const {activeTeamMembers} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');

    // temporary solution since the v1/shift does not return all shift roles, but only active onse
    // TBD: BE to adjust v1/shift endpoint, so we can get all roles instantly
    // NOTE: as per https://computerrock.atlassian.net/browse/ACELEA-1954, we should not display all roles
    const currentTeamData = useMemo(() => {
        const roleToDisplay = [
            eupShiftRoleTypes.DISPATCHER_1,
            eupShiftRoleTypes.DISPATCHER_2,
            eupShiftRoleTypes.DISPATCHER_3,
            eupShiftRoleTypes.DISPO,
            eupShiftRoleTypes.INBOX,
            eupShiftRoleTypes.TEAM_LEAD,
        ];
        const roleToUserMapping = Object.keys(eupShiftRoleTypes)
            .filter(role => roleToDisplay.includes(role))
            .reduce((acc, roleType) => {
                acc[roleType] = null;
                return acc;
            }, {});

        if (!activeTeamMembers) return roleToUserMapping;
        Object.keys(activeTeamMembers).forEach(roleType => {
            if (!roleToDisplay.includes(roleType)) return;
            roleToUserMapping[roleType] = activeTeamMembers[roleType] || null;
        });
        return roleToUserMapping;
    }, [activeTeamMembers]);

    return (
        <Panel
            title={translateScreen('current_team_panel.title')}
        >
            <List className={cx('global!ace-u-full-width')}>
                {Object.keys(currentTeamData).map(teamMemberRole => {
                    const loggedUser = currentTeamData[teamMemberRole];
                    return (
                        <Fragment key={teamMemberRole}>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-row',
                                    'global!ace-u-flex--align-center',
                                ])}
                            >
                                {loggedUser ? (
                                    <Avatar
                                        className={cx('global!ace-u-margin--16-16-16-0')}
                                        alt={loggedUser.initials}
                                    />
                                ) : (
                                    <AvatarPlaceholder className={cx('global!ace-u-margin--16-16-16-0')} />
                                )}
                                <div>
                                    <p
                                        className={cx([
                                            'global!ace-u-typography--variant-highlight',
                                            'global!ace-u-typography--color-medium-emphasis',
                                        ])}
                                    >
                                        {translate(`global.user_shift_role.${snakeCase(teamMemberRole)}`)}
                                    </p>
                                    <p className={cx('global!ace-u-typography--variant-body')}>
                                        {loggedUser !== null
                                            ? [loggedUser?.firstName, loggedUser?.lastName].filter(value => !!value).join(' ')
                                            : translateScreen('current_team_panel.role_not_taken')}
                                    </p>
                                </div>
                            </div>
                            <Divider />
                        </Fragment>
                    );
                })}
            </List>
        </Panel>
    );
};

CurrentTeamPanel.propTypes = {
    activeTeamMembers: PropTypes.object.isRequired,
};
export default CurrentTeamPanel;
