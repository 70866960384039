import {take, fork, put, select} from 'redux-saga/effects';
import {push, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import routePaths from '../../routePaths';
import fetchRequest from '../../application/sagas/fetchRequest';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import * as sarjActionTypes from '../sarjActionTypes';
import sarjScreenTabs from '../sarjScreenTabs';

const createServiceAssignmentReturnJourneyFlow = function* createServiceAssignmentReturnJourneyFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        const {payload} = yield take(sarjActionTypes.CREATE_SERVICE_ASSIGNMENT_RETURN_JOURNEY);
        const {serviceCaseId} = payload;
        const {serviceCases = {}} = yield select(state => state.serviceCases);
        const serviceCase = serviceCases[serviceCaseId] || null;

        yield fork(fetchRequest,
            sarjActionTypes.CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST,
            ecsFlowService.createServiceAssignment, {
                serviceCaseId,
                assignmentType: efServiceAssignmentTypes.RETURN_JOURNEY,
            });

        const responseAction = yield take([
            sarjActionTypes.CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST_SUCCEEDED,
            sarjActionTypes.CREATE_SERVICE_CASE_RETURN_JOURNEY_ASSIGNMENT_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {serviceAssignmentDTO} = response;
            const serviceAssignmentLineNo = serviceAssignmentDTO.lineNo;

            yield put({
                type: serviceAssignmentActionTypes.STORE_SERVICE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs: [serviceAssignmentDTO]},
            });

            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_CASE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs: [serviceAssignmentDTO], serviceCaseId},
            });

            // serviceAssignment.callbackPhoneNo = serviceCase.callbackPhoneNo, by default
            if (serviceCase?.callbackPhoneNo) {
                yield* updateServiceAssignment({
                    caller: sarjActionTypes.CREATE_SERVICE_ASSIGNMENT_RETURN_JOURNEY,
                    assignmentType: efServiceAssignmentTypes.RETURN_JOURNEY,
                    serviceAssignmentLineNo,
                    serviceCaseId,
                    serviceAssignmentData: {
                        callbackPhoneNo: serviceCase.callbackPhoneNo,
                    },
                });
            }

            yield put(push(resolveRoute(
                routePaths.SERVICE_ASSIGNMENT_RETURN_JOURNEY,
                {
                    serviceCaseId,
                    serviceAssignmentLineNo,
                },
                {search: prepareSearchQueryParams({activeTab: sarjScreenTabs.REQUIREMENTS})},
            )));
        }
    }
};

export default createServiceAssignmentReturnJourneyFlow;
