import {fork, select, take} from 'redux-saga/effects';
import {closeModal, openModal} from '@computerrock/formation-router/sagas';
import {efAdditionalServiceRequestedTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sarcActionTypes from '../sarcActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import calculateRentalCarPrice from '../calculateRentalCarPrice';
import calculateAdditionalServicePrice from '../calculateAdditionalServicePrice';
import config from '../../config';

/**
 * Update SARC ACE partner station flow
 */
const updateSARCACEPartnerStationFlow = function* updateSARCACEPartnerStationFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        yield take(sarcActionTypes.INITIATE_SARC_UPDATE_ACE_PARTNER_STATION_FLOW);

        yield* openModal(modalIds.SARC_UPDATE_ACE_PARTNER_STATION);
        const chosenModalOption = yield take([
            sarcActionTypes.CONFIRM_SARC_UPDATE_ACE_PARTNER_STATION,
            sarcActionTypes.DECLINE_SARC_UPDATE_ACE_PARTNER_STATION,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === sarcActionTypes.CONFIRM_SARC_UPDATE_ACE_PARTNER_STATION) {
            const {serviceAssignmentLineNo, serviceAssignmentData, serviceCaseId} = chosenModalOption.payload;
            const {isRentalCarStationPickupEnteredManually, numberOfDays} = chosenModalOption.payload;
            const {serviceAssignments} = yield select(state => state.serviceAssignments);
            const serviceAssignment = serviceAssignments[`${serviceCaseId}-${serviceAssignmentLineNo}`];
            const {serviceCases} = yield select(state => state.serviceCases);
            const serviceCase = serviceCases[`${serviceCaseId}`];
            let updatedRentalCarStationPickup = null;
            let calculatedRentalCarCost = 0;
            const additionalServiceCosts = {
                [efAdditionalServiceRequestedTypes.EMERGENCY_SERVICE_FEE]: {
                    calculatedCost: 0,
                },
                [efAdditionalServiceRequestedTypes.WINTER_TIRES]: {
                    calculatedCost: 0,
                },
                [efAdditionalServiceRequestedTypes.AUTOMATIC]: {
                    calculatedCost: 0,
                },
                [efAdditionalServiceRequestedTypes.TRAILER]: {
                    calculatedCost: 0,
                },
            };

            if (serviceAssignmentData?.sippCode && serviceAssignmentData?.acePartner?.id && numberOfDays) {
                yield fork(
                    fetchRequest,
                    sarcActionTypes.FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST,
                    partnerManagementService.getServiceProviderRentalCarPricing,
                    {serviceProviderId: serviceAssignmentData.acePartner.id},
                );

                const responseAction = yield take([
                    sarcActionTypes.FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_SUCCEEDED,
                    sarcActionTypes.FETCH_SARC_SERVICE_PROVIDER_PRICING_REQUEST_FAILED,
                ]);

                if (responseAction.error) return;

                const {response} = responseAction.payload;
                const {rentalCarPricingDTOs} = response;

                const rentalCarPricing = rentalCarPricingDTOs
                    .find(pricingDTO => pricingDTO.sippCode === serviceAssignmentData?.sippCode);
                if (rentalCarPricing) {
                    calculatedRentalCarCost = calculateRentalCarPrice(rentalCarPricing, numberOfDays);
                }

                if (serviceAssignment.additionalServiceRequested) {
                    const additionalServicesPricing = rentalCarPricingDTOs.filter(pricingDTO => {
                        return pricingDTO.additionalService;
                    });
                    Object.values(serviceAssignment.additionalServiceRequested).forEach(additionalServiceRequested => {
                        const additionalServicePricing = additionalServicesPricing.find(pricingDTO => {
                            return pricingDTO.additionalService === additionalServiceRequested;
                        });

                        if (additionalServicePricing) {
                            /* eslint-disable-next-line max-len */
                            additionalServiceCosts[additionalServiceRequested].calculatedCost = calculateAdditionalServicePrice(
                                additionalServicePricing,
                                numberOfDays,
                            );
                        }
                    });
                }
            }

            if (isRentalCarStationPickupEnteredManually) {
                const {rentalCarStationPickup} = serviceAssignmentData;
                if (rentalCarStationPickup && rentalCarStationPickup.location) {
                    const displayAddress = [
                        rentalCarStationPickup.location.street,
                        rentalCarStationPickup.location.postCode,
                        rentalCarStationPickup.location.city,
                    ].filter(value => !!value).join(', ');

                    yield fork(
                        fetchRequest,
                        sarcActionTypes.SEARCH_SARC_PICKUP_STATION_ADDRESS_GEOLOCATION_REQUEST,
                        arcGISRESTService.searchAddressLocation,
                        {
                            singleLine: displayAddress,
                            forStorage: true,
                        },
                    );

                    const searchSARCPickupStationAddressGeolocation = yield take([
                        sarcActionTypes.SEARCH_SARC_PICKUP_STATION_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED,
                        sarcActionTypes.SEARCH_SARC_PICKUP_STATION_ADDRESS_GEOLOCATION_REQUEST_FAILED,
                    ]);
                    if (searchSARCPickupStationAddressGeolocation.error) return;

                    const {response} = searchSARCPickupStationAddressGeolocation.payload;
                    const {arcGISLocationCandidateDTOs} = response;

                    const arcGISLocationCandidateDTO = arcGISLocationCandidateDTOs[0];
                    if (arcGISLocationCandidateDTO) {
                        updatedRentalCarStationPickup = {
                            ...rentalCarStationPickup,
                            location: {
                                ...rentalCarStationPickup.location,
                                latitude: arcGISLocationCandidateDTO.latitude,
                                longitude: arcGISLocationCandidateDTO.longitude,
                                countryCode: arcGISLocationCandidateDTO.countryCode,
                                formattedAddress: arcGISLocationCandidateDTO.formattedAddress,
                            },
                        };
                    }
                }
            }

            const serviceCost = additionalServiceCosts[efAdditionalServiceRequestedTypes.EMERGENCY_SERVICE_FEE];
            const winterTiresCost = additionalServiceCosts[efAdditionalServiceRequestedTypes.WINTER_TIRES];
            const otherCost = additionalServiceCosts[efAdditionalServiceRequestedTypes.TRAILER].calculatedCost
                + additionalServiceCosts[efAdditionalServiceRequestedTypes.AUTOMATIC].calculatedCost;

            yield* updateServiceAssignment({
                caller: sarcActionTypes.CONFIRM_SARC_UPDATE_ACE_PARTNER_STATION,
                assignmentType: efServiceAssignmentTypes.RENTAL_CAR,
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentData: {
                    ...serviceAssignmentData,
                    ...(!!calculatedRentalCarCost && {
                        costs: {
                            rental: calculatedRentalCarCost,
                            service: serviceCost.calculatedCost,
                            tires: winterTiresCost.calculatedCost,
                            other: otherCost,
                        },
                        budget: {
                            rental: serviceAssignment?.budget?.rental,
                            service: serviceCost.calculatedCost,
                            tires: winterTiresCost.calculatedCost,
                            other: serviceAssignment.budget?.other,
                            delivery: serviceAssignment.budget?.delivery,
                        },
                    }),
                    // reset service assignment costs and budget if the SIPP code is not selected
                    // see https://computerrock.atlassian.net/browse/ACEMS-1123
                    ...(!serviceAssignmentData?.sippCode && {
                        costs: {
                            rental: 0,
                            service: 0,
                            tires: 0,
                            other: 0,
                            delivery: 0,
                        },
                        budget: {
                            rental: serviceAssignment?.budgetPerDay,
                            service: 0,
                            tires: 0,
                            other: 0,
                            delivery: serviceAssignment?.deliveryRequested
                                ? (typeof serviceAssignment.budget?.delivery === 'number'
                                    ? serviceAssignment.budget.delivery
                                    : (typeof serviceCase?.remainingBudget === 'number'
                                        ? serviceCase.remainingBudget
                                        : config.DEFAULT_RENTAL_CAR_DELIVERY_BUDGET))
                                : 0,
                        },
                    }),
                    ...(updatedRentalCarStationPickup ? {rentalCarStationPickup: updatedRentalCarStationPickup} : {}),
                },
            });
        }

        yield* closeModal(modalIds.SARC_UPDATE_ACE_PARTNER_STATION);
    }
};

export default updateSARCACEPartnerStationFlow;
