import {take, fork, put, select} from 'redux-saga/effects';
import {push, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import routePaths from '../../routePaths';
import fetchRequest from '../../application/sagas/fetchRequest';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as serviceCaseActionTypes from '../../service-cases/serviceCaseActionTypes';
import * as saaActionTypes from '../saaActionTypes';
import saaScreenTabs from '../saaScreenTabs';

const createServiceAssignmentAccommodationFlow = function* createServiceAssignmentAccommodationFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        const {payload} = yield take(saaActionTypes.CREATE_SERVICE_ASSIGNMENT_ACCOMMODATION);
        const {serviceCaseId} = payload;
        const {serviceCases = {}} = yield select(state => state.serviceCases);
        const serviceCase = serviceCases[serviceCaseId] || null;

        yield fork(fetchRequest,
            saaActionTypes.CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST,
            ecsFlowService.createServiceAssignment, {
                serviceCaseId,
                assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
            });

        const responseAction = yield take([
            saaActionTypes.CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST_SUCCEEDED,
            saaActionTypes.CREATE_SERVICE_CASE_ACCOMMODATION_ASSIGNMENT_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {serviceAssignmentDTO} = response;
            const serviceAssignmentLineNo = serviceAssignmentDTO.lineNo;
            const serviceAssignmentDTOs = [serviceAssignmentDTO];

            yield put({
                type: serviceAssignmentActionTypes.STORE_SERVICE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs},
            });

            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_CASE_ASSIGNMENTS,
                payload: {serviceAssignmentDTOs, serviceCaseId},
            });

            // serviceAssignment.callbackPhoneNo = serviceCase.callbackPhoneNo, by default
            if (serviceCase?.callbackPhoneNo) {
                yield* updateServiceAssignment({
                    caller: saaActionTypes.CREATE_SERVICE_ASSIGNMENT_ACCOMMODATION,
                    assignmentType: efServiceAssignmentTypes.ACCOMMODATION,
                    serviceAssignmentLineNo,
                    serviceCaseId,
                    serviceAssignmentData: {
                        callbackPhoneNo: serviceCase.callbackPhoneNo,
                    },
                });
            }

            yield put(push(resolveRoute(
                routePaths.SERVICE_ASSIGNMENT_ACCOMMODATION,
                {serviceCaseId, serviceAssignmentLineNo},
                {search: prepareSearchQueryParams({activeTab: saaScreenTabs.REQUIREMENTS})},
            )));
        }
    }
};
export default createServiceAssignmentAccommodationFlow;
