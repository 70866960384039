import {take, fork, put, select} from 'redux-saga/effects';
import {efServiceCaseTypes, Member, ampMemberTariffGroups, ACEPartner} from '@ace-de/eua-entity-types';
import {openModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import config from '../../config';
import * as savActionTypes from '../../service-assignments-vehicle/savActionTypes';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as saosActionTypes from '../../service-assignments-other-services/saosActionTypes';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as memberActionTypes from '../../members/memberActionTypes';
import updateSCMemberResidenceGeolocation from './updateSCMemberResidenceGeolocation';
import updateServiceCase from './updateServiceCase';
import modalIds from '../../modalIds';

/**
 * Create service case flow
 */
const createServiceCaseFlow = function* createServiceCaseFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        const {payload} = yield take(serviceCaseActionTypes.CREATE_SERVICE_CASE);
        const {member, caseType, assignmentType, nonAceMember} = payload;
        const {commissioner, isNonACEMemberEmptyCase = false, maximumBudget, externalCaseId} = payload;
        const {initialAttachments = null} = payload;

        // when an agent wants to create a new case directly from the member search screen,
        // we must store the user for whom the case is being created
        const {members, membersByMembershipNo} = yield select(state => state.members);
        if (!members[member?.id] || !membersByMembershipNo[member?.membershipNo]) {
            yield put({
                type: memberActionTypes.STORE_MEMBER,
                payload: {memberDTO: member},
            });
        }

        if (!nonAceMember
            && (!member.tariffDetails
                || !member.tariffDetails.currentTariff
                || member.tariffDetails.tariffGroup === ampMemberTariffGroups.NON_MEMBER
            )
        ) {
            yield* openModal(modalIds.CREATE_CASE_NO_TARIFF_WARNING);
            continue;
        }

        yield fork(
            fetchRequest,
            serviceCaseActionTypes.CREATE_SERVICE_CASE_REQUEST,
            ecsFlowService.createServiceCase,
            nonAceMember ? {
                memberDTO: Member.objectToDTO(member),
                caseType,
                nonAceMember,
                commissionerDTO: ACEPartner.objectToDTO(commissioner),
            } : {
                memberDTO: member.toDTO(),
                caseType,
                commissionerDTO: config.ACE_COMMISSIONER,
            },
        );

        yield put({
            type: serviceCaseActionTypes.SET_SERVICE_CASE_CREATION_PROGRESS_STATE,
            payload: {isServiceCaseCreationInProgress: true},
        });

        const responseAction = yield take([
            serviceCaseActionTypes.CREATE_SERVICE_CASE_REQUEST_SUCCEEDED,
            serviceCaseActionTypes.CREATE_SERVICE_CASE_REQUEST_FAILED,
        ]);

        if (responseAction.error) {
            yield put({
                type: serviceCaseActionTypes.SET_SERVICE_CASE_CREATION_PROGRESS_STATE,
                payload: {isServiceCaseCreationInProgress: false},
            });
            continue;
        }

        const {response} = responseAction.payload;
        const {serviceCaseDTO} = response;
        const {id, member: snapshotMemberDTO} = serviceCaseDTO;

        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASES,
            payload: {serviceCaseDTOs: [serviceCaseDTO]},
        });

        if (!nonAceMember) {
            yield* updateSCMemberResidenceGeolocation({
                serviceCaseId: id,
                caseType,
                snapshotMemberDTO,
            });
        }

        if (nonAceMember) {
            yield* updateServiceCase({
                caller: serviceCaseActionTypes.CREATE_SERVICE_CASE,
                caseType,
                serviceCaseId: id,
                serviceCaseData: {
                    nonAceMember: nonAceMember,
                    maximumBudget,
                    externalCaseId,
                    member: {
                        tariffDetails: member.tariffDetails,
                    },
                    ...(initialAttachments ? {attachments: initialAttachments} : {}),
                },
            });
        }

        switch (serviceCaseDTO.caseType) {
            case efServiceCaseTypes.VEHICLE: {
                yield put({
                    type: savActionTypes.CREATE_SERVICE_ASSIGNMENT_VEHICLE,
                    payload: {serviceCaseId: serviceCaseDTO.id, isNonACEMemberEmptyCase},
                });
                break;
            }

            case efServiceCaseTypes.PERSON: {
                yield put({
                    type: serviceAssignmentActionTypes.CREATE_PERSON_SERVICE_ASSIGNMENT,
                    payload: {serviceCaseId: serviceCaseDTO.id, assignmentType},
                });
                break;
            }

            case efServiceCaseTypes.HOUSEHOLD: {
                yield put({
                    type: saosActionTypes.CREATE_SERVICE_ASSIGNMENT_OTHER_SERVICES,
                    payload: {serviceCaseId: serviceCaseDTO.id},
                });
                break;
            }

            default: {
                // no-op
            }
        }
    }
};

export default createServiceCaseFlow;
