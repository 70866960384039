import {produce} from 'immer';
import {persistenceStates, Task} from '@ace-de/eua-entity-types';
import * as taskActionTypes from './taskActionTypes';

const initialState = {
    areSearchResultsValid: false,
    taskSearchResults: [],
    taskSearchResultsCount: 0,
    tasks: {},
    serviceCasesTasksCount: {},
    serviceCasesToDoTasksCount: {},
    searchPersistenceState: persistenceStates.READY,
};

/**
 * Tasks reducer
 *
 * @param state
 * @param action
 * @return {Object}
 */
const taskReducer = produce((draft, action) => {
    switch (action.type) {
        case taskActionTypes.SET_ARE_SEARCH_RESULTS_VALID: {
            const {areSearchResultsValid} = action.payload;
            draft.areSearchResultsValid = areSearchResultsValid;
            break;
        }

        case taskActionTypes.STORE_TASK_SEARCH_RESULTS: {
            const {taskSearchResults, taskSearchResultsCount} = action.payload;
            draft.taskSearchResults = taskSearchResults.map(taskDTO => {
                return new Task().fromDTO(taskDTO);
            });
            draft.taskSearchResultsCount = taskSearchResultsCount;
            break;
        }

        case taskActionTypes.STORE_TASKS: {
            const {taskDTOs} = action.payload;
            taskDTOs.forEach(taskDTO => {
                const taskId = taskDTO.id;
                const task = draft.tasks[taskId];
                if (!task) {
                    draft.tasks[taskId] = new Task().fromDTO(taskDTO);
                    return;
                }
                draft.tasks[taskId] = task.fromDTO(taskDTO);
            });
            break;
        }

        case taskActionTypes.STORE_SERVICE_CASE_TASK_COUNT: {
            const {serviceCaseId, taskCount} = action.payload;
            draft.serviceCasesTasksCount[serviceCaseId] = taskCount;
            break;
        }

        case taskActionTypes.STORE_SERVICE_CASE_TODO_TASK_COUNT: {
            const {serviceCaseId, toDoTaskCount} = action.payload;
            draft.serviceCasesToDoTasksCount[serviceCaseId] = toDoTaskCount;
            break;
        }

        case taskActionTypes.SET_TASK_SEARCH_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.searchPersistenceState = persistenceState;
            break;
        }

        default:
            // no-op
    }
    return draft;
}, initialState);

export default taskReducer;
