import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, Pill} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageTravelDataPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('saa_cost_coverage_tab');
    const {serviceAssignment} = props;
    const sumOfRooms = serviceAssignment.numberOfDoubleBedroom
        + serviceAssignment.numberOfFamilyBedroom
        + serviceAssignment.numberOfTwinBedroom;

    const formatAgeOfKids = () => {
        let outputString = serviceAssignment.numberOfKids
            ? `, ${serviceAssignment.numberOfKids} ${translateTab('data_row_label.children')}`
            : '';

        serviceAssignment.ageOfKids.forEach((age, idx) => {
            if (idx === 0) {
                outputString = outputString.concat(' (');
            }
            outputString = idx === serviceAssignment.ageOfKids.length - 1
                ? outputString.concat(`${age} ${translate('global.time_unit.year')})`)
                : outputString.concat(`${age} ${translate('global.time_unit.year')}, `);
        });
        return outputString;
    };

    return (
        <Panel
            title={translateTab('panel_title.travel_data')}
            qaIdent="saa-cost-coverage-travel-data"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.stay_start_date')}
                        qaIdent="sa-stay-start-date"
                    >
                        {serviceAssignment.stayStartDate
                            && moment(serviceAssignment.stayStartDate).format('DD.MM.YYYY')
                        }
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.stay_end_date')}
                        qaIdent="sa-stay-end-date"
                    >
                        <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                            <span className={cx('global!ace-u-margin--right-8')}>
                                {serviceAssignment.stayEndDate
                                    && moment(serviceAssignment.stayEndDate).format('DD.MM.YYYY')
                                }
                            </span>
                            {!!serviceAssignment.navStatusHistory?.extended && (
                                <Pill type="information">
                                    {translateTab('pill_text.extension')}
                                </Pill>
                            )}
                        </div>
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.room')}
                        qaIdent="sa-rooms"
                    >
                        {sumOfRooms > 0 ? sumOfRooms : ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.persons')}
                        qaIdent="sa-number-of-persons"
                    >
                        <p>
                            {serviceAssignment.numberOfAdults
                                ? `${serviceAssignment.numberOfAdults} ${translateTab('data_row_label.adults')}`
                                : ''}
                            {formatAgeOfKids()}
                            {serviceAssignment.numberOfPets
                                ? `, ${serviceAssignment.numberOfPets} ${translateTab('data_row_label.animals')}`
                                : ''}
                        </p>
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CostCoverageTravelDataPanel.propTypes = {
    serviceAssignment: PropTypes.object,
};

CostCoverageTravelDataPanel.defaultProps = {
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageTravelDataPanel));
