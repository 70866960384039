import React, {Fragment, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {snakeCase, camelCase} from 'change-case';
import {resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {etmTaskStatusTypes, etmTaskCategoryTypes, eupShiftRoleTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {Panel, useStyles, Pill, NoResultsBlock, Paginator, HighlightCircle, LoaderSpinner} from '@ace-de/ui-components';
import {Form, InputField, ButtonIcon, CheckboxGroup, Checkbox, SelectField, Option} from '@ace-de/ui-components/form';
import {AutocompleteField} from '@ace-de/ui-components/text-inputs';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, InteractiveIcon, resubmissionIcon, searchIcon, resetIcon} from '@ace-de/ui-components/icons';
import routePaths from '../routePaths';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as taskSelectors from './taskSelectors';
import * as taskActionTypes from './taskActionTypes';
import config from '../config';

const pillVariants = {
    [etmTaskStatusTypes.DUE]: 'pending',
    [etmTaskStatusTypes.OVERDUE]: 'negative',
    [etmTaskStatusTypes.DONE]: 'positive',
};

const initialTaskFilteringParams = {
    searchTerm: '',
    category: 'ALL',
    status: [],
    assignedToUser: '',
    assignedToRole: '',
};

const TaskSearchScreen = props => {
    const {cx} = useStyles();
    const resetRef = useRef(false);
    const submitRef = useRef(false);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('task_search_screen');
    const {history, users, searchPersistenceState} = props;
    const {taskSearchResults, taskSearchResultsCount, initiateTaskUpdateFlow} = props;
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const [filterFormData, setFilterFormData] = useState({
        ...initialTaskFilteringParams,
        status: queryParams.getAll('status').length ? queryParams.getAll('status') : [],
    });
    const assignedToQueryParams = queryParams.getAll('assignedTo');
    const paginatorCount = Math.ceil(taskSearchResultsCount / config.TASKS_DEFAULT_PAGE_SIZE);
    const parsedAssignedToQueryParams = assignedToQueryParams.length ? assignedToQueryParams[0].split(',') : '';
    let parsedAssignedToUser = '';
    let parsedAssignedToRole = '';
    if (parsedAssignedToQueryParams.length) {
        parsedAssignedToQueryParams.forEach(param => {
            Object.values(eupShiftRoleTypes).includes(param)
                ? parsedAssignedToRole = param
                : parsedAssignedToUser = param;
        });
    }
    const isSearchInProgress = searchPersistenceState === persistenceStates.PENDING;

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
        }
    }, [history.location.search, queryParams]);

    const formatQueryParams = formData => {
        if (!formData) return;
        submitRef.current = true;
        const apiQueryParams = new URLSearchParams();
        const assignedToValues = [];
        Object.keys(formData).forEach(formField => {
            if (formData[formField] !== undefined && formData[formField] !== '') {
                if (formField !== 'status' && formField !== 'assignedToUser' && formField !== 'assignedToRole') {
                    apiQueryParams.append(`${formField}`, formData[formField]);
                }

                if (formField === 'status' && Array.isArray(formData[formField]) && formData[formField].length > 0) {
                    formData[formField].forEach(taskStatus => {
                        if (taskStatus !== '') apiQueryParams.append('status', taskStatus);
                    });
                }

                if (formField === 'assignedToUser') {
                    assignedToValues.push(formData[formField]);
                }

                if (formField === 'assignedToRole') {
                    assignedToValues.push(formData[formField]);
                }
            }
        });

        if (assignedToValues.length > 0) apiQueryParams.append('assignedTo', assignedToValues);
        return apiQueryParams;
    };

    const handleOnSubmit = formValues => {
        if (!formValues) {
            return;
        }
        const apiQueryParams = formatQueryParams(formValues);
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.TASK_SEARCH, {}, {search: queryParamsString}));
    };

    const handleResetRef = () => {
        resetRef.current = false;
        return;
    };

    const handleStatusChange = status => {
        setFilterFormData({
            ...filterFormData,
            status,
        });
    };

    const onChange = formValues => {
        if (submitRef.current) return;
        if (resetRef.current) {
            handleResetRef();
            return;
        }
        setFilterFormData(formValues);
    };

    const handleOnFilterReset = () => {
        resetRef.current = true;
        submitRef.current = false;
        setFilterFormData(initialTaskFilteringParams);
        history.push(resolveRoute(routePaths.TASK_SEARCH, {}, {search: ''}));
    };

    const openEditTaskModal = task => {
        initiateTaskUpdateFlow({task});
    };

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.TASKS_DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.TASK_SEARCH, {}, {search: queryParamsString}));
    };

    return (
        <Fragment>
            <Form name="tasksSearchForm" onSubmit={handleOnSubmit} onChange={onChange}>
                {formValues => (
                    <Panel
                        title={translateScreen('task_search_panel.title')}
                        contentClassName={cx('global!ace-u-flex', 'global!ace-u-flex--direction-column')}
                    >
                        <div className={cx('global!ace-u-grid')}>

                            <InputField
                                label={translateScreen('search_input_label.free_text_search')}
                                name="searchTerm"
                                value={queryParams.get('searchTerm') || filterFormData.searchTerm}
                                className={cx([
                                    'global!ace-u-grid-column--x-large-span-5',
                                    'global!ace-u-grid-column--large-span-3',
                                ])}
                                onKeyDown={() => handleOnSubmit(formValues)}
                            />
                            <AutocompleteField
                                className={cx([
                                    'global!ace-u-grid-column--x-large-span-2',
                                    'global!ace-u-grid-column--large-span-4',
                                ])}
                                name="assignedToRole"
                                label={translateScreen('select_input_label.assigned_to_role')}
                                placeholder={translate('global.select.placeholder')}
                                value={parsedAssignedToRole || filterFormData.assignedToRole}
                                isArrowIconDisplayed={true}
                            >
                                {Object.values(eupShiftRoleTypes)
                                    .filter(role => role !== eupShiftRoleTypes.VIEWER)
                                    .map((role, index) => {
                                        return (
                                            <Option
                                                key={`${role}-${index}`}
                                                name={`${role}-${index}`}
                                                value={role}
                                            >
                                                {translate(`global.user_shift_role.${role.toLowerCase()}`)}
                                            </Option>
                                        );
                                    })}
                            </AutocompleteField>
                            <AutocompleteField
                                className={cx([
                                    'global!ace-u-grid-column--x-large-span-2',
                                    'global!ace-u-grid-column--large-span-4',
                                ])}
                                name="assignedToUser"
                                label={translateScreen('select_input_label.assigned_to_user')}
                                placeholder={translate('global.select.placeholder')}
                                value={parsedAssignedToUser || filterFormData.assignedToUser}
                                isArrowIconDisplayed={true}
                            >
                                {
                                    Object.values(users).map((user, idx) => {
                                        return (
                                            <Option
                                                name={`assignedToOption${idx}`}
                                                key={user.id}
                                                value={user.id}
                                            >
                                                {`${user.firstName} ${user.lastName}`}
                                            </Option>
                                        );
                                    })
                                }
                            </AutocompleteField>
                            <SelectField
                                className={cx('global!ace-u-grid-column--x-large-span-2 global!ace-u-grid-column--large-span-4')}
                                name="category"
                                label={translateScreen('select_input_label.category')}
                                value={queryParams.get('category') || filterFormData.category}
                                placeholder={translate('global.select.placeholder')}
                            >
                                <Option
                                    name="categoryOptionAll"
                                    value="ALL"
                                >
                                    {translateScreen('select_input_option.all')}
                                </Option>
                                {Object.keys(etmTaskCategoryTypes).map((taskCategory, idx) => {
                                    return (
                                        <Option
                                            key={`category${idx}`}
                                            name={`categoryOption${camelCase(taskCategory)}`}
                                            value={taskCategory}
                                        >
                                            {translate(`global.task_category.${snakeCase(taskCategory)}`)}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-flex-end',
                                    'global!ace-u-margin--top-24',
                                ])}
                            >
                                <ButtonIcon
                                    name="searchButton"
                                    icon={searchIcon}
                                    type="submit"
                                    onClick={handleOnSubmit}
                                />
                            </div>
                        </div>
                        <div className={cx('global!ace-u-grid')}>
                            <div
                                className={cx([
                                    'global!ace-u-grid-column--span-11',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-space-between',
                                    'global!ace-u-margin--top-32',
                                ])}
                            >
                                <div>
                                    <span className={cx('global!ace-u-typography--variant-body')}>
                                        {`${translateScreen('search_panel_label.search_results_count')}:`}
                                    </span>&nbsp;
                                    <span className={cx('global!ace-u-typography--variant-body-medium')}>
                                        {taskSearchResultsCount || 0}
                                    </span>
                                </div>
                                <div>
                                    <InteractiveIcon
                                        icon={resetIcon}
                                        className={cx('ace-c-interactive-icon--reverse', 'global!ace-u-margin--right-64')}
                                        onClick={handleOnFilterReset}
                                    >
                                        {translateScreen('interactive_icon_label.reset_filter')}
                                    </InteractiveIcon>
                                    <CheckboxGroup
                                        name="status"
                                        value={filterFormData.status}
                                        onChange={handleStatusChange}
                                    >
                                        {Object.keys(etmTaskStatusTypes).map((statusType, idx) => (
                                            <Checkbox
                                                className={cx({
                                                    'global!ace-u-margin--right-48': idx !== Object.keys(etmTaskStatusTypes).length - 1,
                                                })}
                                                key={statusType}
                                                name={statusType}
                                                value={statusType}
                                            >
                                                {translate(`global.task_status.${snakeCase(statusType)}`)}
                                            </Checkbox>
                                        ))}
                                    </CheckboxGroup>
                                </div>
                            </div>
                        </div>
                    </Panel>
                )}
            </Form>
            <Panel className={cx(['ace-c-panel--full-bleed-content', 'global!ace-u-padding--top-0'])}>
                <Table qaIdent="task-search-results">
                    {taskSearchResults.length === 0 && !isSearchInProgress && (
                        <TableCaption>
                            <NoResultsBlock
                                message={translateScreen('no_results.message')}
                                description={translateScreen('no_results.description')}
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl')}
                                        icon={resubmissionIcon}
                                    />
                                )}
                            />
                        </TableCaption>
                    )}
                    {isSearchInProgress && (
                        <TableCaption>
                            <HighlightCircle
                                className={cx([
                                    'ace-c-highlight-circle--medium',
                                    'ace-c-highlight-circle--primary-highlight',
                                    'global!ace-u-margin--top-64',
                                    'global!ace-u-margin--bottom-64',
                                ])}
                            >
                                <LoaderSpinner />
                            </HighlightCircle>
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell qaIdentPart="task-due-date">
                                {translateScreen('table_header.due_date')}
                            </TableCell>
                            <TableCell qaIdentPart="task-status">
                                {translateScreen('table_header.status')}
                            </TableCell>
                            <TableCell qaIdentPart="task-category">
                                {translateScreen('table_header.category')}
                            </TableCell>
                            <TableCell qaIdentPart="task-description">
                                {translateScreen('table_header.description')}
                            </TableCell>
                            <TableCell qaIdentPart="task-member-name">
                                {translateScreen('table_header.member')}
                            </TableCell>
                            <TableCell qaIdentPart="task-assigned-to-role">
                                {translateScreen('table_header.assigned_to')}
                            </TableCell>
                            <TableCell qaIdentPart="task-assigned-service-case-id">
                                {translateScreen('table_header.case_id')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {taskSearchResults.length > 0 && taskSearchResults.map(task => (
                            <TableRow
                                key={task.id}
                                onClick={() => openEditTaskModal(task)}
                                qaIdentPart={task.id}
                            >
                                <TableCell
                                    qaIdentPart="task-due-date"
                                    qaIdentPartPostfix={task.id}
                                >
                                    {task.dueDate && moment(task.dueDate).isValid()
                                        ? moment(task.dueDate).format('DD.MM.YYYY[,] HH:mm') : '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="task-status"
                                    qaIdentPartPostfix={task.id}
                                >
                                    <Pill type={pillVariants[task.status]}>
                                        {translate(`global.task_status.${task.status.toLowerCase()}`)}
                                    </Pill>
                                </TableCell>
                                <TableCell
                                    qaIdentPart="task-category"
                                    qaIdentPartPostfix={task.id}
                                >
                                    {translate(`global.task_category.${task.category.toLowerCase()}`)}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="task-description"
                                    qaIdentPartPostfix={task.id}
                                >
                                    {task.description}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="task-member-name"
                                    qaIdentPartPostfix={task.id}
                                >
                                    {task.memberName}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="task-assigned-to-role"
                                    qaIdentPartPostfix={task.id}
                                >
                                    {task.assignedToRole
                                        ? translate(`global.user_shift_role.${task.assignedTo.toLowerCase()}`)
                                        : `${users[task.assignedTo]?.firstName || ''} ${users[task.assignedTo]?.lastName || ''}`
                                    }
                                </TableCell>
                                <TableCell
                                    qaIdentPart="task-assigned-service-case-id"
                                    qaIdentPartPostfix={task.id}
                                >
                                    {task.assignedCaseId}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Panel>
            {taskSearchResults.length > 0 && !isSearchInProgress && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={handlePaginationPage}
                />
            )}
        </Fragment>
    );
};

TaskSearchScreen.propTypes = {
    history: PropTypes.object,
    users: PropTypes.object,
    taskSearchResults: PropTypes.array,
    taskSearchResultsCount: PropTypes.number,
    initiateTaskUpdateFlow: PropTypes.func.isRequired,
    searchPersistenceState: PropTypes.string.isRequired,
};

TaskSearchScreen.defaultProps = {
    history: {},
    users: {},
    taskSearchResults: [],
    taskSearchResultsCount: 0,
};

const mapStateToProps = (state, props) => {
    return {
        users: userProfileSelectors.getUsers(state),
        taskSearchResults: taskSelectors.getTaskSearchResults(state, props),
        taskSearchResultsCount: state.tasks.taskSearchResultsCount,
        searchPersistenceState: state.tasks.searchPersistenceState,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateTaskUpdateFlow: payload => dispatch({
        type: taskActionTypes.INITIATE_TASK_UPDATE_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskSearchScreen);

