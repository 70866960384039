import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, Checkbox, NumberInput, Pill} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';
import getPrice from '../../utils/getPrice';

const CostCoverageCostOverviewPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sast_cost_coverage_tab');
    const {totalCost, costConfirmedByMember, onChangeHandler, serviceAssignment} = props;

    const isFullyCovered = totalCost - serviceAssignment.totalCoverage <= 0;

    return (
        <Panel
            title={translateTab('panel_title.cost_overview')}
            qaIdent="sast-cost-coverage-cost-overview"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.total_costs')}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="sa-total-cost"
                    >
                        <NumberInput
                            className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                            placeholder={translateTab('input_placeholder.please_enter_here')}
                            value={totalCost}
                            onChange={value => onChangeHandler('totalCost', value)}
                            min={0}
                        />
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.maximum_budget')}
                        qaIdent="sa-maximum-budget"
                    >
                        {getPrice(serviceAssignment.totalCoverage, activeLocale)}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.difference')}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="sa-cost-difference"
                    >
                        {!!(totalCost && parseFloat(totalCost)) && (
                            // show pill only when agent enters totalCost
                            <Pill
                                type={isFullyCovered ? 'positive' : 'negative'}
                                className={cx('global!ace-u-typography--variant-body-medium')}
                            >
                                {isFullyCovered
                                    ? translateTab('difference_label.is_fully_covered')
                                    : `${getPrice(totalCost - serviceAssignment.totalCoverage, activeLocale)} ${translateTab('difference_label.add_payment')}`}
                            </Pill>
                        )}
                    </DataRow>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    {!isFullyCovered && (
                        <Checkbox
                            name="costConfirmedByMember"
                            value={true}
                            isSelected={!!costConfirmedByMember}
                            onChange={value => onChangeHandler('costConfirmedByMember', !!value)}
                            className={cx('global!ace-u-margin--bottom-16')}
                        >
                            {translateTab('checkbox_label.confirmed_by_member')}
                        </Checkbox>
                    )}
                </div>
            </div>
        </Panel>
    );
};

CostCoverageCostOverviewPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    totalCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // prop named differently to match DTO name
    // eslint-disable-next-line react/boolean-prop-naming
    costConfirmedByMember: PropTypes.bool,
    onChangeHandler: PropTypes.func,
};

CostCoverageCostOverviewPanel.defaultProps = {
    serviceAssignment: {},
    totalCost: '',
    costConfirmedByMember: false,
    onChangeHandler: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageCostOverviewPanel));
