import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {Link, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {etmTaskCategoryTypes, Task, etmTaskStatusTypes, eupShiftRoleTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, Divider, Option} from '@ace-de/ui-components';
import {Button, ButtonPrimary} from '@ace-de/ui-components/buttons';
import {DateField, TimeField, AutocompleteField} from '@ace-de/ui-components/text-inputs';
import {Form, TextAreaField, SelectField, Checkbox} from '@ace-de/ui-components/form';
import {closeIcon, Icon, linkIcon, InteractiveIcon, calendarIcon} from '@ace-de/ui-components/icons';
import routePaths from '../../routePaths';
import config from '../../config';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as taskActionTypes from '../taskActionTypes';
import * as taskSelectors from '../taskSelectors';

const eupAssignableShiftRoleTypes = [
    eupShiftRoleTypes.DISPATCHER_1,
    eupShiftRoleTypes.DISPATCHER_2,
    eupShiftRoleTypes.DISPATCHER_3,
    eupShiftRoleTypes.DISPO,
    eupShiftRoleTypes.TEAM_LEAD,
    eupShiftRoleTypes.INBOX,
];

const taskAssigneeTypes = {
    USER: 'USER',
    ROLE: 'ROLE',
};

const initialErrors = {
    assignedTo: '',
};

const TaskDataModal = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateModal = createTranslateShorthand('task_data_modal');
    const {hasBackdrop, serviceCase, users, task, location} = props;
    const {declineTaskCreation, confirmTaskCreation} = props;
    const {declineTaskUpdate, confirmTaskUpdate} = props;
    const taskType = location?.query?.type;
    const [taskData] = useState(task?.id
        ? {
            ...task,
            assigneeType: task.assignedToRole ? taskAssigneeTypes.ROLE : (task.assignedTo ? taskAssigneeTypes.USER : ''),
        } : taskType === 'POWER_OF_ATTORNEY'
            ? {
                category: etmTaskCategoryTypes.CONTACT_MEMBER,
                assigneeType: taskAssigneeTypes.ROLE,
                assignedTo: eupShiftRoleTypes.DISPO,
                dueDate: moment().add(1, 'days'),
                dueTime: '18:00',
                description: translateModal('text.power_of_attorney_description'),
            }
            : {
                ...new Task(),
            });
    const [errors, setErrors] = useState({...initialErrors});

    const validateFormValues = formValues => {
        let isValid = true;
        // validate 'assignedTo' field
        if (formValues.assignedTo) {
            if (formValues.assigneeType === taskAssigneeTypes.ROLE) {
                const translatedEUPAssignableShiftRoleTypesMapping = eupAssignableShiftRoleTypes
                    .reduce((translationToRoleTypeMapping, shiftRole) => {
                        translationToRoleTypeMapping[translate(`global.user_shift_role.${shiftRole.toLowerCase()}`)] = shiftRole;
                        return translationToRoleTypeMapping;
                    }, {});
                if (Object.keys(translatedEUPAssignableShiftRoleTypesMapping).includes(formValues.assignedTo)) {
                    // NOTE: if the agent enters 'assignedTo' role manually, and does NOT select the option
                    // we should change the entered value to the proper one
                    formValues.assignedTo = translatedEUPAssignableShiftRoleTypesMapping[formValues.assignedTo];
                }
                if (!eupAssignableShiftRoleTypes.includes(formValues.assignedTo)) {
                    setErrors(prevState => ({
                        ...prevState,
                        assignedTo: translateModal('error_message.invalid_assigned_to'),
                    }));
                    isValid = false;
                }
            }
            if (formValues.assigneeType === taskAssigneeTypes.USER && !users[formValues.assignedTo]) {
                setErrors(prevState => ({
                    ...prevState,
                    assignedTo: translateModal('error_message.invalid_assigned_to'),
                }));
                isValid = false;
            }
        }

        // if form values are valid, remove all previous errors from the errors object
        if (isValid) {
            setErrors({...initialErrors});
        }
        return isValid;
    };

    const handleOnSubmit = (formValues, markAsDone) => {
        const isValid = validateFormValues(formValues);
        if (!isValid) return;
        const {category, assignedTo, description, dueDate, dueTime, assigneeType, immediatelyDue} = formValues;

        const taskData = {
            assignedTo,
            category,
            description,
            dueDate: immediatelyDue
                ? moment().add(5, 'minutes')
                : moment(`${moment(dueDate).format('YYYY-MM-DD')}T${dueTime}`),
            assignedCaseId: task?.id ? task.assignedCaseId : serviceCase.id,
            memberName: task?.id ? task.memberName : serviceCase.member.personalDetails.displayName,
            assignedToRole: assigneeType === taskAssigneeTypes.ROLE,
            assignedToUser: assigneeType === taskAssigneeTypes.USER && assignedTo && users[assignedTo]
                ? [users[assignedTo].firstName, users[assignedTo].lastName].filter(value => !!value).join(' ')
                : null,
        };
        if (markAsDone) {
            taskData.status = etmTaskStatusTypes.DONE;
        }
        if (task?.id) {
            confirmTaskUpdate({
                taskData,
                taskId: task.id,
            });
            return;
        }
        confirmTaskCreation({
            taskData,
        });
    };

    const handleCloseModal = () => {
        if (task?.id) {
            declineTaskUpdate();
            return;
        }

        declineTaskCreation();
    };

    return (
        <Modal
            title={translateModal('header.title')}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={handleCloseModal}
                />
            )}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--top-24',
                        'global!ace-u-margin--bottom-16',
                    ])}
                >
                    <span>
                        {translateModal('header.case_number')}
                    </span>
                    <Link
                        to={resolveRoute(routePaths.SERVICE_CASE_SECTION, {
                            serviceCaseId: task?.id ? task.assignedCaseId : serviceCase.id,
                        })}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={cx([
                            'global!ace-u-typography--variant-highlight',
                            'global!ace-u-typography--variant-highlight',
                            'global!ace-u-typography--color-highlighted',
                        ])}
                    >
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-margin--left-8',
                                'global!ace-u-margin--right-64',
                            ])}
                        >
                            <span>{task?.id ? task.assignedCaseId : serviceCase.id}</span>
                            <Icon
                                icon={linkIcon}
                                className={cx([
                                    'ace-c-icon--color-highlight',
                                    'global!ace-u-margin--left-8',
                                ])}
                            />
                        </div>
                    </Link>
                    <span>
                        {translateModal('header.member')}
                    </span>
                    <span className={cx('global!ace-u-margin--left-8')}>
                        {task?.id ? task.memberName : serviceCase.member.personalDetails.displayName}
                    </span>
                </div>
                <Divider />
                <Form
                    name="createTask"
                    onSubmit={handleOnSubmit}
                >
                    {formValues => {
                        let assignedToOptions = [];
                        if (formValues.assigneeType === taskAssigneeTypes.ROLE) {
                            assignedToOptions = eupAssignableShiftRoleTypes;
                        }
                        if (formValues.assigneeType === taskAssigneeTypes.USER) {
                            assignedToOptions = Object.keys(users);
                        }

                        let dueTimeValue = taskData.dueTime ? taskData.dueTime : '';
                        if (task?.id) {
                            dueTimeValue = taskData.dueTime !== undefined ? taskData.dueTime : moment(taskData.dueDate).format('HH:mm');
                        }
                        if (formValues['category'] === etmTaskCategoryTypes.TODO) {
                            dueTimeValue = '';
                        }

                        const assignedTo = formValues.assigneeType
                            ? (formValues.assigneeType === taskAssigneeTypes.USER
                                && eupAssignableShiftRoleTypes.includes(formValues.assignedTo)
                                ? ''
                                : formValues.assigneeType === taskAssigneeTypes.ROLE && users[formValues.assignedTo]
                                    ? '' : formValues.assignedTo
                            ) : (taskData.assigneeType && taskData.assignedTo
                                ? taskData.assignedTo : '');
                        const isTaskDone = !!(task?.id && task?.status && task.status === etmTaskStatusTypes.DONE);
                        const isTaskCreationEnabled = formValues.category
                            && formValues.assigneeType && assignedTo
                            && (formValues.immediatelyDue ? true : formValues.dueDate && formValues.dueTime);

                        return (
                            <Fragment>
                                <div
                                    className={cx([
                                        'global!ace-u-grid',
                                        'global!ace-u-margin--top-32',
                                    ])}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                            'global!ace-u-margin--right-24',
                                            'global!ace-u-grid-column--span-6',
                                        ])}
                                    >
                                        <SelectField
                                            name="category"
                                            value={taskData.category}
                                            label={translateModal('input_label.category')}
                                            placeholder={translate('global.select.placeholder')}
                                            className={cx('global!ace-u-margin--bottom-48')}
                                            isDisabled={isTaskDone}
                                        >
                                            {Object.keys(etmTaskCategoryTypes).map(categoryType => (
                                                <Option
                                                    key={categoryType}
                                                    name={categoryType}
                                                    value={categoryType}
                                                >
                                                    {translate(`global.task_category.${categoryType.toLowerCase()}`)}
                                                </Option>
                                            ))}
                                        </SelectField>
                                        <div
                                            className={cx([
                                                'global!ace-u-margin--bottom-48',
                                                'global!ace-u-grid',
                                            ])}
                                        >
                                            <SelectField
                                                name="assigneeType"
                                                value={(task?.id || taskType === 'POWER_OF_ATTORNEY')
                                                    && formValues['category'] !== etmTaskCategoryTypes.TODO
                                                    ? taskData.assigneeType
                                                    : ''}
                                                label={translateModal('input_label.assignee_type')}
                                                placeholder={translate('global.select.placeholder')}
                                                className={cx('global!ace-u-grid-column--span-6', {
                                                    'global!ace-u-margin--bottom-24': !!(errors && errors['assignedTo']),
                                                })}
                                                isDisabled={isTaskDone
                                                    || formValues['category'] === etmTaskCategoryTypes.TODO}
                                            >
                                                <Option
                                                    key="ROLE"
                                                    name="assigneeTypeOptionRole"
                                                    value="ROLE"
                                                >
                                                    {translateModal('assignee_type_input_option.role')}
                                                </Option>
                                                <Option
                                                    key="USER"
                                                    name="assigneeTypeOptionUser"
                                                    value="USER"
                                                >
                                                    {translateModal('assignee_type_input_option.user')}
                                                </Option>
                                            </SelectField>
                                            <AutocompleteField
                                                name="assignedTo"
                                                value={formValues['category'] !== etmTaskCategoryTypes.TODO
                                                    ? assignedTo : ''}
                                                placeholder={translate('global.select.placeholder')}
                                                className={cx('global!ace-u-grid-column--span-6', 'global!ace-u-flex--align-self-flex-end')}
                                                isArrowIconDisplayed={true}
                                                isDisabled={isTaskDone
                                                    || formValues['category'] === etmTaskCategoryTypes.TODO}
                                                errors={errors['assignedTo'] ? [errors['assignedTo']] : []}
                                            >
                                                {assignedToOptions.map((option, index) => (
                                                    <Option
                                                        key={`${option}-${index}`}
                                                        name={`${option}-${index}`}
                                                        value={option}
                                                    >
                                                        {formValues.assigneeType === taskAssigneeTypes.ROLE
                                                            ? translate(`global.user_shift_role.${option.toLowerCase()}`)
                                                            : `${users[option].firstName || ''} ${users[option].lastName || ''}`}
                                                    </Option>
                                                ))}
                                            </AutocompleteField>
                                        </div>
                                        <div
                                            className={cx([
                                                'global!ace-u-grid',
                                            ])}
                                        >
                                            <DateField
                                                name="dueDate"
                                                label={translateModal('input_label.due_date')}
                                                value={taskData.dueDate
                                                    && formValues['category'] !== etmTaskCategoryTypes.TODO
                                                    ? taskData.dueDate : ''}
                                                className={cx('global!ace-u-grid-column--span-6')}
                                                isDisabled={isTaskDone || formValues.immediatelyDue
                                                    || formValues['category'] === etmTaskCategoryTypes.TODO}
                                                icon={calendarIcon}
                                                locale={activeLocale}
                                            />
                                            <TimeField
                                                name="dueTime"
                                                label={translateModal('input_label.due_time')}
                                                value={dueTimeValue}
                                                className={cx('global!ace-u-grid-column--span-6')}
                                                isDisabled={isTaskDone || formValues.immediatelyDue
                                                    || formValues['category'] === etmTaskCategoryTypes.TODO}
                                            />
                                        </div>
                                        <Checkbox
                                            name="immediatelyDue"
                                            className={cx('global!ace-u-margin--top-24')}
                                            value={true}
                                            isDisabled={isTaskDone
                                                || formValues['category'] === etmTaskCategoryTypes.TODO}
                                        >
                                            {translateModal('input_label.immediately_due')}
                                        </Checkbox>
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                            'global!ace-u-flex--justify-space-between',
                                            'global!ace-u-grid-column--span-6',
                                        ])}
                                    >
                                        <TextAreaField
                                            name="description"
                                            label={translateModal('input_label.description')}
                                            placeholder={translateModal('input_placeholder.description')}
                                            maxLength={config.TASK_DESCRIPTION_MAX_LENGTH}
                                            isResizable={false}
                                            value={taskData.description}
                                            isDisabled={isTaskDone}
                                            contentClassName={cx('ace-c-text-area--medium')}
                                        />
                                    </div>
                                </div>
                                <div className={cx('global!ace-u-grid', 'global!ace-u-margin--top-8')}>
                                    {task?.id ? (
                                        taskData.status && taskData.status === etmTaskStatusTypes.DONE ? (
                                            <div className={cx('global!ace-u-grid-column--12-span-1')}>
                                                <ButtonPrimary
                                                    name="closeTaskModal"
                                                    type="button"
                                                    onClick={declineTaskUpdate}
                                                >
                                                    {translateModal('button_label.close')}
                                                </ButtonPrimary>
                                            </div>
                                        ) : (
                                            <div className={cx('global!ace-u-grid', 'global!ace-u-grid-column--span-12')}>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-grid-column--5-9',
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--justify-center',
                                                    ])}
                                                >
                                                    <Button
                                                        name="markAsDoneButton"
                                                        onClick={() => handleOnSubmit(formValues, true)}
                                                    >
                                                        {translateModal('button_label.done')}
                                                    </Button>
                                                </div>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-grid-column--10-span-3',
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--justify-flex-end',
                                                    ])}
                                                >
                                                    <ButtonPrimary
                                                        name="submitTaskUpdate"
                                                        type="submit"
                                                    >
                                                        {translateModal('button_label.save')}
                                                    </ButtonPrimary>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div className={cx('global!ace-u-grid-column--12-span-1')}>
                                            <ButtonPrimary
                                                name="submitTask"
                                                type="submit"
                                                isDisabled={formValues['category'] === etmTaskCategoryTypes.TODO
                                                    ? false
                                                    : !isTaskCreationEnabled}
                                            >
                                                {translateModal('button_label.save')}
                                            </ButtonPrimary>
                                        </div>
                                    )}
                                </div>
                            </Fragment>
                        );
                    }}
                </Form>
            </div>
        </Modal>
    );
};

TaskDataModal.propTypes = {
    serviceCase: PropTypes.object,
    declineTaskCreation: PropTypes.func.isRequired,
    confirmTaskCreation: PropTypes.func.isRequired,
    declineTaskUpdate: PropTypes.func.isRequired,
    confirmTaskUpdate: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    users: PropTypes.object,
    task: PropTypes.object,
    location: PropTypes.object,
    // history: PropTypes.object,
};

TaskDataModal.defaultProps = {
    serviceCase: null,
    hasBackdrop: true,
    users: {},
    task: {},
    location: {},
    // history: {},
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const taskSelector = taskSelectors.createTaskSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        users: userProfileSelectors.getUsers(state),
        task: taskSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineTaskCreation: payload => dispatch({
        type: taskActionTypes.DECLINE_TASK_CREATION,
        payload,
    }),
    confirmTaskCreation: payload => dispatch({
        type: taskActionTypes.CONFIRM_TASK_CREATION,
        payload,
    }),
    declineTaskUpdate: payload => dispatch({
        type: taskActionTypes.DECLINE_TASK_UPDATE,
        payload,
    }),
    confirmTaskUpdate: payload => dispatch({
        type: taskActionTypes.CONFIRM_TASK_UPDATE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskDataModal);
