import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampVehicleFuelTypes, ampVehicleTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, NoResultsBlock, ToggleSwitch, Badge} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {InteractiveIcon, Icon, vehicleIcon, deleteIcon, editIcon} from '@ace-de/ui-components/icons';
import * as memberActionTypes from '../memberActionTypes';
import * as memberSelectors from '../memberSelectors';
import config from '../../config';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatureActions, ecsFeatures} from '../../application/ecsFeatures';

const MemberVehiclesPrivatePanel = props => {
    const {cx} = useStyles();
    const {memberVehicles, initiateVehicleDeleteFlow, initiateEditMemberVehicleFlow} = props;
    const {initiateUpdateMemberVehicleActivityFlow, match} = props;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('member_vehicles_private_panel');
    const isVehicleEditingFeatureAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.MEMBER_VEHICLE,
        ecsFeatureActions.UPDATE,
    );
    const isVehicleDeleteFeatureAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.MEMBER_VEHICLE,
        ecsFeatureActions.DELETE,
    );

    const handleVehicleActivity = (vehicleId, isActive) => {
        const vehicleData = {
            isActive: !!isActive,
        };

        initiateUpdateMemberVehicleActivityFlow({
            vehicleId,
            membershipNo: match.params?.membershipNo,
            vehicleData,
        });
    };

    return (
        <Panel
            title={translateScreen('vehicle_panel.title')}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table qaIdent="member-private-vehicles">
                {memberVehicles.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl')}
                                    icon={vehicleIcon}
                                />
                            )}
                            message={translateScreen('vehicle_no_results.message')}
                            description={translateScreen('vehicle_no_results.description')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="vehicle-manufacturer">
                            {translateScreen('vehicle_table_cell.manufacturer')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-model">
                            {translateScreen('vehicle_table_cell.model')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-licence-plate-number">
                            {translateScreen('vehicle_table_cell.license_plate_number')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-build-year">
                            {translateScreen('vehicle_table_cell.build_year')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-color">
                            {translateScreen('vehicle_table_cell.color')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-fuel-type">
                            {translateScreen('vehicle_table_cell.fuel_type')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-weight">
                            {translateScreen('vehicle_table_cell.weight_in_tons')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-active">
                            {translateScreen('vehicle_table_cell.active')}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {memberVehicles.length > 0 && memberVehicles.map(vehicle => (
                        <TableRow
                            key={vehicle.id}
                            qaIdentPart={vehicle.id}
                            className={cx('', {
                                'global!ace-u-typography--color-disabled-main': !vehicle.isActive,
                                'global!ace-u-typography--text-decoration-line-through': !vehicle.isActive,
                            })}
                        >
                            <TableCell
                                qaIdentPart="vehicle-manufacturer"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {vehicle.manufacturer}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-model"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {`${vehicle.model} ${vehicle.variant}`}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-licence-plate-number"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {vehicle.licensePlateNumber}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-build-year"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {vehicle.buildYear}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-color"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {vehicle.color
                                    ? translate(`global.vehicle_color.${(vehicle.color).toLowerCase()}`)
                                    : ''}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-fuel-type"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {vehicle.fuelType !== ampVehicleFuelTypes.UNKNOWN
                                    ? translate(`global.vehicle_fuel_type.${snakeCase(ampVehicleFuelTypes[vehicle.fuelType])}`)
                                    : ''}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-weight"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {vehicle.weight}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-active"
                                qaIdentPartPostfix={vehicle.id}
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-padding--16-4',
                                ])}
                            >
                                {
                                    !config.IS_FALLBACK_SYSTEM
                                    && isVehicleEditingFeatureAvailable
                                    && vehicle.type !== ampVehicleTypes.CAMPER && (
                                        <ToggleSwitch
                                            name={`vehicleActivity-${vehicle.id}`}
                                            value={true}
                                            onChange={value => handleVehicleActivity(vehicle.id, !!value)}
                                            isSelected={!!vehicle.isActive}
                                        />
                                    )}
                                {vehicle.isVehicleBCNav && (
                                    <Badge>
                                        {translate('global.vehicle_source.bc_nav')}
                                    </Badge>
                                )}
                            </TableCell>
                            <TableCell
                                qaIdentPart="vehicle-option"
                                qaIdentPartPostfix={vehicle.id}
                            >
                                {!config.IS_FALLBACK_SYSTEM && isVehicleEditingFeatureAvailable && (
                                    <InteractiveIcon
                                        icon={editIcon}
                                        onClick={() => initiateEditMemberVehicleFlow({vehicleId: vehicle.id})}
                                        className={cx('global!ace-u-margin--right-16')}
                                    />
                                )}
                                {
                                    !config.IS_FALLBACK_SYSTEM
                                    && isVehicleDeleteFeatureAvailable
                                    && vehicle.type !== ampVehicleTypes.CAMPER && (
                                        <InteractiveIcon
                                            icon={deleteIcon}
                                            onClick={() => initiateVehicleDeleteFlow({vehicleId: vehicle.id})}
                                        />
                                    )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Panel>
    );
};

MemberVehiclesPrivatePanel.propTypes = {
    memberVehicles: PropTypes.array,
    initiateVehicleDeleteFlow: PropTypes.func.isRequired,
    initiateEditMemberVehicleFlow: PropTypes.func.isRequired,
    initiateUpdateMemberVehicleActivityFlow: PropTypes.func.isRequired,
    match: PropTypes.object,
};

MemberVehiclesPrivatePanel.defaultProps = {
    memberVehicles: [],
    match: {},
};

const mapStateToProps = (state, props) => {
    const memberVehiclesSelector = memberSelectors.createMemberVehiclesSelector();
    return {
        memberVehicles: memberVehiclesSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateVehicleDeleteFlow: payload => dispatch({
        type: memberActionTypes.INITIATE_VEHICLE_DELETE_FLOW,
        payload,
    }),
    initiateEditMemberVehicleFlow: payload => dispatch({
        type: memberActionTypes.INITIATE_EDIT_MEMBER_VEHICLE_FLOW,
        payload,
    }),
    initiateUpdateMemberVehicleActivityFlow: payload => dispatch({
        type: memberActionTypes.INITIATE_UPDATE_MEMBER_VEHICLE_ACTIVITY_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberVehiclesPrivatePanel));
