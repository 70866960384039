/**
 * Command action types
 */
export const START_AUTO_REFRESH_TIMER = '[dashboard] START_AUTO_REFRESH_TIMER';

/**
 * Event action types
 */

/**
 * Store action types
 */
export const STORE_CURRENTLY_ACTIVE_TEAM = '[dashboard] STORE_CURRENTLY_ACTIVE_TEAM';
export const STORE_REPORTER_CASES_TASKS_COUNT = '[dashboard] STORE_REPORTER_CASES_TASKS_COUNT';
export const STORE_CURRENT_USERS_TASKS = '[dashboard] STORE_CURRENT_USERS_TASKS';
export const STORE_MEMBER_APP_SERVICE_CASES = '[dashboard] STORE_MEMBER_APP_SERVICE_CASES';
export const STORE_OPEN_SERVICE_ASSIGNMENTS = '[dashboard] STORE_OPEN_SERVICE_ASSIGNMENTS';
export const STORE_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT = '[dashboard] STORE_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT';
