import {take, put, select, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {Task} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as taskActionTypes from '../taskActionTypes';

const createTaskFlow = function* createTaskFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');

    while (true) {
        yield take(taskActionTypes.INITIATE_TASK_CREATION_FLOW);

        yield* openModal(modalIds.TASK_DATA);
        const chosenModalOption = yield take([
            taskActionTypes.CONFIRM_TASK_CREATION,
            taskActionTypes.DECLINE_TASK_CREATION,
        ]);

        if (chosenModalOption && chosenModalOption.type === taskActionTypes.CONFIRM_TASK_CREATION) {
            const {taskData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                taskActionTypes.CREATE_TASK_REQUEST,
                ecsTaskManagementService.createTask,
                {
                    taskDTO: Task.objectToDTO(taskData),
                },
            );

            const responseAction = yield take([
                taskActionTypes.CREATE_TASK_REQUEST_SUCCEEDED,
                taskActionTypes.CREATE_TASK_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {taskDTO} = response;
                const taskDTOs = [taskDTO];

                yield put({
                    type: taskActionTypes.STORE_TASKS,
                    payload: {taskDTOs},
                });
            }
        }

        yield* closeModal(modalIds.TASK_DATA);
    }
};

export default createTaskFlow;
