import {put, select, take, fork} from 'redux-saga/effects';
import fetchRequest from '../application/sagas/fetchRequest';
import config from '../config';
import * as serviceCaseActionTypes from '../service-cases/serviceCaseActionTypes';
import * as memberActionTypes from './memberActionTypes';

export {default as loadMemberVehicleInfo} from './sagas/loadMemberVehicleInfo';

export const loadMemberSearchResults = function* loadMemberSearchResults({payload}) {
    const {location} = payload;
    const searchQueryParams = new URLSearchParams(location.search);
    const searchQuery = searchQueryParams.get('searchQuery');

    if (searchQuery && searchQuery.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
        yield put({
            type: memberActionTypes.SEARCH_MEMBERS,
            payload: {searchQueryParams},
        });
    }
};

export const loadMemberDetails = function* loadMemberDetails({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    const {match} = payload;
    const {membershipNo} = match.params;
    yield fork(fetchRequest, memberActionTypes.FETCH_MEMBER_REQUEST, membersService.getMember, {
        membershipNo,
    });

    const responseAction = yield take([
        memberActionTypes.FETCH_MEMBER_REQUEST_SUCCEEDED,
        memberActionTypes.FETCH_MEMBER_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {memberDTO} = response;

        yield put({
            type: memberActionTypes.STORE_MEMBER,
            payload: {memberDTO},
        });
    }
};

export const loadMemberVehicles = function* loadMemberVehicles({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');
    const efService = serviceManager.loadService('ecsFlowService');

    const {match} = payload;
    const {membershipNo} = match.params;
    yield fork(fetchRequest, memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST, membersService.getMemberVehicles, {
        membershipNo,
    });

    const responseAction = yield take([
        memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST_SUCCEEDED,
        memberActionTypes.FETCH_MEMBER_VEHICLES_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {memberVehicleDTOs} = response;

        if (memberVehicleDTOs.length === 0) return;

        yield fork(
            fetchRequest,
            serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST,
            efService.getServiceCaseIdsForVehicles,
            {vehicleIds: memberVehicleDTOs.map(vehicle => (vehicle.id))},
        );

        const caseIdsResponseAction = yield take([
            serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_SUCCEEDED,
            serviceCaseActionTypes.FETCH_SERVICE_CASES_FOR_VEHICLES_REQUEST_FAILED,
        ]);

        if (caseIdsResponseAction.error) return;
        const caseIdsResponse = caseIdsResponseAction.payload.response;
        const serviceCaseCountForVehicles = caseIdsResponse?.serviceCaseCountForVehicles || [];
        const memberVehicles = memberVehicleDTOs.map(vehicle => ({
            ...vehicle,
            caseIds: serviceCaseCountForVehicles.find(serviceCaseCountForVehicle => (
                serviceCaseCountForVehicle.vehicleId === vehicle.id
            ))?.caseIds || [],
        }));

        yield put({
            type: memberActionTypes.STORE_MEMBER_VEHICLES,
            payload: {
                membershipNo,
                memberVehicleDTOs: memberVehicles,
            },
        });
    }
};

export const loadMemberServiceCases = function* loadMemberServiceCases({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    const {match} = payload;
    const {membershipNo} = match.params;
    yield fork(
        fetchRequest,
        memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST,
        ecsFlowService.getMemberServiceCases,
        {membershipNo, sort: 'emergencyCallDateTime,desc'},
    );

    const responseAction = yield take([
        memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST_SUCCEEDED,
        memberActionTypes.FETCH_MEMBER_SERVICE_CASES_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {serviceCaseDTOs} = response;
        const serviceCaseIds = serviceCaseDTOs.map(serviceCaseDTO => serviceCaseDTO.id);

        yield put({
            type: memberActionTypes.STORE_MEMBER_SERVICE_CASES,
            payload: {membershipNo, serviceCaseIds},
        });

        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASES,
            payload: {serviceCaseDTOs},
        });
    }
};
