/**
 * Command action types
 * */
export const SEARCH_TASKS = '[tasks] SEARCH_TASKS';
export const CONFIRM_TASK_CREATION = '[service-case] CONFIRM_TASK_CREATION';
export const DECLINE_TASK_CREATION = '[service-case] DECLINE_TASK_CREATION';
export const INITIATE_TASK_UPDATE_FLOW = '[service-case] INITIATE_TASK_UPDATE_FLOW';
export const CONFIRM_TASK_UPDATE = '[service-case] CONFIRM_TASK_UPDATE';
export const DECLINE_TASK_UPDATE = '[service-case] DECLINE_TASK_UPDATE';
export const INITIATE_TASK_CREATION_FLOW = '[service-case] INITIATE_TASK_CREATION_FLOW';

/**
 * Event action types
 * */
export const SEARCH_TASKS_REQUEST = '[tasks] SEARCH_TASKS_REQUEST';
export const SEARCH_TASKS_REQUEST_SENT = '[tasks] SEARCH_TASKS_REQUEST_SENT';
export const SEARCH_TASKS_REQUEST_SUCCEEDED = '[tasks] SEARCH_TASKS_REQUEST_SUCCEEDED';
export const SEARCH_TASKS_REQUEST_FAILED = '[tasks] SEARCH_TASKS_REQUEST_FAILED';

export const CREATE_TASK_REQUEST = '[tasks] CREATE_TASK_REQUEST';
export const CREATE_TASK_REQUEST_SENT = '[tasks] CREATE_TASK_REQUEST_SENT';
export const CREATE_TASK_REQUEST_SUCCEEDED = '[tasks] CREATE_TASK_REQUEST_SUCCEEDED';
export const CREATE_TASK_REQUEST_FAILED = '[tasks] CREATE_TASK_REQUEST_FAILED';

export const FETCH_SERVICE_CASE_TASKS_REQUEST = '[tasks] FETCH_SERVICE_CASE_TASKS_REQUEST';
export const FETCH_SERVICE_CASE_TASKS_REQUEST_SENT = '[tasks] FETCH_SERVICE_CASE_TASKS_REQUEST_SENT';
export const FETCH_SERVICE_CASE_TASKS_REQUEST_SUCCEEDED = '[tasks] FETCH_SERVICE_CASE_TASKS_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_CASE_TASKS_REQUEST_FAILED = '[tasks] FETCH_SERVICE_CASE_TASKS_REQUEST_FAILED';

export const FETCH_CURRENT_USERS_TASKS_REQUEST = '[tasks] FETCH_CURRENT_USERS_TASKS_REQUEST';
export const FETCH_CURRENT_USERS_TASKS_REQUEST_SENT = '[tasks] FETCH_CURRENT_USERS_TASKS_REQUEST_SENT';
export const FETCH_CURRENT_USERS_TASKS_REQUEST_SUCCEEDED = '[tasks] FETCH_CURRENT_USERS_TASKS_REQUEST_SUCCEEDED';
export const FETCH_CURRENT_USERS_TASKS_REQUEST_FAILED = '[tasks] FETCH_CURRENT_USERS_TASKS_REQUEST_FAILED';

export const UPDATE_TASK_REQUEST = '[tasks] UPDATE_TASK_REQUEST';
export const UPDATE_TASK_REQUEST_SENT = '[tasks] UPDATE_TASK_REQUEST_SENT';
export const UPDATE_TASK_REQUEST_SUCCEEDED = '[tasks] UPDATE_TASK_REQUEST_SUCCEEDED';
export const UPDATE_TASK_REQUEST_FAILED = '[tasks] UPDATE_TASK_REQUEST_FAILED';

export const FETCH_REPORTER_TASKS_COUNT_REQUEST = '[tasks] FETCH_REPORTER_TASKS_COUNT_REQUEST';
export const FETCH_REPORTER_TASKS_COUNT_REQUEST_SENT = '[tasks] FETCH_REPORTER_TASKS_COUNT_REQUEST_SENT';
export const FETCH_REPORTER_TASKS_COUNT_REQUEST_SUCCEEDED = '[tasks] FETCH_REPORTER_TASKS_COUNT_REQUEST_SUCCEEDED';
export const FETCH_REPORTER_TASKS_COUNT_REQUEST_FAILED = '[tasks] FETCH_REPORTER_TASKS_COUNT_REQUEST_FAILED';

export const FETCH_SERVICE_CASE_TASK_COUNT_REQUEST = '[tasks] FETCH_SERVICE_CASE_TASK_COUNT_REQUEST';
export const FETCH_SERVICE_CASE_TASK_COUNT_REQUEST_SENT = '[tasks] FETCH_SERVICE_CASE_TASK_COUNT_REQUEST_SENT';
export const FETCH_SERVICE_CASE_TASK_COUNT_REQUEST_SUCCEEDED = '[tasks] FETCH_SERVICE_CASE_TASK_COUNT_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_CASE_TASK_COUNT_REQUEST_FAILED = '[tasks] FETCH_SERVICE_CASE_TASK_COUNT_REQUEST_FAILED';

/**
 * Store action types
 * */
export const SET_ARE_SEARCH_RESULTS_VALID = '[tasks] SET_ARE_SEARCH_RESULTS_VALID';
export const STORE_TASK_SEARCH_RESULTS = '[tasks] STORE_TASK_SEARCH_RESULTS';
export const STORE_TASKS = '[tasks] STORE_TASKS';
export const STORE_SERVICE_CASE_TASK_COUNT = '[tasks] STORE_SERVICE_CASE_TASK_COUNT';
export const STORE_SERVICE_CASE_TODO_TASK_COUNT = '[tasks] STORE_SERVICE_CASE_TODO_TASK_COUNT';
export const SET_TASK_SEARCH_PERSISTENCE_STATE = '[tasks] SET_TASK_SEARCH_PERSISTENCE_STATE';
