import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampVehicleFuelTypes, ampVehicleColorTypes, ampVehicleSpecialFeatureTypes, ampVehicleTypes, ampVehicleLicensePlateCountryTypes, ampMemberTariffAdditionalPackageTypes, ampMembershipTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, Option, Input, RadioTile, ButtonPrimary, InteractiveIcon, AutocompleteField, Pill} from '@ace-de/ui-components';
import {Form, RadioButtonGroup, RadioButton, CheckboxGroup, Checkbox, SelectField, NumberInputField} from '@ace-de/ui-components/form';
import {passengerCarIcon, suvIcon, camperIcon, motorbikeIcon, closeIcon} from '@ace-de/ui-components/icons';
import {germanyFlagImage, otherFlagImage} from '@ace-de/ui-components/assets/flags';
import createDefaultVehicle from '../createDefaultVehicle';
import vehicleTypesMapping from '../vehicleTypesMapping';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as serviceAssignmentActionTypes from '../../service-assignments/serviceAssignmentActionTypes';
import * as memberSelectors from '../../members/memberSelectors';
import useVehicleInfo from '../useVehicleInfo';
import config from '../../config';

const vehicleInitialState = createDefaultVehicle();

const VehicleDataModal = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('vehicle_data_modal');
    const {hasBackdrop, serviceCase, serviceAssignments, vehicle, location} = props;
    const {confirmCreateServiceCaseVehicle, declineCreateServiceCaseVehicle} = props;
    const {confirmEditServiceCaseVehicle, declineEditServiceCaseVehicle} = props;
    const {submitServiceCaseVehicleDataForm, updateServiceAssignmentText} = props;
    const {vehicleInfos, getVehicleManufacturers, getVehicleModels, getVehicleVariants} = props;
    const isEditModeActive = location?.query?.mode === 'edit';

    const {
        getManufacturerNameById,
        getManufacturerIdByName,
        getModelNameById,
        getModelIdByName,
        getVariantNameById,
        getVariantIdByName,
        getManufacturerList,
        getModelList,
        getVariantList,
    } = useVehicleInfo();

    const [formData, setFormData] = useState(
        serviceCase?.vehicle && !serviceCase.vehicle?.vehicleId && !vehicle ? {
            ...serviceCase.vehicle,
            vehicleLicensePlateNumber1: serviceCase?.vehicle?.licensePlateCountryFlag === ampVehicleLicensePlateCountryTypes.GERMANY // eslint-disable-line max-len
                ? serviceCase?.vehicle?.licensePlateNumber?.split(' ')?.[0].split('-')[0] || '' : '',
            vehicleLicensePlateNumber2: serviceCase?.vehicle?.licensePlateCountryFlag === ampVehicleLicensePlateCountryTypes.GERMANY // eslint-disable-line max-len
                ? serviceCase?.vehicle?.licensePlateNumber?.split(' ')?.[0].split('-')[1] || '' : '',
            vehicleLicensePlateNumber3: serviceCase?.vehicle?.licensePlateCountryFlag === ampVehicleLicensePlateCountryTypes.GERMANY // eslint-disable-line max-len
                ? serviceCase?.vehicle?.licensePlateNumber?.split(' ')?.[1] || '' : '',
            color: serviceCase?.vehicle?.color === ampVehicleColorTypes.UNKNOWN ? null : serviceCase.vehicle.color,
            manufacturer: getManufacturerIdByName(serviceCase.vehicle) || serviceCase.vehicle.manufacturer || '',
            model: getModelIdByName(serviceCase.vehicle) || serviceCase.vehicle.model || '',
            variant: getVariantIdByName(serviceCase.vehicle) || serviceCase.vehicle.variant || '',
        } : (vehicle ? {
            ...vehicle,
            vehicleLicensePlateNumber1: vehicle?.licensePlateCountryFlag === ampVehicleLicensePlateCountryTypes.GERMANY
                ? vehicle?.licensePlateNumber?.split(' ')?.[0].split('-')[0] || '' : '',
            vehicleLicensePlateNumber2: vehicle?.licensePlateCountryFlag === ampVehicleLicensePlateCountryTypes.GERMANY
                ? vehicle?.licensePlateNumber?.split(' ')?.[0].split('-')[1] || '' : '',
            vehicleLicensePlateNumber3: vehicle?.licensePlateCountryFlag === ampVehicleLicensePlateCountryTypes.GERMANY
                ? vehicle?.licensePlateNumber?.split(' ')?.[1] || '' : '',
            color: vehicle?.color === ampVehicleColorTypes.UNKNOWN ? null : vehicle.color,
            manufacturer: getManufacturerIdByName(vehicle) || vehicle.manufacturer || '',
            model: getModelIdByName(vehicle) || vehicle.model || '',
            variant: getVariantIdByName(vehicle) || vehicle.variant || '',
        } : vehicleInitialState),
    );
    const [errors, setErrors] = useState({
        weight: '',
        width: '',
        height: '',
        length: '',
        buildYear: '',
    });
    const isEditingLicensePlateAllowed = !(isEditModeActive && (formData.type === ampVehicleTypes.CAMPER
        || serviceCase?.member?.membershipType === ampMembershipTypes.CORPORATE));

    const validateVehicleMeasurements = () => {
        const handleMeasurementErrors = key => {
            if (!formData[key]) return '';
            const floatRegex = /^$|^\d+([.,]\d+)?$/;
            if (floatRegex.test(formData[key])) {
                if (formData[key] <= config[`max_vehicle_${key}`.toUpperCase()]) return '';
                return translateModal(`error_message.max_${key}`);
            }
            return translateModal(`error_message.invalid_format`);
        };

        const updatedErrors = {};
        let hasError = false;
        ['weight', 'width', 'length', 'height'].forEach(key => {
            const errorMessage = handleMeasurementErrors(key);
            updatedErrors[key] = errorMessage;
            if (errorMessage) hasError = true;
        });
        setErrors(prevState => ({
            ...prevState,
            ...updatedErrors,
        }));
        return hasError;
    };

    const formatBuildYear = inputYear => {
        if (!inputYear) return '';
        const yearValue = parseInt(inputYear, 10);
        if (yearValue >= 1000) return inputYear;

        const currentYear = moment().year();
        const currentTens = currentYear % 1000 % 100;

        return yearValue <= currentTens ? `20${inputYear}`
            : (yearValue > 99 ? `1${inputYear}` : `19${inputYear}`);
    };

    const validateBuildYear = inputYear => {
        let errorMessage = '';
        if (inputYear.length === 1 || inputYear.length === 3 || inputYear.length > 4) {
            errorMessage = translateModal('error_message.build_year_format');
        }

        const currentYear = moment().year();
        const formattedYear = formatBuildYear(inputYear); // two digit years should pass
        if (formattedYear && (formattedYear < config.MINIMUM_VEHICLE_BUILD_YEAR || formattedYear > currentYear)) {
            errorMessage = translateModal('error_message.build_year_range', {currentYear});
        }

        setErrors(prevState => ({
            ...prevState,
            'buildYear': errorMessage,
        }));
        return !!errorMessage;
    };

    const handleOnSubmit = formData => {
        const hasError = validateVehicleMeasurements();
        const isBuildYearInvalid = formData.buildYear ? validateBuildYear(formData.buildYear) : false;
        if (hasError || isBuildYearInvalid) return;

        const serviceCaseData = {
            vehicle: {
                ...formData,
                buildYear: formatBuildYear(formData.buildYear),
                color: formData.color !== '' ? formData.color : null,
                ...(!!formData.manufacturer && {
                    manufacturer: getManufacturerNameById(formData) || formData.manufacturer,
                }),
                ...(!!formData.model && {
                    model: getModelNameById(formData) || formData.model,
                }),
                ...(!!formData.variant && {
                    variant: getVariantNameById(formData) || formData.variant,
                }),
                licensePlateNumber: formData?.licensePlateCountryFlag === ampVehicleLicensePlateCountryTypes.GERMANY
                    ? formData?.vehicleLicensePlateNumber1 && formData?.vehicleLicensePlateNumber2
                    && formData?.vehicleLicensePlateNumber3
                        ? `${formData?.vehicleLicensePlateNumber1}-${formData?.vehicleLicensePlateNumber2} ${formData?.vehicleLicensePlateNumber3}`
                        : ''
                    : formData?.licensePlateNumber,
                specialFeatures: formData?.specialFeatures.length === 0
                    ? [ampVehicleSpecialFeatureTypes.UNKNOWN]
                    : formData?.specialFeatures,
            },
        };

        const memberVehicleData = {
            ...formData,
            buildYear: formatBuildYear(formData.buildYear),
            color: formData.color !== '' ? formData.color : null,
            ...(!!formData.manufacturer && {
                manufacturer: getManufacturerNameById(formData) || formData.manufacturer,
            }),
            ...(!!formData.model && {
                model: getModelNameById(formData) || formData.model,
            }),
            ...(!!formData.variant && {
                variant: getVariantNameById(formData) || formData.variant,
            }),
            memberId: serviceCase.member.id,
            licensePlateNumber: isEditingLicensePlateAllowed ? (
                formData?.licensePlateCountryFlag === ampVehicleLicensePlateCountryTypes.GERMANY
                    ? formData?.vehicleLicensePlateNumber1 && formData?.vehicleLicensePlateNumber2
                    && formData?.vehicleLicensePlateNumber3
                        ? `${formData?.vehicleLicensePlateNumber1}-${formData?.vehicleLicensePlateNumber2} ${formData?.vehicleLicensePlateNumber3}`
                        : ''
                    : formData?.licensePlateNumber
            ) : undefined,
            licensePlateCountryFlag: isEditingLicensePlateAllowed ? formData.licensePlateCountryFlag : undefined,
        };

        if (isEditModeActive) {
            confirmEditServiceCaseVehicle({
                membershipNo: serviceCase.member.membershipNo,
                memberVehicle: memberVehicleData,
                serviceCaseId: serviceCase.id,
                serviceCaseData,
                caseType: serviceCase.caseType,
                nonAceMember: serviceCase.nonAceMember,
                isSnapshotUpdateAllowed: serviceCase.vehicle?.vehicleId === vehicle?.id,
            });
            return;
        }

        confirmCreateServiceCaseVehicle({
            membershipNo: serviceCase.member.membershipNo,
            memberVehicle: memberVehicleData,
            serviceCaseId: serviceCase.id,
            serviceCaseData,
            caseType: serviceCase.caseType,
        });
    };

    const handleCloseVehicleCreationModal = () => {
        // if mode=edit then do NOT patch on close
        if (isEditModeActive) {
            declineEditServiceCaseVehicle();
            return;
        }

        // patch if possible and close the modal
        const hasError = validateVehicleMeasurements();
        const isBuildYearInvalid = validateBuildYear(formData.buildYear);
        if (!hasError && !isBuildYearInvalid) {
            const serviceCaseData = {
                vehicle: {
                    ...formData,
                    // BE still does not support any content,
                    // therefore, we have to format two digit years into correct format
                    buildYear: formatBuildYear(formData.buildYear),
                    color: formData.color !== '' ? formData.color : null,
                    ...(!!formData.manufacturer && {
                        manufacturer: getManufacturerNameById(formData) || formData.manufacturer,
                    }),
                    ...(!!formData.model && {
                        model: getModelNameById(formData) || formData.model,
                    }),
                    ...(!!formData.variant && {
                        variant: getVariantNameById(formData) || formData.variant,
                    }),
                    vehicleId: null, // Note: set vehicleId to override prev picked vehicleId, with this relevant data
                    licensePlateNumber: formData?.licensePlateCountryFlag === ampVehicleLicensePlateCountryTypes.GERMANY
                        ? formData?.vehicleLicensePlateNumber1 && formData?.vehicleLicensePlateNumber2
                        && formData?.vehicleLicensePlateNumber3
                            ? `${formData?.vehicleLicensePlateNumber1}-${formData?.vehicleLicensePlateNumber2} ${formData?.vehicleLicensePlateNumber3}`
                            : ''
                        : formData?.licensePlateNumber,
                    specialFeatures: formData?.specialFeatures.length === 0
                        ? [ampVehicleSpecialFeatureTypes.UNKNOWN]
                        : formData?.specialFeatures,
                },
            };

            submitServiceCaseVehicleDataForm({
                serviceCaseId: serviceCase.id,
                serviceCaseData,
                caseType: serviceCase.caseType,
            });
        }

        // close the modal
        declineCreateServiceCaseVehicle();
    };

    const handleManufacturerSelect = value => {
        // if manufacturer has changed and models for the selected manufacturer aren't loaded, get models
        if (value !== formData.manufacturer && !vehicleInfos[vehicleTypesMapping[formData.type]][value]?.models) {
            getVehicleModels({vehicleType: vehicleTypesMapping[formData.type], manufacturer: value.substring(4)});
        }
    };

    const handleModelSelect = value => {
        // if model has changed and variants for the selected model aren't loaded, get variants
        if (value !== formData.model && !vehicleInfos[vehicleTypesMapping[formData.type]][formData.manufacturer].models[value]?.variants) { // eslint-disable-line max-len
            getVehicleVariants({
                vehicleType: vehicleTypesMapping[formData.type],
                manufacturer: formData.manufacturer.substring(4),
                baseModel: value.substring(4),
            });
        }
    };

    const handleOnChange = formValues => {
        const typeHasChanged = !!(formData.type && formValues.type && formData.type !== formValues.type);
        // if type has changed and manufacturers for the selected type aren't loaded, get manufacturers
        if (typeHasChanged && !vehicleInfos[vehicleTypesMapping[formValues.type]]) {
            getVehicleManufacturers({vehicleType: vehicleTypesMapping[formValues.type]});
        }
        const manufacturerHasChanged = formData.manufacturer !== formValues.manufacturer;
        const modelHasChanged = formData.model !== formValues.model;
        setFormData({
            ...formValues,
            manufacturer: typeHasChanged ? '' : formValues.manufacturer,
            model: manufacturerHasChanged ? '' : formValues.model,
            variant: manufacturerHasChanged || modelHasChanged ? '' : formValues.variant,
        });

        if (!serviceCase.member?.tariffDetails?.additionalPackages?.includes(ampMemberTariffAdditionalPackageTypes.CAMPER)) { // eslint-disable-line max-len
            return;
        }

        if ((serviceCase.vehicle?.type !== formValues.type)
            && (serviceCase.vehicle?.type === ampVehicleTypes.CAMPER || formValues.type === ampVehicleTypes.CAMPER)) {
            updateServiceAssignmentText({
                serviceCaseId: serviceCase.id,
                serviceAssignments,
                serviceAssignmentData: {
                    assignmentText: null,
                },
            });
        }
    };

    const handleOnBuildYearBlur = value => {
        if (value && !isNaN(parseInt(value, 10))) {
            setFormData(prevState => ({
                ...prevState,
                buildYear: formatBuildYear(value),
            }));
        }
    };

    // if no service case, don't render
    if (!serviceCase) return null;

    const isSubmitButtonDisabled = !!(formData.type
        && formData.manufacturer
        && formData.model
        && (formData.licensePlateCountryFlag === ampVehicleLicensePlateCountryTypes.GERMANY
            ? (formData.vehicleLicensePlateNumber1
                && formData.vehicleLicensePlateNumber2
                && formData.vehicleLicensePlateNumber3)
            : formData.licensePlateNumber));

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={handleCloseVehicleCreationModal}
                />
            )}
            hasBackdrop={hasBackdrop}
            title={isEditModeActive
                ? translateModal('title.update_vehicle_data')
                : translateModal('title.create_vehicle_data')}
            contentClassName={cx('ace-c-modal__content--scrollable')}
            className={cx('global!ace-u-modal-content-size--m')}
        >
            <Form name="updateVehicleDetailsForm" onChange={handleOnChange}>
                {formValues => {
                    return (
                        <Fragment>
                            {!!isEditModeActive && ((config.IS_FALLBACK_SYSTEM && serviceCase.vehicle.vehicleId) || !serviceCase.nonAceMember) && ( // eslint-disable-line max-len
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-flex--justify-center',
                                        'global!ace-u-margin--top-8',
                                    ])}
                                >
                                    <Pill type="negative">
                                        {translateModal('pill_text.warning')}
                                    </Pill>
                                </div>
                            )}
                            <div className={cx(['global!ace-u-typography--variant-h3', 'global!ace-u-margin--32-0'])}>
                                {translateModal('heading.which_vehicle_type')}
                            </div>
                            <div className={cx('global!ace-u-grid')}>
                                <RadioButtonGroup
                                    name="type"
                                    value={formData?.type || ampVehicleTypes.CAR}
                                >
                                    <RadioTile
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        name="car-type"
                                        value={ampVehicleTypes.CAR}
                                        icon={passengerCarIcon}
                                    >
                                        {translate(`global.service_assignment_vehicle_type.${
                                            (ampVehicleTypes.CAR).toLowerCase()
                                        }`)}
                                    </RadioTile>
                                    <RadioTile
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        name="truck-type"
                                        value={ampVehicleTypes.TRUCK}
                                        icon={suvIcon}
                                    >
                                        {translate(`global.service_assignment_vehicle_type.${
                                            (ampVehicleTypes.TRUCK).toLowerCase()
                                        }`)}
                                    </RadioTile>
                                    <RadioTile
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        name="camper-type"
                                        value={ampVehicleTypes.CAMPER}
                                        icon={camperIcon}
                                    >
                                        {translate(`global.service_assignment_vehicle_type.${
                                            (ampVehicleTypes.CAMPER).toLowerCase()
                                        }`)}
                                    </RadioTile>
                                    <RadioTile
                                        className={cx('global!ace-u-grid-column--span-3')}
                                        name="motorbike-type"
                                        value={ampVehicleTypes.MOTORBIKE}
                                        icon={motorbikeIcon}
                                    >
                                        {translate(`global.service_assignment_vehicle_type.${
                                            (ampVehicleTypes.MOTORBIKE).toLowerCase()
                                        }`)}
                                    </RadioTile>
                                </RadioButtonGroup>
                            </div>
                            <div className={cx(['global!ace-u-typography--variant-h3', 'global!ace-u-margin--32-0'])}>
                                {translateModal('heading.vehicle_details')}
                            </div>
                            <div
                                className={cx('global!ace-u-grid', {
                                    'global!ace-u-margin--bottom-8': !!errors?.buildYear,
                                    'global!ace-u-margin--bottom-48': !errors?.buildYear,
                                })}
                            >
                                <AutocompleteField
                                    name="manufacturer"
                                    value={formData?.manufacturer || ''}
                                    label={translateModal('input_label.manufacturer')}
                                    placeholder={translate('global.select.placeholder')}
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    isArrowIconDisplayed={true}
                                    onOptionSelect={handleManufacturerSelect}
                                >
                                    {getManufacturerList(formData, true).map((manufacturerKey, index) => (
                                        <Option
                                            key={`${manufacturerKey}-${index}`}
                                            name={`${manufacturerKey}-${index}`}
                                            value={manufacturerKey}
                                        >
                                            {vehicleInfos[vehicleTypesMapping[formData.type]][manufacturerKey].name}
                                        </Option>
                                    ))}
                                </AutocompleteField>
                                <AutocompleteField
                                    name="model"
                                    value={formData?.manufacturer ? formData?.model : ''}
                                    label={translateModal('input_label.model')}
                                    placeholder={translate('global.select.placeholder')}
                                    className={cx('global!ace-u-grid-column--span-3')}
                                    isArrowIconDisplayed={true}
                                    onOptionSelect={handleModelSelect}
                                >
                                    {getModelList(formData, true).map((modelKey, index) => (
                                        <Option
                                            key={`${modelKey}-${index}`}
                                            name={`${modelKey}-${index}`}
                                            value={modelKey}
                                        >
                                            {/* eslint-disable-next-line max-len */}
                                            {vehicleInfos[vehicleTypesMapping[formData.type]][formData.manufacturer].models[modelKey].name}
                                        </Option>
                                    ))}
                                </AutocompleteField>
                                <NumberInputField
                                    name="buildYear"
                                    label={translateModal('input_label.build_year')}
                                    value={formData?.buildYear || ''}
                                    className={cx('global!ace-u-grid-column--span-2')}
                                    errors={errors?.buildYear ? [errors.buildYear] : []}
                                    onBlur={handleOnBuildYearBlur}
                                    // allow only integers
                                    min={0}
                                    areOnlyIntegersAllowed={true}
                                />
                                <AutocompleteField
                                    name="variant"
                                    label={translateModal('input_label.variant')}
                                    placeholder={translate('global.select.placeholder')}
                                    value={formData?.manufacturer && formData?.model ? formData?.variant : ''}
                                    className={cx('global!ace-u-grid-column--span-4')}
                                    isArrowIconDisplayed={true}
                                >
                                    {getVariantList(formData, true).map((variantKey, index) => (
                                        <Option
                                            key={`${variantKey}-${index}`}
                                            name={`${variantKey}-${index}`}
                                            value={variantKey}
                                        >
                                            {/* eslint-disable-next-line max-len */}
                                            {vehicleInfos[vehicleTypesMapping[formData.type]][formData.manufacturer].models[formData.model].variants[variantKey].name}
                                        </Option>
                                    ))}
                                </AutocompleteField>
                            </div>
                            <div className={cx('global!ace-u-grid')}>
                                <div className={cx('global!ace-u-grid-column--span-8')}>
                                    <div className={cx(['global!ace-u-grid', 'global!ace-u-margin--bottom-48'])}>
                                        <div
                                            className={cx([
                                                'global!ace-u-grid-column--span-12',
                                                'global!ace-u-grid',
                                                'global!ace-u-flex--align-flex-end',
                                            ])}
                                        >
                                            <SelectField
                                                name="licensePlateCountryFlag"
                                                value={formData?.licensePlateCountryFlag !== ampVehicleLicensePlateCountryTypes.GERMANY // eslint-disable-line max-len
                                                    ? ampVehicleLicensePlateCountryTypes.OTHER
                                                    : ampVehicleLicensePlateCountryTypes.GERMANY}
                                                label={translateModal('input_label.license_plate_no')}
                                                placeholder={translate('global.select.placeholder')}
                                                className={cx('global!ace-u-grid-column--span-2')}
                                                isDisabled={!isEditingLicensePlateAllowed}
                                            >
                                                <Option
                                                    key="germanyFlagImage"
                                                    name="germanyFlagImage"
                                                    value={ampVehicleLicensePlateCountryTypes.GERMANY}
                                                    selectedLabel={(
                                                        <img src={germanyFlagImage} alt="germanyFlagImage" />
                                                    )}
                                                >
                                                    <img src={germanyFlagImage} alt="germanyFlagImage" />
                                                    <span className={cx('global!ace-u-margin--left-8')}>
                                                        {translate(`global.country.germany`)}
                                                    </span>
                                                </Option>
                                                <Option
                                                    key="otherFlagImage"
                                                    name="otherFlagImage"
                                                    value={ampVehicleLicensePlateCountryTypes.OTHER}
                                                    selectedLabel={(
                                                        <img src={otherFlagImage} alt="otherFlagImage" />
                                                    )}
                                                >
                                                    <img src={otherFlagImage} alt="otherFlagImage" />
                                                    <span className={cx('global!ace-u-margin--left-8')}>
                                                        {translate(`global.country.other`)}
                                                    </span>
                                                </Option>
                                            </SelectField>
                                            {formValues.licensePlateCountryFlag === ampVehicleLicensePlateCountryTypes.GERMANY // eslint-disable-line max-len
                                                ? (
                                                    <Fragment>
                                                        <Input
                                                            name="vehicleLicensePlateNumber1"
                                                            value={formData?.vehicleLicensePlateNumber1 || ''}
                                                            className={cx('global!ace-u-grid-column--span-3')}
                                                            isDisabled={!isEditingLicensePlateAllowed}
                                                        />
                                                        <Input
                                                            name="vehicleLicensePlateNumber2"
                                                            value={formData?.vehicleLicensePlateNumber2 || ''}
                                                            className={cx('global!ace-u-grid-column--span-3')}
                                                            isDisabled={!isEditingLicensePlateAllowed}
                                                        />
                                                        <Input
                                                            name="vehicleLicensePlateNumber3"
                                                            value={formData?.vehicleLicensePlateNumber3 || ''}
                                                            className={cx('global!ace-u-grid-column--span-4')}
                                                            isDisabled={!isEditingLicensePlateAllowed}
                                                        />
                                                    </Fragment>
                                                ) : (
                                                    <Input
                                                        name="licensePlateNumber"
                                                        value={formValues?.licensePlateNumber
                                                                || formData?.licensePlateNumber
                                                        }
                                                        className={cx('global!ace-u-grid-column--span-10')}
                                                        isDisabled={!isEditingLicensePlateAllowed}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--column-gap-24',
                                            'global!ace-u-margin--bottom-48',
                                        ])}
                                    >
                                        {/* TODO: replace with SelectField in the future */}
                                        <div className={cx('global!ace-u-flex--grow-1')}>
                                            <NumberInputField
                                                name="numberOfSeats"
                                                value={formData?.numberOfSeats || ''}
                                                label={translateModal('input_label.number_of_seats')}
                                                placeholder={translate('global.select.placeholder')}
                                                className={cx('global!ace-u-full-width')}
                                                // allow only integers
                                                min={0}
                                                areOnlyIntegersAllowed={true}
                                            />
                                        </div>
                                        <div className={cx('global!ace-u-flex--grow-1')}>
                                            <NumberInputField
                                                name="weight"
                                                value={formData?.weight || ''}
                                                label={translateModal('input_label.weight')}
                                                className={cx('global!ace-u-full-width')}
                                                min={0}
                                                errors={errors?.weight ? [errors.weight] : []}
                                            />
                                        </div>
                                        <div className={cx('global!ace-u-flex--grow-1')}>
                                            <SelectField
                                                name="color"
                                                value={formData?.color}
                                                label={translateModal('input_label.color')}
                                                placeholder={translate('global.select.placeholder')}
                                                className={cx('global!ace-u-full-width')}
                                            >
                                                {Object.keys(ampVehicleColorTypes).map(color => (
                                                    <Option key={`${color}-farbe`} name={`${color}-farbe`} value={color}>
                                                        {translate(`global.vehicle_color.${snakeCase(color)}`)}
                                                    </Option>
                                                ))}
                                            </SelectField>
                                        </div>
                                    </div>
                                    <div className={cx('global!ace-u-flex', 'global!ace-u-flex--column-gap-24')}>
                                        <div className={cx('global!ace-u-flex--grow-1')}>
                                            <NumberInputField
                                                name="length"
                                                value={formData?.length || ''}
                                                label={translateModal('input_label.length')}
                                                className={cx('global!ace-u-full-width')}
                                                min={0}
                                                errors={errors?.length ? [errors.length] : []}
                                            />
                                        </div>
                                        <div className={cx('global!ace-u-flex--grow-1')}>
                                            <NumberInputField
                                                name="width"
                                                value={formData?.width || ''}
                                                label={translateModal('input_label.width')}
                                                className={cx('global!ace-u-full-width')}
                                                min={0}
                                                errors={errors?.width ? [errors.width] : []}
                                            />
                                        </div>
                                        <div className={cx('global!ace-u-flex--grow-1')}>
                                            <NumberInputField
                                                name="height"
                                                value={formData?.height || ''}
                                                label={translateModal('input_label.height')}
                                                className={cx('global!ace-u-full-width')}
                                                min={0}
                                                errors={errors?.height ? [errors.height] : []}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('global!ace-u-grid-column--span-4')}>
                                    <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--justify-flex-end'])}>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--direction-column',
                                                'global!ace-u-margin--right-24',
                                            ])}
                                        >
                                            <div className={cx('global!ace-u-typography--variant-body-bold')}>
                                                {translateModal('input_label.fuel_type')}
                                            </div>
                                            <RadioButtonGroup name="fuelType" value={formData?.fuelType || ampVehicleFuelTypes.UNKNOWN}>
                                                {Object.keys(ampVehicleFuelTypes)
                                                    .filter(key => key !== ampVehicleFuelTypes.UNKNOWN)
                                                    .map(fuelType => (
                                                        <RadioButton
                                                            className={cx('global!ace-u-margin--top-24')}
                                                            key={fuelType}
                                                            name={fuelType}
                                                            value={fuelType}
                                                        >
                                                            {translate(`global.vehicle_fuel_type.${snakeCase(fuelType)}`)}
                                                        </RadioButton>
                                                    ))}
                                            </RadioButtonGroup>
                                        </div>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--direction-column',
                                            ])}
                                        >
                                            <div className={cx('global!ace-u-typography--variant-body-bold')}>
                                                {translateModal('input_label.other')}
                                            </div>
                                            <CheckboxGroup name="specialFeatures" value={formData?.specialFeatures || ampVehicleSpecialFeatureTypes.UNKNOWN}>
                                                {Object.keys(ampVehicleSpecialFeatureTypes)
                                                    .filter(key => key !== ampVehicleSpecialFeatureTypes.UNKNOWN)
                                                    .map(specialFeature => (
                                                        <Checkbox
                                                            className={cx('global!ace-u-margin--top-24')}
                                                            key={specialFeature}
                                                            name={specialFeature}
                                                            value={specialFeature}
                                                        >
                                                            {translate(`global.vehicle_special_feature.${snakeCase(specialFeature)}`)}
                                                        </Checkbox>
                                                    ))}
                                            </CheckboxGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-margin--top-16',
                                    'global!ace-u-flex--justify-space-between',
                                    'global!ace-u-flex--align-center',
                                ])}
                            >
                                <div className={cx('global!ace-u-typography--variant-caption')}>
                                    <p>
                                        {translateModal('text.valid_from', {validFrom: vehicle?.validFrom
                                            ? moment(vehicle.validFrom).format('DD.MM.YYYY, HH:mm')
                                            : '-'})}
                                    </p>
                                    <p
                                        className={cx('global!ace-u-margin--top-4', {
                                            'global!ace-u-typography--color-warning': moment().diff(vehicle?.validUntil) < 0,
                                        })}
                                    >
                                        {translateModal('text.valid_until', {validUntil: vehicle?.validUntil
                                            ? moment(vehicle.validUntil).format('DD.MM.YYYY, HH:mm')
                                            : '-'})}
                                    </p>
                                </div>
                                <div>
                                    <ButtonPrimary
                                        onClick={() => handleOnSubmit(formData)}
                                        isDisabled={!isSubmitButtonDisabled}
                                    >
                                        {translateModal('button_label.edit_data')}
                                    </ButtonPrimary>
                                </div>
                            </div>
                        </Fragment>
                    );
                }}
            </Form>
        </Modal>
    );
};

VehicleDataModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    location: PropTypes.object,
    serviceCase: PropTypes.object,
    vehicleInfos: PropTypes.object,
    serviceAssignments: PropTypes.object,
    vehicle: PropTypes.object,
    getVehicleManufacturers: PropTypes.func.isRequired,
    getVehicleModels: PropTypes.func.isRequired,
    getVehicleVariants: PropTypes.func.isRequired,
    confirmCreateServiceCaseVehicle: PropTypes.func.isRequired,
    declineCreateServiceCaseVehicle: PropTypes.func.isRequired,
    confirmEditServiceCaseVehicle: PropTypes.func.isRequired,
    declineEditServiceCaseVehicle: PropTypes.func.isRequired,
    submitServiceCaseVehicleDataForm: PropTypes.func.isRequired,
    updateServiceAssignmentText: PropTypes.func.isRequired,
};

VehicleDataModal.defaultProps = {
    hasBackdrop: false,
    location: null,
    serviceCase: null,
    vehicleInfos: {},
    serviceAssignments: null,
    vehicle: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const vehicleSelector = memberSelectors.createVehicleByIdSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        vehicleInfos: serviceCaseSelectors.getVehicleInfos(state),
        serviceAssignments: serviceCaseSelectors.getServiceAssignments(state, props),
        vehicle: vehicleSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmCreateServiceCaseVehicle: payload => dispatch({
        type: serviceCaseActionTypes.CONFIRM_CREATE_SERVICE_CASE_VEHICLE,
        payload,
    }),
    declineCreateServiceCaseVehicle: () => dispatch({
        type: serviceCaseActionTypes.DECLINE_CREATE_SERVICE_CASE_VEHICLE,
    }),
    confirmEditServiceCaseVehicle: payload => dispatch({
        type: serviceCaseActionTypes.CONFIRM_EDIT_SERVICE_CASE_VEHICLE,
        payload,
    }),
    declineEditServiceCaseVehicle: () => dispatch({
        type: serviceCaseActionTypes.DECLINE_EDIT_SERVICE_CASE_VEHICLE,
    }),
    submitServiceCaseVehicleDataForm: payload => dispatch({
        type: serviceCaseActionTypes.SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM,
        payload,
    }),
    updateServiceAssignmentText: payload => dispatch({
        type: serviceAssignmentActionTypes.INITIATE_UPDATE_SERVICE_ASSIGNMENT_TEXT_FLOW,
        payload,
    }),
    getVehicleManufacturers: payload => dispatch({
        type: serviceCaseActionTypes.GET_VEHICLE_MANUFACTURERS,
        payload,
    }),
    getVehicleModels: payload => dispatch({
        type: serviceCaseActionTypes.GET_VEHICLE_MODELS,
        payload,
    }),
    getVehicleVariants: payload => dispatch({
        type: serviceCaseActionTypes.GET_VEHICLE_VARIANTS,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDataModal);
