import React, {Fragment, useState, useRef, useMemo, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {withRouter, useRouteUnmountEffect} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, efLocationTypes, efChildrenAgeTypes, Location, efServiceTypes} from '@ace-de/eua-entity-types'; // eslint-disable-line max-len
import {useArcGISMap, createMarkerGraphic, markerHomePNG, markerVehiclePNG, mapArcGISPOITypeToMarkerIcon} from '@ace-de/eua-arcgis-map';
import {useStyles, ContentBlock, ContentItem, Panel, ScrollableBlock, InputCounter, Option, Pill, Divider} from '@ace-de/ui-components';
import {Form, TextAreaField, AutosuggestField, InputField, InputPhoneNumberField, SelectField, ButtonPrimary, DateField, SearchBox, Checkbox} from '@ace-de/ui-components/form';
import {Icon, locationIcon, checkmarkIcon, calendarIcon} from '@ace-de/ui-components/icons';
import config from '../config';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../service-assignments/serviceAssignmentSelectors';
import * as savActionTypes from './savActionTypes';
import * as serviceCaseActionTypes from '../service-cases/serviceCaseActionTypes';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';

const SAVDamageLocationTab = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('sav_damage_location_tab');
    const arcGISMap = useArcGISMap('service-assignment-vehicle');
    const [damageLocationInfoFormData, setNewDamageLocationInfoFormData] = useState(null);
    const currentDamageLocationAddress = useRef('');
    const lastDamageLocationSearchQuery = useRef('');
    const prevReverseGeocodingDamageLocation = useRef(null);
    const {serviceCase, serviceAssignment, setArcGISNearbyPOIs} = props;
    const {searchArcGISNearbyPOIs, selectedPOIsCategory, arcGISNearbyPOIs} = props;
    const {searchSAVDamageLocationGeolocation, searchSAVReverseDamageLocationGeolocation} = props;
    const {submitSAVDamageLocationForm, submitSAVDamageLocationInfoForm} = props;
    const {resetSAVReverseGeocodingDamageLocation} = props;
    const initialNewDamageLocationType = serviceCase && serviceCase.damage && serviceCase.damage.location
        ? serviceCase.damage.location.locationType : efLocationTypes.SELECT_LOCATION;
    const [newDamageLocationType, setNewDamageLocationType] = useState(initialNewDamageLocationType);
    const [newDamageLocation, setNewDamageLocation] = useState(null);
    const newDamageLocationRef = useRef(newDamageLocation?.formattedAddress || '');
    const searchSAVDamageLocationGeolocationDebounced = useMemo(
        () => debounce(searchSAVDamageLocationGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchSAVDamageLocationGeolocation],
    );
    const [childrenAge, setChildrenAge] = useState(serviceCase.childrenAge.length
        ? (serviceCase.childrenAge.length < serviceCase.childrenAtSite
            ? serviceCase.childrenAge
                .concat(new Array(serviceCase.childrenAtSite - serviceCase.childrenAge.length)
                    .fill('', 0))
            : serviceCase.childrenAge)
        : (serviceCase.childrenAtSite
            ? new Array(serviceCase.childrenAtSite).fill('', 0)
            : []));

    useEffect(() => {
        if (!serviceCase || !arcGISMap || !!newDamageLocation) return;

        const {damage} = serviceCase;
        if (damage && damage.location) {
            setNewDamageLocation(damage.location);

            if (damage.location.locationType === efLocationTypes.RESIDENCE_PLACE) {
                arcGISMap.toggleMapClickEvent(false);
                return; // early exit to prevent enabling map click event
            }
        }

        arcGISMap.toggleMapClickEvent(true);
    }, [serviceCase, newDamageLocation, arcGISMap]);

    useEffect(() => {
        if (!serviceCase) return;

        const {reverseGeocodingDamageLocation} = serviceCase;
        if (reverseGeocodingDamageLocation
            && reverseGeocodingDamageLocation !== prevReverseGeocodingDamageLocation.current) {
            prevReverseGeocodingDamageLocation.current = reverseGeocodingDamageLocation;
            setNewDamageLocationType(reverseGeocodingDamageLocation.locationType);
            setNewDamageLocation(reverseGeocodingDamageLocation);
        }
    }, [serviceCase]);

    useEffect(() => {
        if (!arcGISMap) return;

        arcGISMap.hideLayer('sav-contract-partner-service-areas');
        arcGISMap.hideLayer('sav-contract-partner-routes');
        arcGISMap.hideLayer('sav-contract-partner-locations');
        arcGISMap.hideLayer('sav-towing-destinations');
        arcGISMap.hideLayer('sav-towing-destination-routes');
    }, [arcGISMap]);

    useEffect(() => {
        if (newDamageLocation && (newDamageLocation.formattedAddress !== newDamageLocationRef.current)) {
            newDamageLocationRef.current = newDamageLocation?.formattedAddress || '';
            if (arcGISNearbyPOIs?.length && selectedPOIsCategory) {
                searchArcGISNearbyPOIs({
                    categoriesString: selectedPOIsCategory,
                });
            }
        }
    }, [newDamageLocation, arcGISNearbyPOIs, selectedPOIsCategory, searchArcGISNearbyPOIs]);

    useEffect(() => {
        if (!serviceCase || !arcGISMap) return;

        const {member} = serviceCase;
        const {residenceLocation} = member;

        arcGISMap.setGraphics({
            graphics: [
                ...(newDamageLocation ? [
                    createMarkerGraphic({
                        id: 'damageLocation',
                        longitude: newDamageLocation.longitude,
                        latitude: newDamageLocation.latitude,
                        icon: markerVehiclePNG,
                        isDraggable: newDamageLocation.locationType !== efLocationTypes.RESIDENCE_PLACE,
                    }),
                ] : []),
                ...((residenceLocation && residenceLocation.longitude && residenceLocation.latitude)
                    && (!newDamageLocation
                    || (newDamageLocation && newDamageLocation.locationType !== efLocationTypes.RESIDENCE_PLACE))
                    ? [
                        createMarkerGraphic({
                            id: 'residenceLocation',
                            longitude: residenceLocation.longitude,
                            latitude: residenceLocation.latitude,
                            icon: markerHomePNG,
                        }),
                    ] : []),
                ...(arcGISNearbyPOIs?.length
                    ? arcGISNearbyPOIs.map(poi => createMarkerGraphic({
                        id: poi.id,
                        longitude: poi.longitude,
                        latitude: poi.latitude,
                        icon: mapArcGISPOITypeToMarkerIcon(Array.isArray(poi.establishmentType)
                            ? poi.establishmentType[0] : ''),
                        isSmall: true,
                        popupFields: {
                            formattedAddress: poi.formattedAddress,
                        },
                        popupTemplate: {
                            title: poi.locationName,
                            content: `<p>{formattedAddress}</p>`,
                        },
                    })) : []),
            ],
        });
    }, [serviceCase, newDamageLocation, arcGISMap, arcGISNearbyPOIs]);

    const handleDamageLocationTypeChange = useCallback(newDamageLocationType => {
        const {member, damage} = serviceCase;
        const {residenceLocation} = member;
        setNewDamageLocationType(newDamageLocationType);

        if (newDamageLocationType === efLocationTypes.RESIDENCE_PLACE) {
            setNewDamageLocation(residenceLocation);
            if (arcGISMap) arcGISMap.toggleMapClickEvent(false);
            return;
        }

        // reset to efLocationTypes.SELECT_LOCATION or efLocationTypes.TRACK_ROUTE
        if (newDamageLocationType === efLocationTypes.SELECT_LOCATION
            && damage
            && damage.location
            && damage.location.locationType === efLocationTypes.SELECT_LOCATION) {
            setNewDamageLocation(damage.location);
            if (arcGISMap) arcGISMap.toggleMapClickEvent(true);
            return;
        }

        setNewDamageLocation(new Location({
            locationType: newDamageLocationType,
        }));
        if (arcGISMap) arcGISMap.toggleMapClickEvent(true);
    }, [serviceCase, arcGISMap]);

    const handleDamageLocationSearchQueryChange = searchQueryString => {
        if (!searchQueryString) return;

        if (searchQueryString.toLowerCase() !== currentDamageLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchSAVDamageLocationGeolocationDebounced({
                searchQueryString,
                serviceCaseId: serviceCase.id,
                ...(!!(residenceLocation && residenceLocation.longitude && residenceLocation.latitude) && {
                    location: [residenceLocation.longitude, residenceLocation.latitude].toString(),
                }),
            });
            lastDamageLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleDamageLocationCoordinatesSelect = damageLocationCoordinatesString => {
        if (!damageLocationCoordinatesString) return;

        const coordinatesString = damageLocationCoordinatesString.trim();
        const latLngRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
        if (coordinatesString && latLngRegex.test(coordinatesString)) {
            const [latitude, longitude] = coordinatesString.split(',');
            if (latitude && longitude) {
                searchSAVReverseDamageLocationGeolocation({
                    serviceCaseId: serviceCase.id,
                    latitude: latitude.trim(),
                    longitude: longitude.trim(),
                });
            }
        }
    };

    const handleDamageLocationCandidateSelect = locationCandidate => {
        currentDamageLocationAddress.current = locationCandidate.formattedAddress;
        setNewDamageLocation(locationCandidate);
    };

    const handleDamageLocationFormSubmit = useCallback(() => {
        if (!newDamageLocation) return;

        submitSAVDamageLocationForm({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceAssignmentData: {
                serviceLocation: newDamageLocation,
            },
            serviceCaseData: {
                damage: {
                    location: newDamageLocation,
                },
            },
        });
    }, [
        submitSAVDamageLocationForm,
        serviceCase.id,
        serviceAssignment.lineNo,
        newDamageLocation,
    ]);

    const handleTabChange = useCallback(() => {
        const {locationNotes, callbackPhoneNo, unknownCallbackPhoneNo, destinationIsReached, ...restFormValues} = damageLocationInfoFormData; // eslint-disable-line max-len
        const {isCreditCardAvailable} = restFormValues;
        submitSAVDamageLocationInfoForm({
            serviceCaseId: serviceCase.id,
            serviceAssignmentLineNo: serviceAssignment.lineNo,
            serviceCaseData: {
                ...restFormValues,
                childrenAge: childrenAge.filter(age => age !== ''),
                ...(serviceCase.damage && serviceCase.damage.location && {
                    damage: {
                        location: {
                            locationNotes,
                        },
                        destinationIsReached: !!destinationIsReached,
                    },
                }),
                isCreditCardAvailable: !!isCreditCardAvailable,
                // TODO: Hidden feature >> uncomment this when feature is ready
                // vehicleNotAccessible: !!formValues.vehicleNotAccessible,
            },
            serviceCaseCallbackPhoneNoData: {
                callbackPhoneNo,
                unknownCallbackPhoneNo: !!unknownCallbackPhoneNo,
            },
        });
    }, [
        childrenAge,
        damageLocationInfoFormData,
        submitSAVDamageLocationInfoForm,
        serviceCase.damage,
        serviceCase.id,
        serviceAssignment.lineNo,
    ]);

    const updateChildrenAgeData = formValues => {
        const {childrenAtSite, childrenAge: childrenAgeValues} = formValues;
        const currentChildrenAgeValues = childrenAtSite
            ? (Object.keys(childrenAgeValues).length
                ? Object.keys(childrenAgeValues).map(key => childrenAgeValues[key])
                    .slice(0, damageLocationInfoFormData?.childrenAtSite || serviceCase.childrenAtSite)
                : childrenAge)
            : [];
        if (childrenAtSite > currentChildrenAgeValues.length) {
            setChildrenAge([...currentChildrenAgeValues, '']);
            return;
        }
        if (childrenAtSite < currentChildrenAgeValues.length) {
            const updatedChildrenArray = [...currentChildrenAgeValues];
            updatedChildrenArray.pop();
            setChildrenAge(updatedChildrenArray);
            return;
        }
        setChildrenAge(currentChildrenAgeValues);
    };

    const handleDamageLocationInfoFormChange = formValues => {
        updateChildrenAgeData(formValues);
        setNewDamageLocationInfoFormData(formValues);
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        // on tab change, always reset serviceCase.reverseGeocodingDamageLocation
        resetSAVReverseGeocodingDamageLocation({serviceCaseId: serviceCase.id});
        // on tab change, always reset serviceAssignment.arcGISNearbyPOIs
        setArcGISNearbyPOIs({
            arcGISLocationCandidateDTOs: [],
            selectedPOIsCategory: '',
        });

        if (!damageLocationInfoFormData) {
            completeRouteUnmountSideEffect({
                caller: savActionTypes.SUBMIT_SAV_DAMAGE_LOCATION_INFO_FORM,
            });
            return;
        }

        handleTabChange(damageLocationInfoFormData);
    }, [
        damageLocationInfoFormData,
        handleTabChange,
        resetSAVReverseGeocodingDamageLocation,
        serviceCase.id,
        setArcGISNearbyPOIs,
    ]);

    // if no case don't render
    if (!serviceCase) return null;

    const {member} = serviceCase;
    const {residenceLocation} = member;
    const {damage, damageLocationCandidates, damageLocationSearchQuery} = serviceCase;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
                'ace-c-content-block--sidebar',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-content-item--sidebar',
                ])}
            >
                <Panel
                    title={translateTab('panel_title.damage_location')}
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <ScrollableBlock
                        label={translateTab('scrollable_block_label.fill_details')}
                    >
                        <Form
                            name="damageLocationForm"
                            onSubmit={handleDamageLocationFormSubmit}
                            isDisabled={serviceCase.persistenceState === persistenceStates.PENDING}
                        >
                            {formValues => {
                                const isSubmitButtonDisabled = !(formValues['damageLocationType'] === efLocationTypes.GEO_COORDINATES
                                    ? formValues['damageLocationGeoCoordinates'] : formValues['damageLocationSearchQuery'])
                                    || (newDamageLocation
                                        ? (!newDamageLocation.longitude || !newDamageLocation.latitude) : false);
                                const isDamageLocationSaved = damage
                                    && damage.location
                                    && damage.location.formattedAddress
                                    && (formValues['damageLocationType'] !== efLocationTypes.GEO_COORDINATES
                                        ? formValues['damageLocationSearchQuery'] === damage.location.formattedAddress
                                        : true)
                                    && formValues['damageLocationType'] === damage.location.locationType
                                    && (newDamageLocation
                                        ? newDamageLocation.longitude === damage.location.longitude
                                            && newDamageLocation.latitude === damage.location.latitude
                                        : true);

                                return (
                                    <Fragment>
                                        <SelectField
                                            name="damageLocationType"
                                            value={newDamageLocationType}
                                            onChange={handleDamageLocationTypeChange}
                                            label={translateTab('select_label.damage_location_information')}
                                            placeholder={translateTab('select_placeholder.please_select')}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-48',
                                            ])}
                                        >
                                            <Option
                                                name="damage-location-type-select-location"
                                                value={efLocationTypes.SELECT_LOCATION}
                                            >
                                                {translateTab('select_option_label.damage_location_known')}
                                            </Option>
                                            <Option
                                                name="damage-location-type-residence-place"
                                                value={efLocationTypes.RESIDENCE_PLACE}
                                            >
                                                {translateTab('select_option_label.residence_place')}
                                            </Option>
                                            <Option
                                                name="damage-location-type-geo-coordinates"
                                                value={efLocationTypes.GEO_COORDINATES}
                                            >
                                                {translateTab('select_option_label.geo_coordinates')}
                                            </Option>
                                        </SelectField>
                                        {formValues['damageLocationType'] === efLocationTypes.TRACK_ROUTE && (
                                            <Fragment>
                                                <InputField
                                                    name="routeStart"
                                                    label={translateTab('input_label.start_point')}
                                                    value=""
                                                    placeholder={translateTab('input_placeholder.please_fill_in')}
                                                    className={cx([
                                                        'global!ace-u-full-width',
                                                        'global!ace-u-margin--bottom-48',
                                                    ])}
                                                    isAutoCompleteOff={true}
                                                />
                                                <InputField
                                                    name="routeEnd"
                                                    label={translateTab('input_label.end_point')}
                                                    value=""
                                                    placeholder={translateTab('input_placeholder.please_fill_in')}
                                                    className={cx([
                                                        'global!ace-u-full-width',
                                                        'global!ace-u-margin--bottom-48',
                                                    ])}
                                                    isAutoCompleteOff={true}
                                                />
                                            </Fragment>
                                        )}
                                        {formValues['damageLocationType'] === efLocationTypes.GEO_COORDINATES && (
                                            <Fragment>
                                                <p className={cx([
                                                    'global!ace-u-typography--variant-caption',
                                                    'global!ace-u-margin--bottom-8',
                                                ])}
                                                >
                                                    {translateTab('input_label.geo_coordinates')}
                                                </p>
                                                <SearchBox
                                                    name="damageLocationGeoCoordinates"
                                                    placeholder={translateTab('input_placeholder.latitude_longitude')}
                                                    value={newDamageLocation
                                                        && newDamageLocation.latitude
                                                        && newDamageLocation.longitude
                                                        ? `${newDamageLocation.latitude},${newDamageLocation.longitude}` : ''}
                                                    onSearchSubmit={handleDamageLocationCoordinatesSelect}
                                                    isDropDownDisabled={true}
                                                    className={cx([
                                                        'global!ace-u-full-width',
                                                        'global!ace-u-margin--bottom-16',
                                                    ])}
                                                />
                                                {newDamageLocation && newDamageLocation.formattedAddress && (
                                                    <Fragment>
                                                        <div
                                                            className={cx([
                                                                'global!ace-u-flex--align-self-flex-start',
                                                                'global!ace-u-typography--variant-body-bold',
                                                            ])}
                                                        >
                                                            {translateTab('input_label.damage_location')}
                                                        </div>
                                                        <div
                                                            className={cx([
                                                                'global!ace-u-flex--align-self-flex-start',
                                                                'global!ace-u-margin--bottom-24',
                                                            ])}
                                                        >
                                                            {newDamageLocation.formattedAddress}
                                                        </div>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        )}
                                        {(formValues['damageLocationType'] === efLocationTypes.RESIDENCE_PLACE
                                            || formValues['damageLocationType'] === efLocationTypes.SELECT_LOCATION) && (
                                            <AutosuggestField
                                                name="damageLocationSearchQuery"
                                                value={newDamageLocation?.formattedAddress || ''}
                                                isDisabled={formValues['damageLocationType'] !== efLocationTypes.SELECT_LOCATION}
                                                onChange={handleDamageLocationSearchQueryChange}
                                                onOptionSelect={handleDamageLocationCandidateSelect}
                                                optionValueSelector={locationCandidate => {
                                                    return locationCandidate.formattedAddress;
                                                }}
                                                label={translateTab('input_label.damage_location')}
                                                placeholder={translateTab('input_placeholder.please_fill_in')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--bottom-48',
                                                ])}
                                            >
                                                {(formValues['damageLocationSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                                    // eslint-disable-next-line max-len
                                                    && damageLocationSearchQuery === lastDamageLocationSearchQuery.current
                                                    ? damageLocationCandidates
                                                        .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                        .map((locationCandidate, index) => {
                                                            return (
                                                                <Option
                                                                    key={index}
                                                                    name={`damage-location-candidate-${index}`}
                                                                    value={locationCandidate}
                                                                >
                                                                    <Icon
                                                                        icon={locationIcon}
                                                                        className={cx('global!ace-u-margin--right-16')}
                                                                    />
                                                                    {locationCandidate.formattedAddress}
                                                                </Option>
                                                            );
                                                        }) : null}
                                            </AutosuggestField>
                                        )}
                                        <ButtonPrimary
                                            name="submitDamageLocation"
                                            type="submit"
                                            isDisabled={isSubmitButtonDisabled}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-24',
                                            ], {
                                                'ace-c-button-primary--is-positive': isDamageLocationSaved,
                                            })}
                                        >
                                            {isDamageLocationSaved
                                                ? (
                                                    <Fragment>
                                                        {translateTab('button_label.damage_location_confirmed')}
                                                        <Icon
                                                            icon={checkmarkIcon}
                                                            className={cx(
                                                                'ace-c-icon--color-contrast',
                                                                'global!ace-u-margin--left-16',
                                                            )}
                                                        />
                                                    </Fragment>
                                                ) : translateTab('button_label.confirm_damage_location')
                                            }
                                        </ButtonPrimary>
                                        {residenceLocation.formattedAddress && (
                                            <Fragment>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-typography--variant-body-bold',
                                                    ])}
                                                >
                                                    {translateTab('label.members_place_of_residence')}
                                                </div>
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex--align-self-flex-start',
                                                        'global!ace-u-margin--bottom-24',
                                                    ])}
                                                >
                                                    {residenceLocation.formattedAddress}
                                                </div>
                                                {serviceCase.distanceResidenceToDamageLocation !== null && (
                                                    <div className={cx('global!ace-u-margin--bottom-24')}>
                                                        <Pill
                                                            type="information"
                                                            className={cx('global!ace-u-typography--variant-caption')}
                                                        >
                                                            {translateTab('pill_label.residence_to_damage_location_distance')}&nbsp;
                                                            {Math
                                                                .round(serviceCase.distanceResidenceToDamageLocation)}
                                                            {translate('global.unit.km')}
                                                        </Pill>
                                                    </div>
                                                )}
                                            </Fragment>
                                        )}
                                    </Fragment>
                                );
                            }}
                        </Form>
                        <Form
                            name="damageLocationInfoForm"
                            onChange={handleDamageLocationInfoFormChange}
                        >
                            {formValues => {
                                return (
                                    <Fragment>
                                        {/* TODO: Hidden feature >> uncomment this when feature is ready */}
                                        {/* <Checkbox */}
                                        {/*    name="vehicleNotAccessible" */}
                                        {/*    value={true} */}
                                        {/*    isSelected={serviceCase.vehicleNotAccessible} */}
                                        {/*    className={cx([ */}
                                        {/*        'global!ace-u-flex--align-self-flex-start', */}
                                        {/*        'global!ace-u-margin--bottom-24', */}
                                        {/*    ])} */}
                                        {/* > */}
                                        {/*    {translateTab('checkbox_label.vehicle_not_accessible')} */}
                                        {/* </Checkbox> */}
                                        <InputPhoneNumberField
                                            name="callbackPhoneNo"
                                            label={translateTab('input_label.callback_phone_no')}
                                            value={formValues['unknownCallbackPhoneNo']
                                                ? '' : (serviceCase.callbackPhoneNo || '')}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-8',
                                            ])}
                                            isDisabled={!!formValues['unknownCallbackPhoneNo']}
                                            isInvalid={!formValues.unknownCallbackPhoneNo
                                                && !formValues.callbackPhoneNo}
                                        />
                                        <Checkbox
                                            name="unknownCallbackPhoneNo"
                                            value={true}
                                            isSelected={!!serviceCase.unknownCallbackPhoneNo}
                                            className={cx([
                                                'global!ace-u-flex--align-self-flex-start',
                                                'global!ace-u-margin--bottom-48',
                                            ])}
                                        >
                                            {translateTab('checkbox_label.unknown_callback_phone_no')}
                                        </Checkbox>
                                        <TextAreaField
                                            name="locationNotes"
                                            label={translateTab('text_area_label.location_notes')}
                                            placeholder={translateTab('text_area_placeholder.location_notes')}
                                            value={damage && damage.location
                                                ? damage.location.locationNotes : ''}
                                            isResizable={false}
                                            isDisabled={!damage || !damage.location}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-margin--bottom-24',
                                            ])}
                                        />
                                        <div
                                            className={cx('global!ace-u-flex--align-self-flex-start')}
                                        >
                                            {translateTab('text.present_place')}
                                        </div>
                                        <InputCounter
                                            name="grownUpsAtSite"
                                            label={translateTab('input_counter_label.adults')}
                                            value={serviceCase.grownUpsAtSite}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'ace-c-input-counter--list-element',
                                            ])}
                                        />
                                        <Divider />
                                        <InputCounter
                                            name="childrenAtSite"
                                            label={translateTab('input_counter_label.children')}
                                            value={serviceCase.childrenAtSite}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'ace-c-input-counter--list-element',
                                            ])}
                                        />
                                        {!childrenAge.length && (
                                            <Divider />
                                        )}
                                        <Form name="childrenAge">
                                            <div
                                                className={cx('global!ace-u-grid', {
                                                    'global!ace-u-padding--16-0': childrenAge.length,
                                                })}
                                            >
                                                {childrenAge.map((age, idx) => (
                                                    <SelectField
                                                        key={idx}
                                                        name={`childrenAge${idx}`}
                                                        label={translateTab('input_label.children_age')}
                                                        value={age}
                                                        className={cx('global!ace-u-grid-column--span-6')}
                                                    >
                                                        {Object.keys(efChildrenAgeTypes).map((ageOption, idx) => (
                                                            <Option
                                                                key={idx}
                                                                name={`children-age-option-${idx}`}
                                                                value={idx}
                                                            >
                                                                {efChildrenAgeTypes[ageOption]}
                                                            </Option>
                                                        ))}
                                                    </SelectField>
                                                ))}
                                            </div>
                                            {!!childrenAge.length && (
                                                <Divider />
                                            )}
                                        </Form>
                                        <InputCounter
                                            name="petsAtSite"
                                            label={translateTab('input_counter_label.animals')}
                                            value={serviceCase.petsAtSite}
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'ace-c-input-counter--list-element',
                                            ])}
                                        />
                                        <Divider className={cx('global!ace-u-margin--bottom-24')} />
                                        <DateField
                                            name="departureDate"
                                            label={translateTab('date_field_label.planned_departure')}
                                            value={serviceCase.departureDate}
                                            placeholder={translateTab('date_field_placeholder.please_enter')}
                                            icon={calendarIcon}
                                            locale={activeLocale}
                                            format="DD.MM.YYYY"
                                            className={cx('global!ace-u-full-width')}
                                        />
                                        {serviceAssignment.serviceType === efServiceTypes.TOWING && (
                                            <Checkbox
                                                name="destinationIsReached"
                                                value={true}
                                                className={cx('global!ace-u-margin--top-24')}
                                                isSelected={serviceCase.damage?.destinationIsReached}
                                            >
                                                {translateTab('checkbox_label.vacation_destination_reached')}
                                            </Checkbox>
                                        )}
                                        <Checkbox
                                            name="isCreditCardAvailable"
                                            value={true}
                                            className={cx('global!ace-u-margin--top-24')}
                                            isSelected={serviceCase.isCreditCardAvailable}
                                        >
                                            {translateTab(`checkbox_label.credit_card_is_available`)}
                                        </Checkbox>
                                    </Fragment>
                                );
                            }}
                        </Form>
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
        </ContentBlock>
    );
};

SAVDamageLocationTab.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    searchSAVDamageLocationGeolocation: PropTypes.func.isRequired,
    submitSAVDamageLocationForm: PropTypes.func.isRequired,
    submitSAVDamageLocationInfoForm: PropTypes.func.isRequired,
    searchSAVReverseDamageLocationGeolocation: PropTypes.func.isRequired,
    resetSAVReverseGeocodingDamageLocation: PropTypes.func.isRequired,
    arcGISNearbyPOIs: PropTypes.array,
    setArcGISNearbyPOIs: PropTypes.func.isRequired,
    selectedPOIsCategory: PropTypes.string,
    searchArcGISNearbyPOIs: PropTypes.func.isRequired,
};

SAVDamageLocationTab.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    arcGISNearbyPOIs: [],
    selectedPOIsCategory: '',
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
        arcGISNearbyPOIs: state.serviceAssignments.arcGISNearbyPOIs,
        selectedPOIsCategory: state.serviceAssignments.selectedPOIsCategory,
    };
};

const mapDispatchToProps = dispatch => ({
    searchSAVDamageLocationGeolocation: payload => dispatch({
        type: savActionTypes.SEARCH_SAV_DAMAGE_LOCATION_GEOLOCATION,
        payload,
    }),
    searchSAVReverseDamageLocationGeolocation: payload => dispatch({
        type: savActionTypes.SEARCH_SAV_REVERSE_DAMAGE_LOCATION_GEOLOCATION,
        payload,
    }),
    submitSAVDamageLocationForm: payload => dispatch({
        type: savActionTypes.SUBMIT_SAV_DAMAGE_LOCATION_FORM,
        payload,
    }),
    submitSAVDamageLocationInfoForm: payload => dispatch({
        type: savActionTypes.SUBMIT_SAV_DAMAGE_LOCATION_INFO_FORM,
        payload,
    }),
    resetSAVReverseGeocodingDamageLocation: payload => dispatch({
        type: serviceCaseActionTypes.RESET_SAV_REVERSE_GEOCODING_DAMAGE_LOCATION,
        payload,
    }),
    setArcGISNearbyPOIs: payload => dispatch({
        type: serviceAssignmentActionTypes.SET_ARCGIS_NEARBY_POIS,
        payload,
    }),
    searchArcGISNearbyPOIs: payload => dispatch({
        type: serviceAssignmentActionTypes.SEARCH_ARCGIS_NEARBY_POIS,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAVDamageLocationTab));
