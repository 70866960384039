import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CommissioningRelevantPlacesPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sasd_commissioning_tab');
    const {serviceAssignment} = props;

    const formatChildrenAtSite = () => {
        if (!serviceAssignment.childrenAtSite) return '';
        let outputString = `${serviceAssignment.childrenAtSite}`;
        serviceAssignment.childrenAge.forEach((age, idx) => {
            if (idx === 0) {
                outputString = outputString.concat(' (');
            }
            outputString = idx === serviceAssignment.childrenAge.length - 1
                ? outputString.concat(`${age} ${translate('global.time_unit.year')}) ${translateTab('text.children')}`)
                : outputString.concat(`${age} ${translate('global.time_unit.year')}, `);
        });
        return outputString;
    };

    // if no service assignment, don't render
    if (!serviceAssignment) return null;

    return (
        <Panel
            title={translateTab('panel_title.relevant_places')}
            qaIdent="sasd-commissioning-relevant-places"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.vehicle_pickup_location')}
                        qaIdent="sc-vehicle-pickup-up-location-address"
                    >
                        {serviceAssignment ? serviceAssignment.serviceLocation?.formattedAddress : ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.company_location')}
                        qaIdent="sc-companion-location-address"
                    >
                        {serviceAssignment ? serviceAssignment.companionPickupLocation?.formattedAddress : ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.clinic_number')}
                        qaIdent="sa-clinic-number"
                    >
                        {serviceAssignment.clinicNumber || ''}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.destination')}
                        qaIdent="sa-destination-address"
                    >
                        {serviceAssignment.destination?.formattedAddress || ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.people_at_site')}
                        qaIdent="sc-people-at-site"
                    >
                        {serviceAssignment.grownUpsAtSite
                            ? `${serviceAssignment.grownUpsAtSite} ${translateTab('text.adults')}${serviceAssignment.childrenAtSite ? ', ' : ''}`
                            : ''}
                        {formatChildrenAtSite()}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.released_from_hospital')}
                        qaIdent="sc-is-released-from-hospital"
                    >
                        {serviceAssignment.isReleasedFromHospital
                            ? translateTab('text.yes')
                            : translateTab('text.no')}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CommissioningRelevantPlacesPanel.propTypes = {
    serviceAssignment: PropTypes.object,
};

CommissioningRelevantPlacesPanel.defaultProps = {
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CommissioningRelevantPlacesPanel));
