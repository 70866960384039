import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useHistory, resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {etmTaskStatusTypes, efServiceCaseStatusTypes, eupShiftRoleTypes, efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, ContentBlock} from '@ace-de/ui-components';
import config from '../../config';
import * as dashboardSelectors from '../dashboardSelectors';
import routePaths from '../../routePaths';
import CurrentUserWorkloadTile from './CurrentUserWorkloadTile';
import ecsAccessControl from '../../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';

const CurrentUserWorkloadPanel = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('dashboard_screen');
    const {reporterCaseAssignmentTaskCount, openServiceAssignments, openServiceAssignmentsCount} = props;
    const {memberAppServiceCases, memberAppServiceCasesCount} = props;
    const {
        countOfCases,
        countOfTasksDispatcher2,
        countOfTasksDispo,
    } = reporterCaseAssignmentTaskCount;

    const currentDate = moment();
    const hasWarning = !!Object.values(openServiceAssignments).find(assignment => {
        if (assignment.assignedAt && assignment.assignmentStatus === efServiceAssignmentStatusTypes.ASSIGNED) {
            return (moment(assignment.assignedAt).add(10, 'minutes')).isBefore(currentDate);
        }
        return false;
    }) || !!memberAppServiceCases.find(serviceCase => serviceCase.status === efServiceCaseStatusTypes.NEW);
    const memberAppServiceCasesIds = memberAppServiceCases.map(serviceCase => serviceCase.id);
    const isTaskAccessAvailable = ecsAccessControl.grantFeatureAccess(ecsFeatures.TASK, ecsFeatureActions.READ);

    return (
        <ContentBlock>
            <div className={cx('global!ace-u-grid')}>
                <CurrentUserWorkloadTile
                    status={translateScreen('current_user_workload_panel_status.case')}
                    teamMemberRole={translateScreen('current_user_workload_panel_role.emergency_call_advisor')}
                    amount={countOfCases}
                    onClick={() => history.push(resolveRoute(
                        routePaths.SERVICE_CASE_SEARCH,
                        {},
                        {search: prepareSearchQueryParams({
                            caseStatus: efServiceCaseStatusTypes.NEW,
                        })},
                    ))}
                />
                <CurrentUserWorkloadTile
                    status={translateScreen('current_user_workload_panel_status.assignment')}
                    teamMemberRole={translateScreen('current_user_workload_panel_role.dispatcher_1')}
                    amount={openServiceAssignmentsCount + memberAppServiceCasesCount}
                    onClick={() => history.push(resolveRoute(
                        routePaths.SERVICE_CASE_SEARCH,
                        {},
                        {search: prepareSearchQueryParams({
                            ...(Object.values(openServiceAssignments).length > 0 ? {
                                caseIds: [
                                    ...Object.values(openServiceAssignments)
                                        .map(openServiceAssignment => openServiceAssignment.serviceCaseId)
                                        .concat(memberAppServiceCasesIds),
                                ].join(', '),
                            } : {
                                caseStatus: efServiceCaseStatusTypes.OPEN,
                            }),
                        })},
                    ))}
                    hasWarning={hasWarning}
                />
                {!config.IS_FALLBACK_SYSTEM && isTaskAccessAvailable && (
                    <Fragment>
                        <CurrentUserWorkloadTile
                            status={translateScreen('current_user_workload_panel_status.task_dispatcher_2')}
                            teamMemberRole={translateScreen('current_user_workload_panel_role.dispatcher_2')}
                            amount={countOfTasksDispatcher2}
                            onClick={() => history.push(resolveRoute(
                                routePaths.TASK_SEARCH,
                                {},
                                {search: prepareSearchQueryParams({
                                    status: [etmTaskStatusTypes.DUE, etmTaskStatusTypes.OVERDUE],
                                    assignedTo: eupShiftRoleTypes.DISPATCHER_2,
                                })},
                            ))}
                        />
                        <CurrentUserWorkloadTile
                            status={translateScreen('current_user_workload_panel_status.task_dispo')}
                            teamMemberRole={translateScreen('current_user_workload_panel_role.dispo')}
                            amount={countOfTasksDispo}
                            onClick={() => history.push(resolveRoute(
                                routePaths.TASK_SEARCH,
                                {},
                                {search: prepareSearchQueryParams({
                                    status: [etmTaskStatusTypes.DUE, etmTaskStatusTypes.OVERDUE],
                                    assignedTo: eupShiftRoleTypes.DISPO,
                                })},
                            ))}
                        />
                    </Fragment>
                )}
            </div>
        </ContentBlock>
    );
};

CurrentUserWorkloadPanel.propTypes = {
    reporterCaseAssignmentTaskCount: PropTypes.object,
    openServiceAssignmentsCount: PropTypes.number,
    openServiceAssignments: PropTypes.object,
    memberAppServiceCases: PropTypes.array,
    memberAppServiceCasesCount: PropTypes.number,
};

CurrentUserWorkloadPanel.defaultProps = {
    reporterCaseAssignmentTaskCount: {},
    openServiceAssignmentsCount: 0,
    openServiceAssignments: {},
    memberAppServiceCases: [],
    memberAppServiceCasesCount: 0,
};


const mapStateToProps = state => {
    return {
        reporterCaseAssignmentTaskCount: dashboardSelectors.getReporterCaseAssignmentTaskCount(state),
        openServiceAssignmentsCount: dashboardSelectors.getOpenServiceAssignmentsCount(state),
        openServiceAssignments: dashboardSelectors.getOpenServiceAssignments(state),
        memberAppServiceCases: dashboardSelectors.getMemberAppServiceCases(state),
        memberAppServiceCasesCount: dashboardSelectors.getMemberAppServiceCaseCount(state),
    };
};

export default connect(mapStateToProps, null)(CurrentUserWorkloadPanel);
