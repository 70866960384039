import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, LinkedList, LinkedListItem} from '@ace-de/ui-components';
import {Form, InputHidden, TextAreaField, ButtonPrimary} from '@ace-de/ui-components/form';
import {InteractiveIcon, deleteIcon} from '@ace-de/ui-components/icons';
import {ecsFeatureActions, ecsFeatures} from '../../application/ecsFeatures';
import config from '../../config';
import * as memberSelectors from '../memberSelectors';
import * as memberActionTypes from '../memberActionTypes';
import ecsAccessControl from '../../ecsAccessControl';

const MemberNotesPanel = props => {
    const {cx} = useStyles();
    const {member, submitCreateMemberNoteForm, initiateMemberNoteDeleteFlow} = props;
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('member_screen');
    const [memberNoteContent, setMemberNoteContent] = useState('');

    const isUserAllowedToDeleteNotes = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.MEMBER_NOTE,
        ecsFeatureActions.DELETE,
    );

    const isUserAllowedToCreateNotes = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.MEMBER_NOTE,
        ecsFeatureActions.CREATE,
    );

    const isAgentIdentityAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.AGENT_IDENTITY,
        ecsFeatureActions.READ,
    );

    const handleOnSubmit = formValues => {
        if (formValues['memberNoteContent']) {
            submitCreateMemberNoteForm({
                memberId: member.id,
                content: formValues['memberNoteContent'],
            });

            setMemberNoteContent('');
        }
    };

    const handleOnChange = formValues => {
        setMemberNoteContent(formValues.memberNoteContent);
    };

    const handleDeleteMemberNote = (memberNoteId, memberId) => {
        initiateMemberNoteDeleteFlow({
            memberNoteId,
            memberId,
            shouldOpenModal: true,
        });
    };

    return (
        <Panel
            title={translateScreen('member_notes_panel.title')}
            className={cx('ace-c-panel--full-bleed-content')}
            contentClassName={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--direction-column',
            ])}
        >
            {!config.IS_FALLBACK_SYSTEM && isUserAllowedToCreateNotes && (
                <Form
                    name="memberNotesForm"
                    onSubmit={handleOnSubmit}
                    onChange={handleOnChange}
                >
                    {formValues => {
                        return (
                            <div className={cx('global!ace-u-padding--0-32-24')}>
                                <InputHidden name="memberId" value={member.id} />
                                <TextAreaField
                                    name="memberNoteContent"
                                    className={cx([
                                        'global!ace-u-flex--grow-1',
                                        'global!ace-u-full-width',
                                        'global!ace-u-padding--bottom-24',
                                    ])}
                                    label={translateScreen('member_notes_panel_text_area.label')}
                                    placeholder={
                                    translateScreen('member_notes_panel_text_area.placeholder')
                                }
                                    value={memberNoteContent}
                                    maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                                    isResizable={false}
                                />
                                <ButtonPrimary
                                    isDisabled={!formValues['memberNoteContent']}
                                    type="submit"
                                    name="submitButton"
                                >
                                    {translateScreen('member_notes_panel_button.add_note')}
                                </ButtonPrimary>
                            </div>
                        );
                    }}
                </Form>
            )}
            <LinkedList
                className={cx({'global!ace-u-margin--bottom-32': member.memberNotes.length})}
                followElement={member.memberNotes}
            >
                {member.memberNotes
                    .filter(memberNote => !memberNote.deleted)
                    .sort((memberNoteA, memberNoteB) => (memberNoteA.createdAt < memberNoteB.createdAt
                        ? 1 : memberNoteA.createdAt > memberNoteB.createdAt ? -1 : 0))
                    .map(memberNote => {
                        return (
                            <LinkedListItem
                                key={memberNote.id}
                                className={cx([
                                    {'ace-c-linked-list-item--hoverable': isUserAllowedToDeleteNotes},
                                    'global!ace-u-margin--0-32',
                                ])}
                            >
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--justify-space-between',
                                        'global!ace-u-padding--8',
                                    ])}
                                >
                                    <p
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--direction-column',
                                        ])}
                                    >
                                        <span
                                            className={cx([
                                                'global!ace-u-typography--variant-caption',
                                                'global!ace-u-margin--bottom-4',
                                            ])}
                                        >
                                            <span>
                                                {isAgentIdentityAvailable
                                                    ? `${memberNote.firstName} ${memberNote.lastName}`
                                                    : `${translate('global.user_shift_role.emergency_call_advisor')}`
                                                }
                                            </span>,
                                            {moment(memberNote.createdAt).format('DD.MM.YYYY[,] HH:mm')}
                                        </span>
                                        <span
                                            className={cx('global!ace-u-typography--variant-body')}
                                        >
                                            {memberNote.content}
                                        </span>
                                    </p>
                                    {isUserAllowedToDeleteNotes && !config.IS_FALLBACK_SYSTEM && (
                                        <InteractiveIcon
                                            icon={deleteIcon}
                                            className={cx([
                                                'ace-c-interactive-icon--primary',
                                                'global!ace-u-flex--align-self-flex-end',
                                            ])}
                                            onClick={() => handleDeleteMemberNote(memberNote.id, member.id)}
                                        />
                                    )}
                                </div>
                            </LinkedListItem>
                        );
                    })}
            </LinkedList>
        </Panel>
    );
};

MemberNotesPanel.propTypes = {
    submitCreateMemberNoteForm: PropTypes.func.isRequired,
    initiateMemberNoteDeleteFlow: PropTypes.func.isRequired,
    member: PropTypes.object,
};

MemberNotesPanel.defaultProps = {
    member: null,
};

const mapStateToProps = (state, props) => {
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();
    return {
        member: memberByMembershipNoSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitCreateMemberNoteForm: payload => dispatch({
        type: memberActionTypes.SUBMIT_CREATE_MEMBER_NOTE_FORM,
        payload,
    }),
    initiateMemberNoteDeleteFlow: payload => dispatch({
        type: memberActionTypes.INITIATE_MEMBER_NOTE_DELETE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberNotesPanel));
