import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efAdditionalServiceRequestedTypes, efRentalCarCategoryTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, DataRow, Input, Pill} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageRentalCarDataPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sarc_cost_coverage_tab');
    const {serviceAssignment, reservationNumber, onChange} = props;

    const formatAdditionalServiceRequested = () => {
        let outputString = '';
        Array.isArray(serviceAssignment.additionalServiceRequested)
            && serviceAssignment.additionalServiceRequested.forEach((service, idx) => {
                outputString = outputString.concat(`${translate(
                    `global.rental_car_additional_service_requested.${
                        efAdditionalServiceRequestedTypes[service].toLowerCase()
                    }`,
                )}`);
                if (idx !== serviceAssignment.additionalServiceRequested.length - 1) {
                    outputString = outputString.concat(', ');
                }
            });
        return outputString;
    };

    return (
        <Panel
            title={translateTab('panel_title.rental_car_data')}
            qaIdent="sarc-cost-coverage-rental-car"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.pickup_date')}
                        qaIdent="sa-pickup-date"
                    >
                        {serviceAssignment.pickupDate
                            ? `${moment(serviceAssignment.pickupDate)
                                .format('DD.MM.YYYY[,] HH:mm')} ${translate('global.time_unit.time')}` : ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.drop_off_date')}
                        qaIdent="sa-drop-off-date"
                    >
                        {serviceAssignment.dropOffDate
                            ? (
                                <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center')}>
                                    <span className={cx('global!ace-u-margin--right-8')}>
                                        {moment(serviceAssignment.dropOffDate)
                                            .format('DD.MM.YYYY[,] HH:mm')} {translate('global.time_unit.time')}
                                    </span>
                                    {serviceAssignment.navStatusHistory?.extended && (
                                        <Pill type="information">
                                            {translateTab('pill_text.extension')}
                                        </Pill>
                                    )}
                                </div>
                            ) : ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.rental_car_category')}
                        qaIdent="sa-rental-car-category"
                    >
                        {serviceAssignment.rentalCarCategory
                           && efRentalCarCategoryTypes[serviceAssignment.rentalCarCategory]
                            ? translate(`global.rental_car_category_type.${
                                efRentalCarCategoryTypes[serviceAssignment.rentalCarCategory].toLowerCase()
                            }`) : ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.additional_service_requested')}
                        qaIdent="sa-additional-service-requested"
                    >
                        {formatAdditionalServiceRequested()}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.pickup_location')}
                        qaIdent="sa-pickup-location"
                    >
                        {serviceAssignment.pickupLocation
                            ? serviceAssignment.pickupLocation.city : ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.drop_off_location')}
                        qaIdent="sa-drop-off-location"
                    >
                        {serviceAssignment.dropOffLocation
                            ? serviceAssignment.dropOffLocation.city : ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.delivery_location')}
                        qaIdent="sa-delivery-location"
                    >
                        {serviceAssignment.deliveryLocation
                            ? serviceAssignment.deliveryLocation.city : ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.reservation_number')}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="sa-reservation-number"
                    >
                        <Input
                            className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                            value={reservationNumber}
                            onChange={value => onChange('reservationNumber', value)}
                        />
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CostCoverageRentalCarDataPanel.propTypes = {
    serviceAssignment: PropTypes.object,
    reservationNumber: PropTypes.string,
    onChange: PropTypes.func,
};

CostCoverageRentalCarDataPanel.defaultProps = {
    serviceAssignment: null,
    reservationNumber: '',
    onChange: () => {},
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageRentalCarDataPanel));
