import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentTypes, efTransportationTypes, efServiceAssignmentStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import * as serviceAssignmentSelectors from '../serviceAssignmentSelectors';

const CommissioningJourneyDataPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('commissioning_journey_data_panel');
    // on the SASP, we are getting diagnose&insurance data from the latest SAMC
    const {serviceAssignment, serviceCase, newestMedicalClearanceAssignment} = props;
    const assignment = useMemo(() => {
        // On assigning, BE will patch the data on SASP, see https://computerrock.atlassian.net/browse/ACEMS-716
        if (serviceAssignment.assignmentType === efServiceAssignmentTypes.SICK_PERSON
            && ![efServiceAssignmentStatusTypes.NEW, efServiceAssignmentStatusTypes.DONE]
                .includes(serviceAssignment.assignmentStatus)
        ) {
            return serviceAssignment;
        }
        return newestMedicalClearanceAssignment || serviceAssignment || null;
    }, [serviceAssignment, newestMedicalClearanceAssignment]);

    const transportationType = useMemo(() => {
        // On assigning, BE will patch the data on SASP, see https://computerrock.atlassian.net/browse/ACEMS-716
        if (serviceAssignment.assignmentType === efServiceAssignmentTypes.SICK_PERSON
            && ![efServiceAssignmentStatusTypes.NEW, efServiceAssignmentStatusTypes.DONE]
                .includes(serviceAssignment.assignmentStatus)
        ) {
            return serviceAssignment?.transportationType;
        }
        return newestMedicalClearanceAssignment?.transportationType || serviceAssignment?.transportationType || '';
    }, [newestMedicalClearanceAssignment, serviceAssignment]);

    return (
        <Panel
            title={translatePanel('title.journey_data')}
            qaIdent="commissioning-journey-data-panel"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translatePanel('data_row_label.journey_start')}
                        qaIdent="sc-journey-start-date"
                    >
                        {assignment.journeyStartDate
                            ? moment(assignment.journeyStartDate).format('DD.MM.YYYY')
                            : ''}
                    </DataRow>
                    <DataRow
                        label={translatePanel('data_row_label.journey_end')}
                        qaIdent="sc-journey-end-date"
                    >
                        {assignment.journeyEndDate
                            ? moment(assignment.journeyEndDate).format('DD.MM.YYYY')
                            : ''}
                    </DataRow>
                    {[
                        efServiceAssignmentTypes.DEATH,
                        efServiceAssignmentTypes.MEDICINE_SHIPMENT,
                    ].includes(assignment.assignmentType) && (
                        <DataRow
                            label={translatePanel('data_row_label.journey_notes')}
                            qaIdent="sa-journey-notes"
                        >
                            {assignment.journeyNotes || ''}
                        </DataRow>
                    )}
                </div>
                <div>
                    <DataRow
                        label={translatePanel('data_row_label.transportation_type')}
                        qaIdent="sc-vehicle-type"
                    >
                        {transportationType === efTransportationTypes.PERSONAL_VEHICLE
                            ? serviceCase.vehicle?.type
                                ? translate(`global.service_assignment_vehicle_type.${serviceCase.vehicle.type.toLowerCase()}`)
                                : ''
                            : transportationType
                                ? translate(`global.service_case_personal_vehicle_type.${transportationType.toLowerCase()}`)
                                : ''}
                    </DataRow>
                    {[
                        efServiceAssignmentTypes.MEDICAL_CLEARANCE,
                        efServiceAssignmentTypes.SUBSTITUTE_DRIVER,
                    ].includes(assignment.assignmentType) && (
                        <DataRow
                            label={translatePanel('data_row_label.luggage')}
                            qaIdent="sa-luggage-amount"
                        >
                            {assignment.luggageAmount}
                        </DataRow>
                    )}
                </div>
            </div>
        </Panel>
    );
};

CommissioningJourneyDataPanel.propTypes = {
    serviceCase: PropTypes.object,
    serviceAssignment: PropTypes.object,
    newestMedicalClearanceAssignment: PropTypes.object,
};

CommissioningJourneyDataPanel.defaultProps = {
    serviceCase: null,
    serviceAssignment: null,
    newestMedicalClearanceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();

    return {
        serviceCase: serviceCaseSelector(state, props),
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CommissioningJourneyDataPanel));
