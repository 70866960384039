import modalIds from './modalIds';
import DeleteVehicleConfirmationModal from './members/modals/DeleteVehicleConfirmationModal';
import DeleteMemberNoteConfirmationModal from './members/modals/DeleteMemberNoteConfirmationModal';
import AdditionalServicesModal from './service-assignments/modals/AdditionalServicesModal';
import SCDocumentUploadModal from './service-cases/modals/SCDocumentUploadModal';
import SCDocumentDeleteConfirmationModal from './service-cases/modals/SCDocumentDeleteConfirmationModal';
import SAVServiceTypeSelectionModal from './service-assignments-vehicle/modals/SAVServiceTypeSelectionModal';
import SAVAssignmentSuccessfulModal from './service-assignments-vehicle/modals/SAVAssignmentSuccessfulModal';
import ServiceAssignmentStatusUpdateModal from './service-assignments/modals/ServiceAssignmentStatusUpdateModal';
import TaskDataModal from './tasks/modals/TaskDataModal';
import SARCUpdateACEPartnerStationModal from './service-assignments-rental-car/modals/SARCUpdateACEPartnerStationModal';
import CreatePersonServiceCaseModal from './members/modals/CreatePersonServiceCaseModal';
import CreateForeignAssistanceCaseModal from './members/modals/CreateForeignAssistanceCaseModal';
import CreateCaseLogNoteModal from './case-logs/modals/CreateCaseLogNoteModal';
import CreateEmptyCaseModal from './members/modals/CreateEmptyCaseModal';
import SendMessagesModal from './case-logs/modals/SendMessagesModal';
import CreateCaseNoMemberTariffWarningModal from './members/modals/CreateCaseNoMemberTariffWarningModal';
import SARCExtendDropOffDateModal from './service-assignments-rental-car/modals/SARCExtendDropOffDateModal';
import PowerOfAttorneySuccessfulModal from './service-assignments/modals/PowerOfAttorneySuccessfulModal';
import CreateTemporaryRestrictionSuccessModal from './contract-partners/modals/CreateTemporaryRestrictionSuccessModal';
import CloseArchiveServiceAssignmentModal from './service-assignments/modals/CloseArchiveServiceAssignmentModal';
import EditTemporaryRestrictionModal from './contract-partners/modals/EditTemporaryRestrictionModal';
import ResendServiceAssignmentModal from './service-assignments/modals/ResendServiceAssignmentModal';
import EditDocumentDescriptionModal from './service-cases/modals/EditDocumentDescriptionModal';
import UpdateCaseLogNoteModal from './case-logs/modals/UpdateCaseLogNoteModal';
import VehicleDataModal from './service-cases/modals/VehicleDataModal';
import ConfirmVehicleSelectionModal from './service-cases/modals/ConfirmVehicleSelectionModal';
import CreateQualityManagementSuccessModal from './contract-partners/modals/CreateQualityManagementNoteSuccessModal';
import CreateTemporaryRestrictionModal from './contract-partners/modals/CreateTemporaryRestrictionModal';
import CreateQualityReportModal from './contract-partners/modals/CreateQualityReportModal';
import EditMemberVehicleModal from './members/modals/EditMemberVehicleModal';
import ConfirmServiceCaseDeletionModal from './service-cases/modals/ConfirmServiceCaseDeletionModal';
import ConfirmServiceAssignmentDeletionModal from './service-assignments/modals/ConfirmServiceAssignmentDeletionModal';
import ServiceCaseDeletionStatusModal from './service-cases/modals/ServiceCaseDeletionStatusModal';
import ServiceAssignmentDeletionStatusModal from './service-assignments/modals/ServiceAssignmentDeletionStatusModal';
import AccommodationDataModal from './service-assignments-accommodation/modals/AccommodationDataModal';
import SAAExtendStayEndDateModal from './service-assignments-accommodation/modals/SAAExtendStayEndDateModal';
import ServiceAssignmentQMNotesModal from './service-assignments/modals/ServiceAssignmentQMNotesModal';
import FailedServiceErrorMessageModal from './service-assignments/modals/FailedServiceErrorMessageModal';
import ManualACEPartnerDataModal from './service-assignments/modals/ManualACEPartnerDataModal';

/*
* Modal configuration
*/
export default [
    {
        id: modalIds.DELETE_MEMBER_VEHICLE,
        component: DeleteVehicleConfirmationModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.DELETE_MEMBER_NOTE,
        component: DeleteMemberNoteConfirmationModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.TASK_DATA,
        component: TaskDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CREATE_PERSON_SERVICE_CASE,
        component: CreatePersonServiceCaseModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CREATE_FOREIGN_ASSISTANCE_CASE,
        component: CreateForeignAssistanceCaseModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.ADDITIONAL_SERVICES,
        component: AdditionalServicesModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SC_DOCUMENT_UPLOAD,
        component: SCDocumentUploadModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SC_DOCUMENT_DELETE,
        component: SCDocumentDeleteConfirmationModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SAV_SERVICE_TYPE_SELECTION,
        component: SAVServiceTypeSelectionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SERVICE_ASSIGNMENT_STATUS_UPDATE,
        component: ServiceAssignmentStatusUpdateModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SAV_ASSIGNMENT_SUCCESSFUL,
        component: SAVAssignmentSuccessfulModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SARC_UPDATE_ACE_PARTNER_STATION,
        component: SARCUpdateACEPartnerStationModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CREATE_CASE_LOG_NOTE,
        component: CreateCaseLogNoteModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.UPDATE_CASE_LOG_NOTE,
        component: UpdateCaseLogNoteModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SEND_MESSAGE,
        component: SendMessagesModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CREATE_EMPTY_CASE,
        component: CreateEmptyCaseModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CREATE_CASE_NO_TARIFF_WARNING,
        component: CreateCaseNoMemberTariffWarningModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SARC_EXTEND_DROPOFF_DATE,
        component: SARCExtendDropOffDateModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.POWER_OF_ATTORNEY_SUCCESSFUL,
        component: PowerOfAttorneySuccessfulModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_CREATE_TEMPORARY_RESTRICTION,
        component: CreateTemporaryRestrictionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_CREATE_QUALITY_REPORT,
        component: CreateQualityReportModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_TEMPORARY_RESTRICTION_SUCCESSFUL,
        component: CreateTemporaryRestrictionSuccessModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CLOSE_ARCHIVE_SERVICE_ASSIGNMENT,
        component: CloseArchiveServiceAssignmentModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_EDIT_TEMPORARY_RESTRICTION,
        component: EditTemporaryRestrictionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.RESEND_SERVICE_ASSIGNMENT,
        component: ResendServiceAssignmentModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.EDIT_DOCUMENT_DESCRIPTION,
        component: EditDocumentDescriptionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.VEHICLE_DATA,
        component: VehicleDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CONFIRM_VEHICLE_SELECTION,
        component: ConfirmVehicleSelectionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_QUALITY_MANAGEMENT_NOTE_SUCCESSFUL,
        component: CreateQualityManagementSuccessModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.EDIT_MEMBER_VEHICLE,
        component: EditMemberVehicleModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.DELETE_SERVICE_CASE,
        component: ConfirmServiceCaseDeletionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.DELETE_SERVICE_ASSIGNMENT,
        component: ConfirmServiceAssignmentDeletionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SERVICE_CASE_DELETION_STATUS,
        component: ServiceCaseDeletionStatusModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SERVICE_ASSIGNMENT_DELETION_STATUS,
        component: ServiceAssignmentDeletionStatusModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.ACCOMMODATION_DATA_MODAL,
        component: AccommodationDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SAA_EXTEND_STAY_END_DATE,
        component: SAAExtendStayEndDateModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SERVICE_ASSIGNMENT_QM_NOTES,
        component: ServiceAssignmentQMNotesModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.FAILED_SERVICE_ERROR_MESSAGE,
        component: FailedServiceErrorMessageModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.ACE_PARTNER_DATA,
        component: ManualACEPartnerDataModal,
        hasBackdrop: true,
    },
];
