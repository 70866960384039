import React, {useState, useRef, useEffect, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {snakeCase, camelCase} from 'change-case';
import debounce from 'lodash.debounce';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMemberStatusTypes, ampMemberSalutationTypes, persistenceStates, europeanCountries, ampMemberSexTypes, ampMemberTariffGroups, ampMemberTariffAdditionalPackageTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, DataRow, Badge, AutocompleteField} from '@ace-de/ui-components';
import {Form, Input, InputField, TextArea, Select, Option, ButtonPrimary, AutosuggestField} from '@ace-de/ui-components/form';
import {InteractiveIcon, Icon, warningAlertIcon, editIcon, closeIcon, arrowDownIcon, locationIcon, warningIcon} from '@ace-de/ui-components/icons';
import config from '../../config';
import * as memberActionTypes from '../memberActionTypes';
import * as memberSelectors from '../memberSelectors';
import {ecsFeatures, ecsFeatureActions} from '../../application/ecsFeatures';
import ecsAccessControl from '../../ecsAccessControl';
import getPrice from '../../utils/getPrice';
import {validateEmail} from '../../utils/validation';

const MemberDataPrivatePanel = props => {
    const {cx} = useStyles();
    const {member, submitUpdateMemberForm, searchMemberAddressGeolocation} = props;
    const {selectMemberAddressGeolocation, setMemberAddressGeolocationSearchError} = props;
    const [isEditModeActive, setEditMode] = useState(false);
    const [countryCode, setCountryCode] = useState(member.personalDetails.address.country || '');
    const [newMemberAddress, setNewMemberAddress] = useState({
        street: member.personalDetails.address.street || '',
        postCode: member.personalDetails.address.postCode || '',
        district: member.personalDetails.address.district || '',
        city: member.personalDetails.address.city || '',
        formattedAddress: member.personalDetails.address.parsedFormattedAddress || '',
    });
    const [inputAddressErrorMessage, setInputAddressErrorMessage] = useState('');
    const [invalidAddressErrorMessage, setInvalidAddressErrorMessage] = useState('');
    const [invalidCountryErrorMessage, setInvalidCountryErrorMessage] = useState('');
    const [invalidEmailErrorMessage, setInvalidEmailErrorMessage] = useState('');
    const [isInvalidAddress, setIsInvalidAddress] = useState(false);
    const prevPersistenceState = useRef(persistenceStates.UNKNOWN);
    const currentMemberAddress = useRef('');
    const lastMemberSearchQuery = useRef('');
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateScreen = createTranslateShorthand('member_screen');
    const sortedCountries = Object.entries(europeanCountries)
        .map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ])
        .sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            if (countryCodeA === 'DE') return -1;
            if (countryCodeB === 'DE') return 1;
            return countryA.name.localeCompare(countryB.name);
        });
    const searchMemberAddressGeolocationDebounced = useMemo(
        () => debounce(searchMemberAddressGeolocation, config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER),
        [searchMemberAddressGeolocation],
    );

    const isMemberEditingAvailable = ecsAccessControl.grantFeatureAccess(
        ecsFeatures.MEMBER_DATA,
        ecsFeatureActions.UPDATE,
    );

    const toggleEditMode = useCallback(() => {
        if (isEditModeActive) {
            setCountryCode(member?.personalDetails.address.country || '');
        }
        setEditMode(prevState => !prevState);
        setInputAddressErrorMessage('');
        setIsInvalidAddress(false);
        setInvalidCountryErrorMessage('');
        setInvalidEmailErrorMessage('');
        lastMemberSearchQuery.current = '';
        currentMemberAddress.current = '';
    }, [member, isEditModeActive]);

    // On persistence state change effects
    useEffect(() => {
        if (!member) return;

        if (prevPersistenceState.current === persistenceStates.SUCCEEDED
            && member.persistenceState === persistenceStates.READY) {
            toggleEditMode();
        }

        if (prevPersistenceState.current !== member.persistenceState) {
            prevPersistenceState.current = member.persistenceState;
        }

        if (!isEditModeActive) {
            setNewMemberAddress({
                street: member.personalDetails.address.street || '',
                postCode: member.personalDetails.address.postCode || '',
                district: member.personalDetails.address.district || '',
                city: member.personalDetails.address.city || '',
                formattedAddress: member.personalDetails.address.parsedFormattedAddress || '',
            });
        }
    }, [member, isEditModeActive, toggleEditMode]);

    useEffect(() => {
        if (!member || !isEditModeActive) return;

        setInputAddressErrorMessage(prevErrorMessage => (member.memberAddressSearchError
            ? translateScreen('membership_data_panel_error_private.server_error') : prevErrorMessage));
    }, [member, isEditModeActive, translateScreen]);

    // if no member don't render
    if (!member) return null;

    // styling
    const formFieldContentClassName = cx([
        'global!ace-u-flex',
        'global!ace-u-flex--direction-column',
        'global!ace-u-flex--align-stretch',
    ]);

    // if no member don't render
    if (!member) return null;

    const handleOnSubmit = memberData => {
        if (inputAddressErrorMessage && !member.memberAddressSearchError) return;

        // ensure `name` property is auto-handled by Member.PersonalDetails entity when patching
        memberData['personalDetails']['name'] = '';
        // validate country field: ensure 'country' has been selected from the dropdown
        if ((memberData['personalDetails']?.['address']?.['country'] && !countryCode)
            || (memberData['personalDetails']?.['address']?.['country'] && countryCode
                && memberData['personalDetails']?.['address']?.['country'] !== countryCode)
        ) {
            setInvalidCountryErrorMessage(translateScreen('membership_data_panel_error_private.invalid_country'));
            return;
        }
        // reset invalid country error
        if (invalidCountryErrorMessage) {
            setInvalidCountryErrorMessage('');
        }
        // trim and validate email field
        if (memberData['contactDetails']?.['email']) {
            memberData['contactDetails']['email'] = memberData['contactDetails']['email']?.trim() || '';
        }
        if (memberData['contactDetails']?.['email'] && !validateEmail(memberData['contactDetails']['email'])) {
            setInvalidEmailErrorMessage(translateScreen('membership_data_panel_error_private.invalid_email'));
            return;
        }
        // reset invalid email error
        if (invalidEmailErrorMessage) {
            setInvalidEmailErrorMessage('');
        }
        // Note: insert country only if picked from the list
        if (memberData['personalDetails']?.['address']?.['country']) {
            memberData['personalDetails']['address']['country'] = countryCode;
        }
        memberData['status'] = ampMemberStatusTypes[member.status];
        if (memberData['personalDetails']?.['address']) {
            memberData['personalDetails']['address']['street'] = newMemberAddress.street;
            memberData['personalDetails']['address']['postCode'] = newMemberAddress.postCode;
            memberData['personalDetails']['address']['district'] = newMemberAddress.district;
            memberData['personalDetails']['address']['city'] = newMemberAddress.city;
        }
        submitUpdateMemberForm({memberId: member.id, memberData});

        setInvalidAddressErrorMessage(isInvalidAddress
            ? translateScreen('membership_data_panel_error_private.invalid_address')
            : '');

        setMemberAddressGeolocationSearchError({
            hasError: false,
            memberId: member.id,
        });

        toggleEditMode();
    };

    const validateAddress = address => {
        if (!address.formattedAddress) {
            setInputAddressErrorMessage(translateScreen('membership_data_panel_input_error_private.choose_option'));
            return;
        }

        setInputAddressErrorMessage('');
    };

    const handleAddressMemberSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentMemberAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH
        ) {
            searchMemberAddressGeolocationDebounced({
                searchQueryString,
                memberId: member.id,
            });
        }
        if (!searchQueryString) {
            setNewMemberAddress({
                street: '',
                postCode: '',
                district: '',
                city: '',
                formattedAddress: '',
            });
            setIsInvalidAddress(true);
        }

        lastMemberSearchQuery.current = searchQueryString || '';
    };

    const handleMemberAddressCandidateSelect = locationCandidate => {
        currentMemberAddress.current = locationCandidate.formattedAddress;
        lastMemberSearchQuery.current = locationCandidate.formattedAddress;

        validateAddress(locationCandidate);

        const isSelectionInvalid = !locationCandidate.street || !locationCandidate.postCode || !locationCandidate.city;
        setIsInvalidAddress(isSelectionInvalid);

        setNewMemberAddress({
            street: !isSelectionInvalid ? locationCandidate.street : locationCandidate.formattedAddress,
            postCode: !isSelectionInvalid ? locationCandidate.postCode : '',
            district: !isSelectionInvalid ? locationCandidate.district : '',
            city: !isSelectionInvalid ? locationCandidate.city : '',
            formattedAddress: locationCandidate.formattedAddress,
        });

        selectMemberAddressGeolocation({
            uuid: locationCandidate.uuid,
        });
    };

    const handleMemberAddressOutsideClick = () => {
        if (member.memberAddressSearchError) return;

        if (!lastMemberSearchQuery.current) {
            setInputAddressErrorMessage('');
            return;
        }
        if (lastMemberSearchQuery.current === newMemberAddress.formattedAddress) {
            validateAddress(newMemberAddress);
            return;
        }
        setInputAddressErrorMessage(translateScreen('membership_data_panel_input_error_private.choose_option'));
    };

    const {personalDetails, contactDetails, tariffDetails, memberAddressSearchQuery, memberAddressCandidates} = member;
    const {address} = personalDetails;
    const memberBirthday = personalDetails.birthday ? moment(personalDetails.birthday).format('DD.MM.YYYY') : '';
    const membershipBeginDate = member.beginMembership ? moment(member.beginMembership).format('DD.MM.YYYY') : '';
    const membershipEndDate = member.endMembership ? moment(member.endMembership).format('DD.MM.YYYY') : '-';
    const isMembershipEnded = member.endMembership ? moment(member.endMembership).isSameOrBefore(moment()) : false;
    const memberSalutation = translate(`member_screen.membership_data_panel_option_private.salutation_${personalDetails.salutation.toLowerCase()}`);
    const additionalPackages = tariffDetails?.additionalPackages
        ? tariffDetails.additionalPackages.reduce((prev, curr) => prev + (prev === '' ? '' : ', ')
        + translate(`global.member_tariff_additional_package.${snakeCase(ampMemberTariffAdditionalPackageTypes[curr])}`), '')
        : '';

    return (
        <Panel
            title={translateScreen('membership_data_panel_private.title')}
            qaIdent="member-data-private"
            actions={(!config.IS_FALLBACK_SYSTEM && isMemberEditingAvailable) && (!isEditModeActive ? (
                <InteractiveIcon
                    icon={editIcon}
                    onClick={toggleEditMode}
                />
            ) : (
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={toggleEditMode}
                />
            ))}
        >
            {!isEditModeActive ? (
                <div className={cx('global!ace-u-two-column-layout')}>
                    <div>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.salutation')}
                            qaIdent="member-personal-details-salutation"
                        >
                            {personalDetails.salutation
                                ? memberSalutation
                                : personalDetails.salutation}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.surname')}
                            qaIdent="member-personal-details-surname"
                        >
                            {personalDetails.surname}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.first_name')}
                            qaIdent="member-personal-details-first-name"
                        >
                            {`${personalDetails.firstName} ${personalDetails.middleName}`}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.sex')}
                            qaIdent="member-personal-details-sex"
                        >
                            {translate(`global.member_sex.${snakeCase(ampMemberSexTypes[personalDetails.sex])}`)}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.address')}
                            qaIdent="member-address"
                            contentClassName={formFieldContentClassName}
                        >
                            {address.displayAddress}
                            {invalidAddressErrorMessage && (
                                <p
                                    className={cx([
                                        'global!ace-u-typography--color-warning',
                                        'global!ace-u-typography--variant-caption',
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-flex-start',
                                    ])}
                                >
                                    <Icon
                                        icon={warningIcon}
                                        className={cx([
                                            'global!ace-u-margin--right-8',
                                            'ace-c-icon--s',
                                            'ace-c-icon--color-warning',
                                        ])}
                                    />
                                    <span>{invalidAddressErrorMessage}</span>
                                </p>
                            )}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.phone_number')}
                            qaIdent="member-contact-details-phone"
                        >
                            {contactDetails.phoneNo}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.email')}
                            qaIdent="member-contact-details-email"
                        >
                            {contactDetails.email}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.membership_begin_date')}
                            qaIdent="member-membership-begin-date"
                        >
                            {membershipBeginDate}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.membership_end_date')}
                            qaIdent="member-membership-end-date"
                        >
                            <span className={cx({
                                'global!ace-u-typography--color-warning': isMembershipEnded,
                                'global!ace-u-typography--variant-body-bold': isMembershipEnded,
                            })}
                            >
                                {membershipEndDate}
                            </span>
                        </DataRow>
                    </div>
                    <div>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.membership_no')}
                            qaIdent="member-membership-number"
                        >
                            {member.membershipNo}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.birthday')}
                            qaIdent="member-birthday"
                        >
                            {memberBirthday}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.current_tariff')}
                            qaIdent="member-tariff-group"
                        >
                            {tariffDetails.tariffGroup
                                ? translate(`global.member_tariff_group.${snakeCase(ampMemberTariffGroups[tariffDetails?.tariffGroup])}`)
                                : ''
                            }
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.tariff_description')}
                            qaIdent="member-tariff-description"
                        >
                            {additionalPackages}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.status')}
                            qaIdent="member-status"
                        >
                            {member.status !== ampMemberStatusTypes.EMPTY && (
                                <Badge status={member.status.toLowerCase()}>
                                    {translate(`global.member_status.${snakeCase(ampMemberStatusTypes[member.status])}`)}
                                </Badge>
                            )}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.dunning_level')}
                            qaIdent="member-dunning-level"
                        >
                            <p
                                className={cx({
                                    'global!ace-u-typography--color-warning': member.dunningLevel
                                        >= config.WARNING_DUNNING_LEVEL,
                                })}
                            >
                                {member.dunningLevel}
                            </p>
                            {member.dunningLevel >= config.WARNING_DUNNING_LEVEL && (
                                <Icon
                                    icon={warningAlertIcon}
                                    className={cx('ace-c-icon--color-warning', 'global!ace-u-margin--left-16')}
                                />
                            )}
                        </DataRow>
                        <DataRow
                            label={translateScreen('membership_data_panel_input_label_private.balance_member_fee')}
                            qaIdent="member-tariff-details-balance"
                        >
                            {getPrice(tariffDetails.balanceMemberFee, activeLocale)}
                        </DataRow>
                    </div>
                </div>
            ) : (
                <Form name="updateMember" onSubmit={handleOnSubmit}>
                    <div className={cx('global!ace-u-two-column-layout')}>
                        <div>
                            <Form name="personalDetails">
                                <DataRow
                                    label={translateScreen('membership_data_panel_input_label_private.salutation')}
                                    qaIdent="member-personal-details-salutation"
                                    className={cx('ace-c-data-row--condensed')}
                                    contentClassName={formFieldContentClassName}
                                >
                                    <Select
                                        className={cx('ace-c-select--small')}
                                        name="salutation"
                                        value={personalDetails.salutation}
                                        placeholder={translate('global.select.placeholder')}
                                    >
                                        {Object.keys(ampMemberSalutationTypes).map(salutation => {
                                            return (
                                                <Option
                                                    key={`salutation${camelCase(salutation)}`}
                                                    name={`salutation${camelCase(salutation)}`}
                                                    value={ampMemberSalutationTypes[salutation]}
                                                >
                                                    {translateScreen(`membership_data_panel_option_private.salutation_${snakeCase(salutation)}`)}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </DataRow>
                                <DataRow
                                    label={translateScreen('membership_data_panel_input_label_private.surname')}
                                    qaIdent="member-personal-details-surname"
                                    className={cx('ace-c-data-row--condensed')}
                                    contentClassName={formFieldContentClassName}
                                >
                                    <Input
                                        name="surname"
                                        value={personalDetails.surname}
                                        className={cx('ace-c-input--small')}
                                    />
                                </DataRow>
                                <DataRow
                                    label={translateScreen('membership_data_panel_input_label_private.first_name')}
                                    qaIdent="member-personal-details-first-name"
                                    className={cx('ace-c-data-row--condensed')}
                                    contentClassName={cx('global!ace-u-flex')}
                                >
                                    <Input
                                        name="firstName"
                                        value={personalDetails.firstName}
                                        className={cx([
                                            'ace-c-input--small',
                                            'global!ace-u-margin--right-16',
                                            'global!ace-u-full-width',
                                        ])}
                                    />
                                    <Input
                                        name="middleName"
                                        value={personalDetails.middleName}
                                        placeholder="Zweiter Vorname"
                                        className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                    />
                                </DataRow>
                                <DataRow
                                    label={translateScreen('membership_data_panel_input_label_private.sex')}
                                    qaIdent="member-personal-details-sex"
                                    className={cx('ace-c-data-row--condensed')}
                                    contentClassName={formFieldContentClassName}
                                >
                                    <Select
                                        className={cx('ace-c-select--small')}
                                        name="sex"
                                        value={personalDetails.sex}
                                        placeholder={translate('global.select.placeholder')}
                                    >
                                        {Object.keys(ampMemberSexTypes).map((sex, idx) => {
                                            return (
                                                <Option
                                                    key={`sex${idx}`}
                                                    name={`sex${camelCase(sex)}`}
                                                    value={sex}
                                                >
                                                    {translate(`global.member_sex.${snakeCase(sex)}`)}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </DataRow>
                                <Form name="address">
                                    {formValues => (
                                        <>
                                            <DataRow
                                                label={translateScreen('membership_data_panel_input_label_private.address')}
                                                qaIdent="member-address-street"
                                                className={cx('ace-c-data-row--condensed')}
                                                contentClassName={formFieldContentClassName}
                                            >
                                                <AutosuggestField
                                                    name="memberAddressSearchQuery"
                                                    value={newMemberAddress.formattedAddress || ''}
                                                    optionValueSelector={addressCandidate => {
                                                        return addressCandidate.formattedAddress;
                                                    }}
                                                    onChange={handleAddressMemberSearchQueryChange}
                                                    onOptionSelect={handleMemberAddressCandidateSelect}
                                                    onBlur={handleMemberAddressOutsideClick}
                                                    errors={inputAddressErrorMessage ? [inputAddressErrorMessage] : []}
                                                >
                                                    {(formValues['memberAddressSearchQuery'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                                    && memberAddressSearchQuery === lastMemberSearchQuery.current
                                                        ? memberAddressCandidates
                                                            .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                            .map((locationCandidate, index) => {
                                                                return (
                                                                    <Option
                                                                        key={index}
                                                                        name={`member-address-candidate-${index}`}
                                                                        value={locationCandidate}
                                                                    >
                                                                        <Icon
                                                                            icon={locationIcon}
                                                                            className={cx('global!ace-u-margin--right-16')}
                                                                        />
                                                                        {locationCandidate.formattedAddress}
                                                                    </Option>
                                                                );
                                                            }) : null}
                                                </AutosuggestField>
                                            </DataRow>
                                            <DataRow
                                                label={translateScreen('membership_data_panel_input_label_private.country')}
                                                qaIdent="member-address-country"
                                                className={cx('ace-c-data-row--condensed')}
                                                contentClassName={formFieldContentClassName}
                                            >
                                                <AutocompleteField
                                                    name="country"
                                                    value={address.country}
                                                    placeholder={translate('global.select.placeholder')}
                                                    className={cx('ace-c-select--small')}
                                                    onOptionSelect={value => setCountryCode(value)}
                                                    icon={arrowDownIcon}
                                                    errors={invalidCountryErrorMessage
                                                        ? [invalidCountryErrorMessage] : []}
                                                >
                                                    {sortedCountries
                                                        .map(([countryCode, country]) => {
                                                            return (
                                                                <Option
                                                                    key={country.id}
                                                                    name={`country-${country.id}`}
                                                                    value={countryCode}
                                                                    className={cx('ace-c-option--small')}
                                                                >
                                                                    {country.name}
                                                                </Option>
                                                            );
                                                        })}
                                                </AutocompleteField>
                                            </DataRow>
                                        </>
                                    )}
                                </Form>
                            </Form>
                            <Form name="contactDetails">
                                <DataRow
                                    label={translateScreen('membership_data_panel_input_label_private.phone_number')}
                                    qaIdent="member-contact-details-phone"
                                    className={cx('ace-c-data-row--condensed')}
                                    contentClassName={formFieldContentClassName}
                                >
                                    <Input
                                        name="phoneNo"
                                        value={contactDetails.phoneNo}
                                        className={cx('ace-c-input--small')}
                                    />
                                </DataRow>
                                <DataRow
                                    label={translateScreen('membership_data_panel_input_label_private.email')}
                                    qaIdent="member-contact-details-email"
                                    className={cx('ace-c-data-row--condensed')}
                                    contentClassName={formFieldContentClassName}
                                >
                                    <InputField
                                        name="email"
                                        value={contactDetails.email}
                                        className={cx('ace-c-input--small')}
                                        errors={invalidEmailErrorMessage ? [invalidEmailErrorMessage] : []}
                                    />
                                </DataRow>
                            </Form>
                            <DataRow
                                label={translateScreen('membership_data_panel_input_label_private.membership_begin_date')}
                                qaIdent="member-membership-dates"
                                className={cx('ace-c-data-row--condensed')}
                                contentClassName={formFieldContentClassName}
                            >
                                <Input
                                    name="beginMembership"
                                    value={membershipBeginDate}
                                    isDisabled={true}
                                    className={cx('ace-c-input--small')}
                                />
                            </DataRow>
                            <DataRow
                                label={translateScreen('membership_data_panel_input_label_private.membership_end_date')}
                                qaIdent="member-membership-end-date"
                                className={cx('ace-c-data-row--condensed')}
                                contentClassName={formFieldContentClassName}
                            >
                                <Input
                                    name="membershipEndDate"
                                    value={membershipEndDate}
                                    isDisabled={true}
                                    className={cx('ace-c-input--small')}
                                />
                            </DataRow>
                        </div>
                        <div>
                            <DataRow
                                label={translateScreen('membership_data_panel_input_label_private.membership_no')}
                                qaIdent="member-membership-number"
                                className={cx('ace-c-data-row--condensed')}
                                contentClassName={formFieldContentClassName}
                            >
                                <Input
                                    name="membershipNo"
                                    value={member.membershipNo}
                                    isDisabled={true}
                                    className={cx('ace-c-input--small')}
                                />
                            </DataRow>
                            <DataRow
                                label={translateScreen('membership_data_panel_input_label_private.birthday')}
                                qaIdent="member-birthday"
                                className={cx('ace-c-data-row--condensed')}
                                contentClassName={formFieldContentClassName}
                            >
                                <Input
                                    name="birthday"
                                    value={memberBirthday}
                                    isDisabled={true}
                                    className={cx('ace-c-input--small')}
                                />
                            </DataRow>
                            <Form name="tariffDetails">
                                <DataRow
                                    label={translateScreen('membership_data_panel_input_label_private.current_tariff')}
                                    qaIdent="member-tariff-group"
                                    className={cx('ace-c-data-row--condensed')}
                                    contentClassName={formFieldContentClassName}
                                >
                                    <Input
                                        name="currentTariff"
                                        value={
                                            tariffDetails.tariffGroup
                                                ? translate(`global.member_tariff_group.${snakeCase(ampMemberTariffGroups[tariffDetails.tariffGroup])}`)
                                                : ''
                                        }
                                        isDisabled={true}
                                        className={cx('ace-c-input--small')}
                                    />
                                </DataRow>
                                <DataRow
                                    label={translateScreen('membership_data_panel_input_label_private.tariff_description')}
                                    qaIdent="member-tariff-description"
                                    className={cx('ace-c-data-row--condensed')}
                                    contentClassName={formFieldContentClassName}
                                >
                                    <TextArea
                                        name="tariffDescription"
                                        value={additionalPackages}
                                        isResizable={false}
                                        isDisabled={true}
                                        className={cx('ace-c-text-area--small')}
                                    />
                                </DataRow>
                                <DataRow
                                    label={translateScreen('membership_data_panel_input_label_private.balance_member_fee')}
                                    qaIdent="member-tariff-details-balance"
                                    className={cx('ace-c-data-row--condensed')}
                                    contentClassName={formFieldContentClassName}
                                >
                                    <Input
                                        name="balanceMemberFee"
                                        value={tariffDetails.balanceMemberFee}
                                        isDisabled={true}
                                        className={cx('ace-c-input--small')}
                                    />
                                </DataRow>
                            </Form>
                            <DataRow
                                label={translateScreen('membership_data_panel_input_label_private.status')}
                                qaIdent="member-status"
                                className={cx('ace-c-data-row--condensed')}
                                contentClassName={formFieldContentClassName}
                            >
                                <Select
                                    name="status"
                                    value={member.status}
                                    isDisabled={true}
                                    className={cx('ace-c-select--small')}
                                >
                                    {Object.values(ampMemberStatusTypes).map(ampMemberStatusType => {
                                        return (
                                            <Option
                                                key={`memberStatus${camelCase(ampMemberStatusType)}`}
                                                name={`status${camelCase(ampMemberStatusType)}`}
                                                value={ampMemberStatusType}
                                            >
                                                {translate(`global.member_status.${snakeCase(ampMemberStatusType)}`)}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </DataRow>
                            <DataRow
                                label={translateScreen('membership_data_panel_input_label_private.dunning_level')}
                                qaIdent="member-dunning-level"
                                className={cx('ace-c-data-row--condensed')}
                                contentClassName={formFieldContentClassName}
                            >
                                <Input
                                    name="dunningLevel"
                                    value={member.dunningLevel}
                                    isDisabled={true}
                                    className={cx('ace-c-input--small')}
                                />
                            </DataRow>
                        </div>
                    </div>
                    <div className={cx('global!ace-u-flex', 'global!ace-u-flex--justify-flex-end')}>
                        <ButtonPrimary name="submitMemberData" type="submit">
                            {translateScreen('membership_data_panel_button_private.save_changes')}
                        </ButtonPrimary>
                    </div>
                </Form>
            )}
        </Panel>
    );
};

MemberDataPrivatePanel.propTypes = {
    submitUpdateMemberForm: PropTypes.func.isRequired,
    member: PropTypes.object,
    searchMemberAddressGeolocation: PropTypes.func.isRequired,
    selectMemberAddressGeolocation: PropTypes.func.isRequired,
    setMemberAddressGeolocationSearchError: PropTypes.func.isRequired,
};

MemberDataPrivatePanel.defaultProps = {
    member: null,
};

const mapStateToProps = (state, props) => {
    const memberByMembershipNoSelector = memberSelectors.createMemberByMembershipNoSelector();
    return {
        member: memberByMembershipNoSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitUpdateMemberForm: payload => dispatch({type: memberActionTypes.SUBMIT_UPDATE_MEMBER_FORM, payload}),
    searchMemberAddressGeolocation: payload => dispatch({
        type: memberActionTypes.SEARCH_MEMBER_ADDRESS_GEOLOCATION,
        payload,
    }),
    selectMemberAddressGeolocation: payload => dispatch({
        type: memberActionTypes.SELECT_MEMBER_ADDRESS_GEOLOCATION,
        payload,
    }),
    setMemberAddressGeolocationSearchError: payload => dispatch({
        type: memberActionTypes.SET_MEMBER_ADDRESS_CANDIDATES_SEARCH_ERROR,
        payload,
    }),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(MemberDataPrivatePanel));
