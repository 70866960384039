import {take, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import updateServiceCase from './updateServiceCase';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';

/**
 * Service case document delete flow (uses modal)
 */
const scDocumentDeleteFlow = function* scDocumentDeleteFlow() {
    while (true) {
        const {payload} = yield take([serviceCaseActionTypes.INITIATE_SC_DOCUMENT_DELETE_FLOW]);
        const {serviceCaseId, attachmentId} = payload;

        yield* openModal(modalIds.SC_DOCUMENT_DELETE);
        const scDocumentDeleteAction = yield take([
            serviceCaseActionTypes.CONFIRM_SC_DOCUMENT_DELETE,
            serviceCaseActionTypes.DECLINE_SC_DOCUMENT_DELETE,
        ]);

        if (scDocumentDeleteAction.type === serviceCaseActionTypes.DECLINE_SC_DOCUMENT_DELETE) {
            yield* closeModal(modalIds.SC_DOCUMENT_DELETE);
            continue;
        }

        const {serviceCases} = yield select(state => state.serviceCases);
        const serviceCase = serviceCases[serviceCaseId];
        yield* updateServiceCase({
            caller: serviceCaseActionTypes.CONFIRM_SC_DOCUMENT_DELETE,
            caseType: serviceCase.caseType,
            serviceCaseId,
            serviceCaseData: {
                attachments: serviceCase.attachments
                    .filter(attachment => attachment.id !== attachmentId),
            },
        });

        yield* closeModal(modalIds.SC_DOCUMENT_DELETE);
    }
};

export default scDocumentDeleteFlow;
