import {put, fork, select, take} from 'redux-saga/effects';
import {etmTaskStatusTypes, efServiceCaseStatusTypes, efServiceAssignmentStatusTypes, eupShiftRoleTypes, efServiceCaseContactChannelTypes, efServiceAssignmentTypes, etmTaskCategoryTypes} from '@ace-de/eua-entity-types';
import * as userProfileActionTypes from '../user-profiles/userProfileActionTypes';
import * as serviceCaseActionTypes from '../service-cases/serviceCaseActionTypes';
import * as dashboardActionTypes from './dashboardActionTypes';
import * as taskActionTypes from '../tasks/taskActionTypes';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import * as serviceAssignmentActionTypes from '../service-assignments/serviceAssignmentActionTypes';
import fetchRequest from '../application/sagas/fetchRequest';
import config from '../config';
import ecsAccessControl from '../ecsAccessControl';
import {ecsFeatures, ecsFeatureActions} from '../application/ecsFeatures';

export const loadLoggedInUsers = function* loadLoggedInUsers() {
    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');

    yield fork(fetchRequest, userProfileActionTypes.FETCH_LOGGED_IN_USERS_REQUEST, userProfileService.getLoggedInUsers);

    const loggedUserResponseAction = yield take([
        userProfileActionTypes.FETCH_LOGGED_IN_USERS_REQUEST_SUCCEEDED,
        userProfileActionTypes.FETCH_LOGGED_IN_USERS_REQUEST_FAILED,
    ]);

    if (!loggedUserResponseAction.error) {
        const {response} = loggedUserResponseAction.payload;
        const {userLoggedInDTOs} = response;

        yield put({
            type: dashboardActionTypes.STORE_CURRENTLY_ACTIVE_TEAM,
            payload: {userLoggedInDTOs},
        });
    }
};

export const loadCurrentUsersTasks = function* loadCurrentUsersTasks() {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');
    const userProfile = yield select(userProfileSelectors.getUser);
    const isTaskFeatureAvailable = ecsAccessControl.grantFeatureAccess(ecsFeatures.TASK, ecsFeatureActions.READ);

    // TODO GRANT FEATURE ACCESS
    if (config.IS_FALLBACK_SYSTEM || !isTaskFeatureAvailable) return;

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('assignedTo', userProfile.shiftRole);
    searchQueryParams.append('assignedTo', userProfile.id);
    searchQueryParams.append('status', etmTaskStatusTypes.DUE);
    searchQueryParams.append('status', etmTaskStatusTypes.OVERDUE);
    Object.keys(etmTaskCategoryTypes)
        .filter(category => category !== etmTaskCategoryTypes.TODO)
        .forEach(category => {
            searchQueryParams.append('category', category);
        });
    searchQueryParams.append('sort', 'dueDate,asc');
    searchQueryParams.append('size', 10);

    yield fork(
        fetchRequest,
        taskActionTypes.FETCH_CURRENT_USERS_TASKS_REQUEST,
        ecsTaskManagementService.getTasks,
        {
            searchQueryParams,
        },
    );

    const responseAction = yield take([
        taskActionTypes.FETCH_CURRENT_USERS_TASKS_REQUEST_SUCCEEDED,
        taskActionTypes.FETCH_CURRENT_USERS_TASKS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {taskDTOs} = response;

        yield put({
            type: dashboardActionTypes.STORE_CURRENT_USERS_TASKS,
            payload: {taskDTOs},
        });

        yield put({
            type: taskActionTypes.STORE_TASKS,
            payload: {taskDTOs},
        });
    }
};

export const loadCurrentUserWorkload = function* loadCurrentUserWorkload() {
    const {serviceManager} = yield select(state => state.application);
    const userProfile = yield select(userProfileSelectors.getUser);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');
    const isTaskFeatureAvailable = ecsAccessControl.grantFeatureAccess(ecsFeatures.TASK, ecsFeatureActions.READ);

    if (!userProfile) return;

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_REPORTER_CASES_COUNT_REQUEST,
        ecsFlowService.getReporterCasesCount,
        {caseStatus: efServiceCaseStatusTypes.NEW},
    );
    const efResponseAction = yield take([
        serviceCaseActionTypes.FETCH_REPORTER_CASES_COUNT_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_REPORTER_CASES_COUNT_REQUEST_FAILED,
    ]);

    if (!config.IS_FALLBACK_SYSTEM && isTaskFeatureAvailable) {
        yield fork(
            fetchRequest,
            taskActionTypes.FETCH_REPORTER_TASKS_COUNT_REQUEST,
            ecsTaskManagementService.getReporterTasksCount,
            {
                taskStatuses: [etmTaskStatusTypes.DUE, etmTaskStatusTypes.OVERDUE],
            },
        );
        const etmResponseAction = yield take([
            taskActionTypes.FETCH_REPORTER_TASKS_COUNT_REQUEST_SUCCEEDED,
            taskActionTypes.FETCH_REPORTER_TASKS_COUNT_REQUEST_FAILED,
        ]);

        if (!etmResponseAction.error) {
            const {response} = etmResponseAction.payload;
            const {reporterTasksCountDTO} = response;

            yield put({
                type: dashboardActionTypes.STORE_REPORTER_CASES_TASKS_COUNT,
                payload: {reporterTasksCountDTO},
            });
        }
    }


    if (!efResponseAction.error) {
        const {response} = efResponseAction.payload;
        const {reporterCasesCountDTO} = response;

        yield put({
            type: dashboardActionTypes.STORE_REPORTER_CASES_TASKS_COUNT,
            payload: {reporterCasesCountDTO},
        });
    }
};

export const loadForeignAssistanceCases = function* loadForeignAssistanceCases() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('sort', 'emergencyCallDateTime,desc');
    searchQueryParams.append('caseStatus', efServiceCaseStatusTypes.NEW);
    searchQueryParams.append('caseStatus', efServiceCaseStatusTypes.OPEN);
    searchQueryParams.append('size', 5);
    searchQueryParams.append('commissionerId', `ne|${config.ACE_COMMISSIONER_ID}`); // foreign cases, non ACE
    searchQueryParams.append('damageLocationCountry', `ne|${config.DAMAGE_LOCATION_GERMANY}`); // foreign cases, non germany damage location

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST,
        ecsFlowService.searchServiceCases,
        {searchQueryParams},
    );

    const searchServiceCasesResponseAction = yield take([
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.SEARCH_SERVICE_CASES_REQUEST_FAILED,
    ]);

    if (!searchServiceCasesResponseAction.error) {
        const {response} = searchServiceCasesResponseAction.payload;
        const {results: serviceCaseSearchResults, totalCount: serviceCaseSearchResultsCount} = response;

        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS,
            payload: {serviceCaseSearchResults, serviceCaseSearchResultsCount},
        });
    }
};

export const loadMemberAppSubmittedCases = function* loadMemberAppSubmittedCases() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_SERVICE_MEMBER_APP_CASE_REQUEST,
        ecsFlowService.getMemberAppServiceCases,
        {
            caseStatus: efServiceCaseStatusTypes.NEW,
            contactChannel: efServiceCaseContactChannelTypes.APP,
            sort: ['createdAt', 'desc'],
            size: config.MEMBER_APP_SERVICE_CASES_PAGE_SIZE,
        },
    );

    const responseAction = yield take([
        serviceCaseActionTypes.FETCH_SERVICE_MEMBER_APP_CASE_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_SERVICE_MEMBER_APP_CASE_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {memberAppServiceCasesDTO, totalCount} = response;

        yield put({
            type: dashboardActionTypes.STORE_MEMBER_APP_SERVICE_CASES,
            payload: {memberAppServiceCasesDTO, totalCount},
        });
    }
};

export const loadOpenServiceAssignments = function* loadOpenAssignments() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    yield fork(
        fetchRequest,
        serviceAssignmentActionTypes.FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST,
        ecsFlowService.getOpenServiceAssignments,
        {
            assignmentStatuses: [
                efServiceAssignmentStatusTypes.ASSIGNED,
                // Disabled for Dispatcher 1 during Live launch by client request
                // efServiceAssignmentStatusTypes.REJECTED,
                // efServiceAssignmentStatusTypes.CANCELED,
                // efServiceAssignmentStatusTypes.EUA_CANCELED,
            ],
            // Feature disabled for Dispatcher 1 during Live launch by client request
            // assignedLongerThan: config.ASSIGNMENT_IN_STATUS_ASSIGNED_MIN_TIME,
            sort: 'assignedAt,asc',
            initialAssignment: true,
            commissionerId: config.ACE_COMMISSIONER_ID,
            damageLocationCountryCode: config.DAMAGE_LOCATION_GERMANY_COUNTRY_CODE,
            size: config.DEFAULT_PAGE_SIZE,
            serviceTypes: [efServiceAssignmentTypes.VEHICLE],
        },
    );

    const efResponseAction = yield take([
        serviceAssignmentActionTypes.FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST_SUCCEEDED,
        serviceAssignmentActionTypes.FETCH_OPEN_SERVICE_ASSIGNMENT_REQUEST_FAILED,
    ]);

    if (!efResponseAction.error) {
        const {response} = efResponseAction.payload;
        const {openServiceAssignmentsDTO, totalCount} = response;

        const serviceCaseIds = openServiceAssignmentsDTO.map(openServiceAssignmentDTO => {
            return openServiceAssignmentDTO.serviceCase.id;
        });

        if (serviceCaseIds.length > 0) {
            yield fork(fetchRequest,
                serviceCaseActionTypes.FIND_SERVICE_CASES_FOR_OPEN_ASSIGNMENTS_REQUEST,
                ecsFlowService.findServiceCasesForAssignments,
                {
                    serviceCaseIds: serviceCaseIds.join(','),
                });

            const efServiceCaseForAssignmentsResponseAction = yield take([
                serviceCaseActionTypes.FIND_SERVICE_CASES_FOR_OPEN_ASSIGNMENTS_REQUEST_SUCCEEDED,
                serviceCaseActionTypes.FIND_SERVICE_CASES_FOR_OPEN_ASSIGNMENTS_REQUEST_FAILED,
            ]);

            if (!efServiceCaseForAssignmentsResponseAction.error) {
                const {response: serviceCaseResponse} = efServiceCaseForAssignmentsResponseAction.payload;
                const {serviceCaseDTOs} = serviceCaseResponse;

                yield put({
                    type: serviceCaseActionTypes.STORE_SERVICE_CASES,
                    payload: {serviceCaseDTOs},
                });
            }
        }

        yield put({
            type: dashboardActionTypes.STORE_OPEN_SERVICE_ASSIGNMENTS,
            payload: {openServiceAssignmentsDTO, totalCount},
        });
    }
};

export const loadSickPersonAndVehicleTransportAssignments = function* loadSickPersonAndVehicleTransportAssignments() {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const userProfile = yield select(userProfileSelectors.getUser);

    // TODO GRANT FEATURE ACCESS
    if (userProfile.shiftRole !== eupShiftRoleTypes.TEAM_LEAD) return;

    yield fork(
        fetchRequest,
        serviceAssignmentActionTypes.FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST,
        ecsFlowService.getServiceAssignmentsByServiceType,
        {
            status: efServiceAssignmentStatusTypes.NEW,
            serviceTypes: [
                efServiceAssignmentTypes.SICK_PERSON,
                efServiceAssignmentTypes.MEDICAL_CLEARANCE,
                efServiceAssignmentTypes.VEHICLE_RETURN,
            ],
            sort: ['updatedAt', 'asc'],
        },
    );

    const efResponseAction = yield take([
        serviceAssignmentActionTypes.FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST_SUCCEEDED,
        serviceAssignmentActionTypes.FETCH_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT_REQUEST_FAILED,
    ]);

    if (!efResponseAction.error) {
        const {response} = efResponseAction.payload;
        const {sickPersonAndVehicleTransportAssignmentsDTO} = response;
        const sickPersonAndVehicleIds = sickPersonAndVehicleTransportAssignmentsDTO.map(
            sickPersonAndVehicleTransportAssignmentDTO => sickPersonAndVehicleTransportAssignmentDTO.serviceCase.id,
        );

        if (sickPersonAndVehicleIds.length > 0) {
            yield fork(
                fetchRequest,
                serviceCaseActionTypes.FETCH_SERVICE_CASE_FOR_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENTS_REQUEST,
                ecsFlowService.findServiceCasesForAssignments,
                {
                    serviceCaseIds: sickPersonAndVehicleIds.join(','),
                },
            );
            const efServiceCaseForAssignmentsResponse = yield take([
                serviceCaseActionTypes
                    .FETCH_SERVICE_CASE_FOR_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENTS_REQUEST_SUCCEEDED,
                serviceCaseActionTypes
                    .FETCH_SERVICE_CASE_FOR_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENTS_REQUEST_FAILED,
            ]);

            if (!efServiceCaseForAssignmentsResponse.error) {
                const {payload: serviceCaseForAssignmentsPayload} = efServiceCaseForAssignmentsResponse;
                const {serviceCaseDTOs} = serviceCaseForAssignmentsPayload.response;

                yield put({
                    type: serviceCaseActionTypes.STORE_SERVICE_CASES,
                    payload: {serviceCaseDTOs},
                });
            }
        }

        yield put({
            type: dashboardActionTypes.STORE_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT,
            payload: {sickPersonAndVehicleTransportAssignmentsDTO},
        });
    }
};

export const startAutoRefreshTimer = function* startAutoRefreshTimer() {
    yield put({
        type: dashboardActionTypes.START_AUTO_REFRESH_TIMER,
    });
};
