import {select, delay, fork, all} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import * as dashboardLoaderSagas from '../dashboardLoaderSagas';
import * as serviceCaseLoaderSagas from '../../service-cases/serviceCaseLoaderSagas';

const dashboardAutoRefreshTimer = function* dashboardAutoRefreshTimer() {
    while (true) {
        yield delay(10000); // in ms (10 seconds delay)

        // exit if NOT on dashboard screen
        const {location} = yield select(state => state.router);
        const dashboardScreenMatch = matchPath(location.pathname, {
            path: routePaths.DASHBOARD,
            exact: true,
        });

        // for some reason, dashboardScreenMatch is not null when location is
        // equal to routePaths.SERVICE_CASE_SEARCH
        const serviceCaseScreenMatch = matchPath(location.pathname, {
            path: routePaths.SERVICE_CASE_SEARCH,
            exact: true,
        });

        if (!dashboardScreenMatch || serviceCaseScreenMatch) return;

        // auto refresh: reload dashboard needed data in parallel
        yield all([
            fork(dashboardLoaderSagas.loadLoggedInUsers),
            fork(dashboardLoaderSagas.loadCurrentUsersTasks),
            fork(dashboardLoaderSagas.loadCurrentUserWorkload),
            fork(dashboardLoaderSagas.loadForeignAssistanceCases),
            fork(dashboardLoaderSagas.loadMemberAppSubmittedCases),
            fork(dashboardLoaderSagas.loadOpenServiceAssignments),
            fork(dashboardLoaderSagas.loadSickPersonAndVehicleTransportAssignments),
            fork(serviceCaseLoaderSagas.loadServiceCaseCommissioners),
        ]);
    }
};

export default dashboardAutoRefreshTimer;
