import React, {Fragment, useContext} from 'react';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {useHistory, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ampMemberStatusTypes, ampMemberTariffGroups} from '@ace-de/eua-entity-types';
import {useStyles, Panel, TabSet, TabBar, Tab, TabPanel, SearchBox, Badge, Paginator} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import routePaths from '../routePaths';
import MemberSearchContext from './MemberSearchContext';
import MemberSearchNoResults from './ui-elements/MemberSearchNoResults';
import config from '../config';

const MemberSearchScreen = () => {
    const {cx} = useStyles();
    const history = useHistory();
    const {searchQueryString, setSearchQueryString} = useContext(MemberSearchContext);
    const {searchResultsPrivate, searchResultsCorporate} = useContext(MemberSearchContext);
    const {searchResultsCountPrivate, setSearchResultsPagePrivate} = useContext(MemberSearchContext);
    const {searchResultsCountCorporate, setSearchResultsPageCorporate} = useContext(MemberSearchContext);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('member_search_screen');
    const paginatorCountPrivate = Math.ceil(searchResultsCountPrivate / config.DEFAULT_PAGE_SIZE);
    const paginatorCountCorporate = Math.ceil(searchResultsCountCorporate / config.DEFAULT_PAGE_SIZE);
    const queryParams = new URLSearchParams(history.location.search);

    const openMemberScreen = member => {
        history.push(resolveRoute(routePaths.MEMBER, {
            membershipNo: member.membershipNo,
        }));
    };

    return (
        <Fragment>
            <Panel
                contentClassName={cx('global!ace-u-flex')}
                title={translateScreen('search_box_panel.title')}
            >
                <SearchBox
                    name="memberSearchBox"
                    value={searchQueryString}
                    onChange={value => {
                        setSearchQueryString(value);
                        setSearchResultsPagePrivate(0);
                        setSearchResultsPageCorporate(0);
                    }}
                    className={cx('global!ace-u-flex--grow-1')}
                    isDropDownDisabled={true}
                    placeholder={translateScreen('search_box_placeholder.search')}
                />
            </Panel>

            <TabSet
                name="member-search-screen-tab-set"
                qaIdent="member-search-screen"
            >
                <Panel
                    className={cx('ace-c-panel--full-bleed-content')}
                    header={(
                        <TabBar
                            name="member-search-screen-tabs"
                            value="membership-type-private"
                            tabSet="member-search-screen-tab-set"
                        >
                            <Tab
                                name="membership-type-private"
                                value="membership-type-private"
                            >
                                {translateScreen('search_results_panel_private.title')} ({`${searchResultsCountPrivate}`})
                            </Tab>
                            <Tab
                                name="membership-type-corporate"
                                value="membership-type-corporate"
                            >
                                {translateScreen('search_results_panel_corporate.title')} ({`${searchResultsCountCorporate}`})
                            </Tab>
                        </TabBar>
                    )}
                >
                    <TabPanel for="membership-type-private">
                        <Table qaIdent="member-search-results-private">
                            {searchResultsPrivate.length === 0 && (
                                <TableCaption>
                                    <MemberSearchNoResults />
                                </TableCaption>
                            )}
                            <TableHead>
                                <TableRow>
                                    <TableCell qaIdentPart="member-membership-number">
                                        {translateScreen('search_results_table_header_private.membership_no')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-personal-details-last-name">
                                        {translateScreen('search_results_table_header_private.surname')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-personal-details-first-name">
                                        {translateScreen('search_results_table_header_private.first_name')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-address-street">
                                        {translateScreen('search_results_table_header_private.street')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-address-post-code">
                                        {translateScreen('search_results_table_header_private.post_code')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-address-city">
                                        {translateScreen('search_results_table_header_private.city')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-personal-details-birthday">
                                        {translateScreen('search_results_table_header_private.birthday')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-tariff-group">
                                        {translateScreen('search_results_table_header_private.tariff')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-status">
                                        {translateScreen('search_results_table_header_private.status')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-service-case-count">
                                        {translateScreen('search_results_table_header_private.cases')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {searchResultsPrivate.length > 0 && searchResultsPrivate.map(member => {
                                    const memberBirthday = member.personalDetails.birthday
                                        ? moment(member.personalDetails.birthday).format('DD.MM.YYYY') : '';

                                    return (
                                        <TableRow
                                            key={member.membershipNo}
                                            onClick={() => openMemberScreen(member)}
                                            qaIdentPart={member.id}
                                        >
                                            <TableCell
                                                qaIdentPart="member-membership-number"
                                                qaIdentPartPostfix={member.id}
                                            >
                                                {member.membershipNo}
                                            </TableCell>
                                            <TableCell
                                                qaIdentPart="member-personal-details-last-name"
                                                qaIdentPartPostfix={member.id}
                                            >
                                                {member.personalDetails.surname}
                                            </TableCell>
                                            <TableCell
                                                qaIdentPart="member-personal-details-fist-name"
                                                qaIdentPartPostfix={member.id}
                                            >
                                                {member.personalDetails.firstName}
                                            </TableCell>
                                            <TableCell
                                                qaIdentPart="member-address-street"
                                                qaIdentPartPostfix={member.id}
                                            >
                                                {member.personalDetails.address.street}
                                            </TableCell>
                                            <TableCell
                                                qaIdentPart="member-address-post-code"
                                                qaIdentPartPostfix={member.id}
                                            >
                                                {member.personalDetails.address.postCode}
                                            </TableCell>
                                            <TableCell
                                                qaIdentPart="member-address-city"
                                                qaIdentPartPostfix={member.id}
                                            >
                                                {member.personalDetails.address.city}
                                            </TableCell>
                                            <TableCell
                                                qaIdentPart="member-personal-details-birthday"
                                                qaIdentPartPostfix={member.id}
                                            >
                                                {memberBirthday}
                                            </TableCell>
                                            <TableCell
                                                qaIdentPart="member-tariff-group"
                                                qaIdentPartPostfix={member.id}
                                            >
                                                {member.tariffDetails?.currentTariff
                                                    ? translate(`global.member_tariff_group.${snakeCase(ampMemberTariffGroups[member.tariffDetails.tariffGroup])}`)
                                                    : ''}
                                            </TableCell>
                                            <TableCell
                                                qaIdentPart="member-status"
                                                qaIdentPartPostfix={member.id}
                                            >
                                                {member.status !== ampMemberStatusTypes.EMPTY && (
                                                    <Badge status={member.status.toLowerCase()}>
                                                        {translate(`global.member_status.${snakeCase(ampMemberStatusTypes[member.status])}`)}
                                                    </Badge>
                                                )}
                                            </TableCell>
                                            <TableCell
                                                qaIdentPart="member-service-case-count"
                                                qaIdentPartPostfix={member.id}
                                            >
                                                {member.serviceCaseCount}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>

                        {searchResultsPrivate.length > 0 && (
                            <Paginator
                                page={+queryParams.get('pagePrivate')}
                                count={paginatorCountPrivate}
                                onClick={page => setSearchResultsPagePrivate(page)}
                            />
                        )}
                    </TabPanel>

                    <TabPanel for="membership-type-corporate">
                        <Table qaIdent="member-search-results-corporate">
                            {searchResultsCorporate.length === 0 && (
                                <TableCaption>
                                    <MemberSearchNoResults />
                                </TableCaption>
                            )}
                            <TableHead>
                                <TableRow>
                                    <TableCell qaIdentPart="member-membership-number">
                                        {translateScreen('search_results_table_header_corporate.membership_no')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-personal-details-name">
                                        {translateScreen('search_results_table_header_corporate.name')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-address-street">
                                        {translateScreen('search_results_table_header_corporate.street')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-address-post-code">
                                        {translateScreen('search_results_table_header_corporate.post_code')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-address-city">
                                        {translateScreen('search_results_table_header_corporate.city')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-tariff-group">
                                        {translateScreen('search_results_table_header_corporate.tariff')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-status">
                                        {translateScreen('search_results_table_header_corporate.status')}
                                    </TableCell>
                                    <TableCell qaIdentPart="member-service-case-count">
                                        {translateScreen('search_results_table_header_corporate.cases')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {searchResultsCorporate.length > 0 && searchResultsCorporate.map(member => (
                                    <TableRow
                                        key={member.membershipNo}
                                        onClick={() => openMemberScreen(member)}
                                        qaIdentPart={member.id}
                                    >
                                        <TableCell
                                            qaIdentPart="member-membership-number"
                                            qaIdentPartPostfix={member.id}
                                        >
                                            {member.membershipNo}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="member-personal-details-name"
                                            qaIdentPartPostfix={member.id}
                                        >
                                            {member.personalDetails.name}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="member-address-street"
                                            qaIdentPartPostfix={member.id}
                                        >
                                            {member.personalDetails.address.street}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="member-address-post-code"
                                            qaIdentPartPostfix={member.id}
                                        >
                                            {member.personalDetails.address.postCode}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="member-address-city"
                                            qaIdentPartPostfix={member.id}
                                        >
                                            {member.personalDetails.address.city}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="member-tariff-group"
                                            qaIdentPartPostfix={member.id}
                                        >
                                            {member.tariffDetails && member.tariffDetails.tariffGroup && (
                                                translate(`global.member_tariff_group.${snakeCase(ampMemberTariffGroups[member.tariffDetails.tariffGroup])}`)
                                            )}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="member-status"
                                            qaIdentPartPostfix={member.id}
                                        >
                                            {member.status !== ampMemberStatusTypes.EMPTY && (
                                            <Badge status={member.status.toLowerCase()}>
                                                {translate(`global.member_status.${snakeCase(ampMemberStatusTypes[member.status])}`)}
                                            </Badge>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="member-service-case-count"
                                            qaIdentPartPostfix={member.id}
                                        >
                                            {member.serviceCaseCount}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        {searchResultsCorporate.length > 0 && (
                            <Paginator
                                page={+queryParams.get('pageCorporate')}
                                count={paginatorCountCorporate}
                                onClick={page => setSearchResultsPageCorporate(page)}
                            />
                        )}
                    </TabPanel>

                </Panel>
            </TabSet>
        </Fragment>
    );
};

export default MemberSearchScreen;
