import {take, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import updateServiceAssignmentStatus from './updateServiceAssignmentStatus';
import * as serviceAssignmentActionTypes from '../serviceAssignmentActionTypes';

/**
 * SAV status update flow (uses modal)
 */
const serviceAssignmentStatusUpdateFlow = function* serviceAssignmentStatusUpdateFlow() {
    while (true) {
        yield take(serviceAssignmentActionTypes.INITIATE_SERVICE_ASSIGNMENT_STATUS_UPDATE_FLOW);

        yield* openModal(modalIds.SERVICE_ASSIGNMENT_STATUS_UPDATE);
        const chosenModalOption = yield take([
            serviceAssignmentActionTypes.CONFIRM_SERVICE_ASSIGNMENT_STATUS_UPDATE,
            serviceAssignmentActionTypes.DECLINE_SERVICE_ASSIGNMENT_STATUS_UPDATE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceAssignmentActionTypes.CONFIRM_SERVICE_ASSIGNMENT_STATUS_UPDATE) {
            const {
                serviceAssignmentLineNo,
                serviceCaseId,
                serviceAssignmentStatusData,
                currentServiceCaseAssignmentStatus,
                redirectionPath,
            } = chosenModalOption.payload;

            // enable cloning for all case types (vehicle, person, household)
            // see https://computerrock.atlassian.net/browse/ACEECS-5745
            if (serviceAssignmentStatusData.assignmentStatus === 'CLONE_ASSIGNMENT') {
                yield put({
                    type: serviceAssignmentActionTypes.SUBMIT_SERVICE_ASSIGNMENT_CLONE_FORM,
                    payload: {
                        serviceCaseId,
                        serviceAssignmentLineNo,
                        redirectionPath,
                        modal: modalIds.SERVICE_ASSIGNMENT_STATUS_UPDATE,
                    },
                });
                continue;
            }

            if (serviceAssignmentStatusData.assignmentStatus !== currentServiceCaseAssignmentStatus) {
                yield* updateServiceAssignmentStatus({
                    caller: serviceAssignmentActionTypes.CONFIRM_SERVICE_ASSIGNMENT_STATUS_UPDATE,
                    serviceAssignmentLineNo,
                    serviceCaseId,
                    serviceAssignmentStatusData,
                });
            }
        }

        yield* closeModal(modalIds.SERVICE_ASSIGNMENT_STATUS_UPDATE);
    }
};

export default serviceAssignmentStatusUpdateFlow;
