import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, TextArea} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CommissioningCommissioningPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sasd_commissioning_tab');
    const {serviceAssignment} = props;
    const {onChangeHandler, assignmentText} = props;

    return (
        <Panel
            className={cx('global!ace-u-flex--grow-1')}
            title={(
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--grow-1',
                        'global!ace-u-flex--justify-space-between',
                    ])}
                >
                    <p>{translateTab('panel_title.commissioning')}</p>
                </div>
              )}
            qaIdent="sasd-commissioning-commissioning"
        >
            <DataRow
                label={translateTab('data_row_label.description')}
                className={cx('ace-c-data-row--condensed')}
                qaIdent="sa-description"
            >
                <TextArea
                    className={cx('global!ace-u-flex--grow-1')}
                    isResizable={false}
                    value={assignmentText}
                    onChange={value => onChangeHandler('assignmentText', value)}
                />
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.pickup_date')}
                qaIdent="sa-pickup-date"
            >
                <div>
                    {serviceAssignment.pickupDate
                        ? moment(serviceAssignment.pickupDate).format('DD.MM.YYYY')
                        : ''}
                </div>
            </DataRow>
        </Panel>
    );
};

CommissioningCommissioningPanel.propTypes = {
    assignmentText: PropTypes.string,
    onChangeHandler: PropTypes.func.isRequired,
    serviceAssignment: PropTypes.object,
};

CommissioningCommissioningPanel.defaultProps = {
    assignmentText: '',
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CommissioningCommissioningPanel));
