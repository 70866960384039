import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, NumberInput} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageCostOverviewPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sarj_cost_coverage_tab');
    const {serviceCase, totalCost, onChangeHandler} = props;

    return (
        <Panel
            title={translateTab('panel_title.cost_overview')}
            qaIdent="sarj-cost-coverage-cost-overview"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.costs')}
                        className={cx('ace-c-data-row--condensed')}
                        qaIdent="sa-total-cost"
                    >
                        <NumberInput
                            className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                            placeholder={translateTab('input_placeholder.please_enter_here')}
                            value={totalCost}
                            onChange={value => onChangeHandler('totalCost', value)}
                            min={0}
                        />
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.member_phone_no')}
                        qaIdent="sa-member-contact-details-phone"
                    >
                        {serviceCase.member.contactDetails.phoneNo}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CostCoverageCostOverviewPanel.propTypes = {
    serviceCase: PropTypes.object,
    totalCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChangeHandler: PropTypes.func,
};

CostCoverageCostOverviewPanel.defaultProps = {
    serviceCase: {},
    totalCost: '',
    onChangeHandler: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageCostOverviewPanel));
