import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, DataRow} from '@ace-de/ui-components';
import {efServiceAssignmentStatusTypes, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import * as serviceAssignmentSelectors from '../serviceAssignmentSelectors';

const CommissioningDiagnoseAndInsurancePanel = props => {
    const {createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('commissioning_diagnose_and_insurance_panel');
    // on the SASP, we are getting diagnose&insurance data from the latest SAMC
    const {serviceAssignment, newestMedicalClearanceAssignment} = props;

    const assignment = useMemo(() => {
        // On assigning, BE will patch the data on SASP, see https://computerrock.atlassian.net/browse/ACEMS-716
        if (serviceAssignment.assignmentType === efServiceAssignmentTypes.SICK_PERSON
            && ![efServiceAssignmentStatusTypes.NEW, efServiceAssignmentStatusTypes.DONE]
                .includes(serviceAssignment.assignmentStatus)
        ) {
            return serviceAssignment;
        }
        return newestMedicalClearanceAssignment || serviceAssignment || null;
    }, [serviceAssignment, newestMedicalClearanceAssignment]);

    // if no service case, don't render
    if (!serviceAssignment && !newestMedicalClearanceAssignment) return null;

    return (
        <Panel
            title={translatePanel('title.diagnose_and_insurance')}
            qaIdent="commissioning-diagnose-and-insurance-panel"
        >
            <DataRow
                label={translatePanel('data_row_label.description')}
                qaIdent="sc-diagnose-description"
            >
                {assignment.diagnoseDescription}
            </DataRow>
            <DataRow
                label={translatePanel('data_row_label.insurance')}
                qaIdent="sc-insurance"
            >
                {assignment.insurance}
            </DataRow>
            {assignment.assignmentType !== efServiceAssignmentTypes.MEDICAL_ADVICE && (
                <DataRow
                    label={translatePanel('data_row_label.hospital_entry_date')}
                    qaIdent="sc-hospital-entry-date"
                >
                    {assignment.hospitalEntryDate
                        ? moment(assignment.hospitalEntryDate).format('DD.MM.YYYY')
                        : ''}
                </DataRow>
            )}
        </Panel>
    );
};

CommissioningDiagnoseAndInsurancePanel.propTypes = {
    serviceAssignment: PropTypes.object,
    newestMedicalClearanceAssignment: PropTypes.object,
};

CommissioningDiagnoseAndInsurancePanel.defaultProps = {
    serviceAssignment: null,
    newestMedicalClearanceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CommissioningDiagnoseAndInsurancePanel));
