import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, DataRow} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageDetailsPanel = props => {
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sala_cost_coverage_tab');
    const {serviceAssignment} = props;

    return (
        <Panel
            title={translateTab('panel_title.details')}
            qaIdent="sala-cost-coverage-details"
        >
            <DataRow
                label={translateTab('data_row_label.reason')}
                qaIdent="sa-reason"
            >
                {serviceAssignment?.reason || ''}
            </DataRow>
            <DataRow
                label={translateTab('data_row_label.callback_number')}
                qaIdent="sa-callback-number"
            >
                {serviceAssignment?.callbackPhoneNo || ''}
            </DataRow>
        </Panel>
    );
};

CostCoverageDetailsPanel.propTypes = {
    serviceAssignment: PropTypes.object,
};

CostCoverageDetailsPanel.defaultProps = {
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageDetailsPanel));
