import {produce} from 'immer';
import {User, Task, efServiceAssignmentTypes} from '@ace-de/eua-entity-types';
import * as dashboardActionTypes from './dashboardActionTypes';
import createNewServiceCase from '../service-cases/createNewServiceCase';
import createNewServiceAssignment from '../service-assignments/createNewServiceAssignment';

const initialState = {
    loggedInUsers: {},
    currentUsersTasks: [],
    reporterCaseAssignmentTaskCount: {},
    memberAppServiceCases: [],
    openServiceAssignments: {},
    sickPersonAndVehicleTransportAssignments: {},
    openServiceAssignmentsCount: 0,
    memberAppServiceCasesCount: 0,
};

const dashboardReducer = produce((draft, action) => {
    const {payload, type} = action;
    switch (type) {
        case dashboardActionTypes.STORE_CURRENT_USERS_TASKS: {
            const {taskDTOs} = action.payload;
            draft.currentUsersTasks = taskDTOs.map(taskDTO => new Task().fromDTO(taskDTO));
            break;
        }

        case dashboardActionTypes.STORE_CURRENTLY_ACTIVE_TEAM: {
            const {userLoggedInDTOs} = payload;
            const {people} = userLoggedInDTOs;
            draft.loggedInUsers = {}; // reset loggedInUsers object
            people.forEach(person => {
                const {currentShiftRole} = person?.shiftRoleInfo;
                const loggedInUserData = draft.loggedInUsers[currentShiftRole];

                if (!loggedInUserData) {
                    draft.loggedInUsers[currentShiftRole] = new User().fromDTO(person);
                    return;
                }
                draft.loggedInUsers[currentShiftRole] = loggedInUserData.fromDTO(person);
            });
            break;
        }

        case dashboardActionTypes.STORE_REPORTER_CASES_TASKS_COUNT: {
            const {reporterCasesCountDTO, reporterTasksCountDTO} = action.payload;
            if (reporterCasesCountDTO !== undefined) {
                const {countOfElements} = reporterCasesCountDTO;
                draft.reporterCaseAssignmentTaskCount.countOfCases = countOfElements;
            }
            if (reporterTasksCountDTO !== undefined) {
                const {countOfTasksDispatcher2, countOfTasksDispo} = reporterTasksCountDTO;
                draft.reporterCaseAssignmentTaskCount.countOfTasksDispatcher2 = countOfTasksDispatcher2;
                draft.reporterCaseAssignmentTaskCount.countOfTasksDispo = countOfTasksDispo;
            }
            break;
        }

        case dashboardActionTypes.STORE_MEMBER_APP_SERVICE_CASES: {
            const {memberAppServiceCasesDTO, totalCount} = action.payload;
            draft.memberAppServiceCases = memberAppServiceCasesDTO.map(serviceCaseDTO => {
                return createNewServiceCase(serviceCaseDTO.caseType).fromDTO(serviceCaseDTO);
            });
            draft.memberAppServiceCasesCount = totalCount;
            break;
        }

        case dashboardActionTypes.STORE_OPEN_SERVICE_ASSIGNMENTS: {
            const {openServiceAssignmentsDTO, totalCount} = action.payload;
            // reset hash map
            draft.openServiceAssignments = {};
            openServiceAssignmentsDTO.forEach(serviceAssignmentDTO => {
                const serviceAssignmentId = `${serviceAssignmentDTO.serviceCaseId}-${serviceAssignmentDTO.lineNo}`;
                const serviceAssignment = draft.openServiceAssignments[serviceAssignmentId];
                if (!serviceAssignment) {
                    if (!Object.values(efServiceAssignmentTypes).includes(serviceAssignmentDTO.assignmentType)) return;

                    // eslint-disable-next-line max-len
                    draft.openServiceAssignments[serviceAssignmentId] = createNewServiceAssignment(serviceAssignmentDTO.assignmentType)
                        .fromDTO(serviceAssignmentDTO);
                    return;
                }
                draft.openServiceAssignments[serviceAssignmentId] = serviceAssignment.fromDTO(serviceAssignmentDTO);
            });
            draft.openServiceAssignmentsCount = totalCount;
            break;
        }

        case dashboardActionTypes.STORE_SICK_PERSON_AND_VEHICLE_TRANSPORT_ASSIGNMENT: {
            const {sickPersonAndVehicleTransportAssignmentsDTO} = action.payload;
            sickPersonAndVehicleTransportAssignmentsDTO.forEach(serviceAssignmentDTO => {
                const serviceAssignmentId = `${serviceAssignmentDTO.serviceCaseId}-${serviceAssignmentDTO.lineNo}`;
                const serviceAssignment = draft.sickPersonAndVehicleTransportAssignments[serviceAssignmentId];
                if (!serviceAssignment) {
                    if (!Object.values(efServiceAssignmentTypes).includes(serviceAssignmentDTO.assignmentType)) return;

                    // eslint-disable-next-line max-len
                    draft.sickPersonAndVehicleTransportAssignments[serviceAssignmentId] = createNewServiceAssignment(serviceAssignmentDTO.assignmentType)
                        .fromDTO(serviceAssignmentDTO);
                    return;
                }

                // eslint-disable-next-line max-len
                draft.sickPersonAndVehicleTransportAssignments[serviceAssignmentId] = serviceAssignment.fromDTO(serviceAssignmentDTO);
            });
            break;
        }

        default:
            // no-op
    }
}, initialState);
export default dashboardReducer;
